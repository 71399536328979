import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloseIcon from '@mui/icons-material/Close';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PauseIcon from '@mui/icons-material/Pause';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {
  Card,
  Grid,
  Box,
  IconButton,
  Tooltip,
  Dialog,
  DialogContent,
  DialogActions,
  ListItemButton,
  ListItemIcon,
  Divider
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AppBar from '@mui/material/AppBar';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import { styled, useTheme } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import {
  DataGrid,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  trTR
} from '@mui/x-data-grid';
import trLocale from 'date-fns/locale/tr';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InvoiceDetail from 'src/components/InvoiceDetail';
import {
  getSubStatuses,
  getSolutionCenterSubStatus
} from 'src/store/slices/assigmentSlice';
import {
  getChangeReasonItems,
  getCityItems,
  getConfirmTypeItems,
  getCustomerTypes,
  getProductItems,
  getRegionItems,
  getStatusItems,
  getSubStatusItems,
  getAronStatusItems,
  getRefundMoneyItems
} from 'src/store/slices/commonSlice';
import {
  generalFollowActions,
  getCMSearch,
  getCMSummary,
  getDetail,
  getFileType,
  getPool,
  waitingPoolReceipt,
  insertFisListControl,
  getRefundInformationForm,
  waitingPoolReceiptUpdate
} from 'src/store/slices/generalFollowSlice';
import {
  getReceiptInfoChat,
  informationChatActions
} from 'src/store/slices/informationChatSlice';
import AlertAndLoading from 'src/components/AlertAndLoading';
import { getUser, getUserRole } from 'src/utils/Common';
import { getFisListControl } from 'src/store/slices/generalFollowSlice';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ReceiptLongSharpIcon from '@mui/icons-material/ReceiptLongSharp';
import HistorySharpIcon from '@mui/icons-material/HistorySharp';
import Print from 'src/components/Print';
import axios from 'src/utils/axios';
import CloudSyncIcon from '@mui/icons-material/CloudSync';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Segoe UI',
    width: '100%'
  },
  AccordionStyle: {
    border: 3,
    borderRadius: 2,
    zIndex: 1000,
    borderColor: 'black',
    bottom: '0.1rem',
    right: '2rem',
    position: 'fixed',
    width: '350px'
  },
  FisNoBox: {
    color: '#b02121b0',
    fontWeight: '400'
  }
}));

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626'
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959'
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343'
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c'
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff'
  }
}));
function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>Satır Bulunamadı</Box>
    </StyledGridOverlay>
  );
}

function GeneralFollow() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();
  const theme = useTheme();
  const userRole = getUserRole().UserBasedComponent;

  const regionData = useSelector((state) => state.commonSlice.regionItems);
  const cityData = useSelector((state) => state.commonSlice.cityItems);
  const confirmTypeData = useSelector(
    (state) => state.commonSlice.confirmTypeItems
  );
  const changeReasonData = useSelector(
    (state) => state.commonSlice.changeReasonItems
  );
  const statusData = useSelector((state) => state.commonSlice.statuses);
  const aronStatusData = useSelector((state) => state.commonSlice.aronStatuses);
  const refundMoney = useSelector((state) => state.commonSlice.refundMoney);
  const subStatusData = useSelector((state) => state.commonSlice.subStatuses);
  const productsData = useSelector((state) => state.commonSlice.products);
  const customerTypeData = useSelector(
    (state) => state.commonSlice.customerTypes
  );
  const activeTab = useSelector((state) => state.generalFollowSlice.activedTab);
  const UpdatedRowCount = useSelector(
    (state) => state.generalFollowSlice.updatedRowCount
  );
  const UpdatedProductInformation = useSelector(
    (state) => state.generalFollowSlice.updatedProductInformation
  );
  const dataSort = useSelector(
    (state) => state.generalFollowSlice.updatedDataGridSort
  );
  const commonSliceLastUpdate = useSelector(
    (state) => state.commonSlice.lastUpdateDate
  );

  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );

  const [expanded, setExpanded] = React.useState(false);
  const [expandedSearch, setExpandedSearch] = React.useState(false);
  const [customerTypes, setCustomerTypes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [customerName, setCustomerName] = React.useState('');
  const [customerSurname, setCustomerSurname] = React.useState('');
  const [retailer, setRetailer] = React.useState('');
  const [modelCode, setModelCode] = React.useState('');
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [receiptNumber, setReceiptNumber] = React.useState('');
  const [invoiceNumber, setInvoiceNumber] = React.useState('');

  const [selectedRegion, setSelectedRegion] = React.useState(
    regionData.filter((i) => i.Id == 0)
  );
  const [selectedCity, setSelectedCity] = React.useState(
    cityData.filter((i) => i.Id == 0)
  );
  const [selectedConfirmType, setConfirmType] = React.useState(
    confirmTypeData.filter((i) => i.Id == 0)
  );
  const [selectedChangeReason, setChangeReason] = React.useState(
    changeReasonData.filter((i) => i.Id == 0)
  );
  const [selectedStatus, setStatus] = React.useState(
    statusData.filter((i) => i.Id == 0)
  );
  const [selectedAronStatus, setAronStatus] = React.useState(
    aronStatusData.filter((i) => i.Id == 0)
  );
  const [selectedRefundMoney, setRefundMoney] = React.useState(
    refundMoney.filter((i) => i.Id == 0)
  );
  const [selectedSubStatus, setSubStatus] = React.useState(
    subStatusData.filter((i) => i.Id == 0)
  );
  const [selectedProduct, setProduct] = React.useState(
    productsData.filter((i) => i.Id == 0)
  );
  const [selectedCustomerType, setSelectedCustomerType] = React.useState([]);
  const [productInformation, setProductInformation] = React.useState([]);
  const [columnHide, setColumnHide] = React.useState(false);
  const [waitingReasonPopUp, setWaitingReasonsPopUp] = React.useState(false);
  const [waitingReasonUpdatePopUp, setWaitingReasonUpdatePopUp] =
    React.useState(false);
  const [waitingReceiptNo, setWaitingReceiptNo] = React.useState(null);
  const [waitingServiceNumber, setWaitingServiceNumber] = React.useState(null);
  const [waitingApplicationNumber, setWaitingApplicationNumber] =
    React.useState(null);
  const [waitingDsc, setWaitingDsc] = React.useState('');
  const [waitingStatus, setWaitingStatus] = React.useState(null);
  const [printOpen, setPrintOpen] = React.useState(false);
  const [printData, setPrintData] = React.useState([]);

  const [startDate, setStartDate] = React.useState(
    moment().subtract(2, 'month')
  );
  const [endDate, setEndDate] = React.useState(moment());

  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({
    ErrorType: 'error',
    ErrorTitle: 'Uyarı'
  });
  const [responseMessage, setresponseMessage] = React.useState('');

  const [fisListControl, setFisListControl] = React.useState([]);
  const [fisExpanded, setFisExpanded] = React.useState(false);

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    });
    setUyari(true);
    return;
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleChangeStartDate = (e) => {
    setStartDate(e);
  };

  const handleChangeEndDate = (e) => {
    setEndDate(e);
  };

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  const handlePrintModal = (e, cellValues) => {
    e.stopPropagation();

    const data = [
      cellValues.row.BASVURU_NO.toString(),
      cellValues.row.BIRIM_KODU.toString(),
      cellValues.row.MUSTERI_TIPI.toString()
    ];

    setLoading(true);
    dispatch(getRefundInformationForm(data))
      .then((response) => {
        if (response.payload.length > 0) {
          setPrintData(response.payload);
          setPrintOpen(true);
        } else {
          InfoAlert('Kayıt bulunamadı!...', 'error', 'Uyarı');
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  };

  const SelectableListItemText = styled(ListItemText)({
    userSelect: 'text'
  });

  const columns = [
    {
      field: 'Yazdır',
      headerName: '',
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}
            onClick={(e) => handlePrintModal(e, cellValues)}
          >
            <IconButton>
              <LocalPrintshopIcon />
            </IconButton>
          </Box>
        );
      },
      width: 70
    },
    {
      field: 'Beklemeye Al',
      renderCell: (cellValues) => {
        return (
          <Box
            sx={{ justifyContent: 'center', '& > :not(style)': { m: 1 } }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {cellValues?.row?.BEKLEMEDE == true ? (
              <Tooltip title="Bekleme Nedenini Güncelle">
                <IconButton
                  onClick={() => {
                    setWaitingReasonUpdatePopUp(true);
                    setWaitingStatus(cellValues?.row?.DEGISIMDURUM);
                    setWaitingApplicationNumber(cellValues?.row?.BASVURU_NO);
                    setWaitingServiceNumber(cellValues?.row?.BIRIM_KODU);
                  }}
                >
                  <PauseIcon sx={{ color: 'red' }} disabled />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Beklemeye Al">
                <IconButton
                  onClick={() => {
                    setWaitingStatus(cellValues?.row?.DEGISIMDURUM);
                    setWaitingReasonsPopUp(true);
                    setWaitingReceiptNo(cellValues?.row?.FIS_NO);
                    setWaitingApplicationNumber(cellValues?.row?.BASVURU_NO);
                    setWaitingServiceNumber(cellValues?.row?.BIRIM_KODU);
                  }}
                >
                  <PlayArrowIcon sx={{ color: 'green' }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
      width: 100,
      hide: columnHide
    },
    { field: 'SIRA', headerName: '', width: 50 },
    { field: 'SERVIS', headerName: 'Servis', width: 200 },
    { field: 'FIS_NO', headerName: 'Fiş Numarası', width: 100 },
    { field: 'DEGISIM_FATURA_NO', headerName: 'Fatura No', width: 150 },
    { field: 'UST_BIRIM_ACIKLAMA', headerName: 'Bölge', width: 200 },
    { field: 'ADSOYAD', headerName: 'Ad Soyad', width: 250 },
    { field: 'DURUM', headerName: 'Durum', width: 250 },
    { field: 'ARON_DURUM_ACIKLAMA', headerName: 'Fiş Durum', width: 250 },
    {
      field: 'PARAIADESI',
      headerName: 'Para İadesi / Değişim Çeki Açıklama',
      width: 250
    },
    { field: 'URUN_ACIKLAMA', headerName: 'Ürün Tanımı', width: 300 },
    {
      field: 'MUSTERI_TIP_ACIKLAMA',
      headerName: 'Müşteri Tipi',
      width: 100,
      hide: true
    },
    {
      field: 'ISLEM_TARIHI',
      headerName: 'İşlem Tarihi',
      width: 150,
      renderCell: (cellValues) => {
        return moment(cellValues.row.ISLEM_TARIHI).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      }
    },
    {
      field: 'CAGRI_TARIHI',
      headerName: 'Çağrı Tarihi',
      width: 150,
      renderCell: (cellValues) => {
        return moment(cellValues.row.CAGRI_TARIHI).format(
          'DD/MM/YYYY HH:mm:ss'
        );
      }
    },
    { field: 'BASVURU_NO', headerName: 'Başvuru No', width: 100, hide: true },
    { field: 'STOK_NO', headerName: 'Stok No', width: 100 },
    { field: 'POOL_DESC', headerName: 'Havuz Adı', width: 250 },
    { field: 'SICILNO', headerName: 'Atanan Kişi', width: 150 },
    { field: 'SERINO', headerName: 'Yıl/Ay/SeriNo', width: 150 },
    { field: 'TEL1', headerName: 'Telefon', width: 100, hide: true },
    { field: 'STOK_NO', headerName: 'Stok No', width: 100 },
    {
      field: 'SERVIS_TESLIM_TARIHI',
      headerName: 'Servise Teslim Tarihi',
      width: 150,
      hide: true
    },
    {
      field: 'DEGISIM_FATURA_KALEM_ADET',
      headerName: 'Adet',
      width: 150,
      hide: true
    }
  ];

  useEffect(() => {
    if (
      !commonSliceLastUpdate ||
      moment(commonSliceLastUpdate).add(12, 'hours') <= moment()
    ) {
      dispatch(getRegionItems());
      dispatch(getConfirmTypeItems());
      dispatch(getChangeReasonItems());
      dispatch(getStatusItems());
      dispatch(getSubStatusItems());
      dispatch(getProductItems());
      dispatch(getCustomerTypes());
      dispatch(getFileType());
      dispatch(getSubStatuses());
      dispatch(getAronStatusItems());
      dispatch(getRefundMoneyItems());
    }
    dispatch(getSolutionCenterSubStatus());
    Pools();
    dispatch(generalFollowActions.updateActiveTab('1'));
    dispatch(
      generalFollowActions.updateDataGridSort({ field: 'SIRA', sort: 'asc' })
    );
    dispatch(generalFollowActions.updateRowCount(0));
  }, [dispatch]);

  useEffect(() => {
    setCustomerTypes(customerTypeData);
    setSelectedCustomerType(customerTypeData);
  }, [customerTypeData]);

  function QuickSearchToolbar(props) {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}>
            <GridToolbarContainer>
              <GridToolbarColumnsButton sx={{ color: '#FF9800' }} />
              <GridToolbarExport
                sx={{ color: '#FF9800' }}
                csvOptions={{
                  utf8WithBom: true
                }}
              />
            </GridToolbarContainer>
          </Grid>
          <Grid item xs={4} md={4}>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              sx={{
                width: '100%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: '#424242'
              }}
            >
              {'Toplam '}
              {UpdatedRowCount}
              {' Adet Fiş bulunmaktadır.'}
            </Typography>
          </Grid>
          <Grid item xs={2} md={2}></Grid>
          <Grid item xs={2} md={2}>
            <Button
              fullWidth
              size="medium"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => Pools()}
            >
              Atanan İşlerim
            </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }

  function Pools() {
    dispatch(generalFollowActions.updateProductInformation([]));
    const data = {
      SESSION_USER_ID: SessionUser?.UserId?.toString() || null
    };
    setLoading(true);
    dispatch(getPool(data))
      .then((response) => {
        if (response.payload?.length > 0) {
          setProductInformation(
            response.payload?.map((W) => ({
              SERVIS: W.KUL_BIRIM_KODU + '-' + W.SERVIS,
              FIS_NO: W.FIS_NO,
              ADSOYAD: W.ADSOYAD,
              STOK_NO: W.STOK_NO,
              CAGRI_TARIHI: W.CAGRI_TARIHI
                ? moment(W.CAGRI_TARIHI).format('DD.MM.YYYY')
                : '',
              ISLEM_TARIHI: W.ISLEM_TARIHI
                ? moment(W.ISLEM_TARIHI).format('DD.MM.YYYY')
                : '',
              URUN_ACIKLAMA: W.URUN_ACIKLAMA,
              DURUM: W.DURUM,
              POOL_DESC: W.POOL_DESC,
              SICILNO: W.SICILNO,
              MUSTERI_TIP_ACIKLAMA: W.MUSTERI_TIP_ACIKLAMA,
              TEL1: W.TEL1,
              BIRIM_KODU: W.BIRIM_KODU,
              UST_BIRIM_ACIKLAMA: W.UST_BIRIM_KODU + '-' + W.UST_BIRIM_ACIKLAMA,
              SERVIS_TESLIM_TARIHI: W.SERVIS_TESLIM_TARIHI,
              SERINO: W.UYIL + '/' + W.UDONEM + '/' + W.SERINO,
              BASVURU_NO: W.BASVURU_NO,
              DEGISIM_FATURA_KALEM_ADET: W.DEGISIM_FATURA_KALEM_ADET,
              NITELIK_KODU: W.NITELIK_KODU,
              DEGISIM_FATURA_NO: W.DEGISIM_FATURA_NO
                ? W.DEGISIM_FATURA_NO
                : '-',
              SIRA: W.SIRA,
              BEKLEMEDE: W.BEKLEMEDE ? W.BEKLEMEDE : 0
            }))
          );
          setColumnHide(false);
          setLoading(false);
          dispatch(
            generalFollowActions.updateRowCount(response.payload.length)
          );
          dispatch(
            generalFollowActions.updateProductInformation(response.payload)
          );
        } else {
          setLoading(false);
          setProductInformation([]);
          dispatch(generalFollowActions.updateRowCount(0));
          dispatch(generalFollowActions.updateProductInformation([]));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  async function searchProductSummary() {
    setColumnHide(true);
    setProductInformation([]);
    dispatch(generalFollowActions.updateProductInformation([]));

    var regionsList = [];
    selectedRegion.map((i) => {
      regionsList.push(i.Id);
    });

    var cityList = [];
    selectedCity.map((i) => {
      cityList.push(i.Id);
    });

    var changeReasonList = [];
    selectedChangeReason.map((i) => {
      changeReasonList.push(i.Id);
    });

    var statusList = [];
    selectedStatus.map((i) => {
      statusList.push(i.Id);
    });

    var aronStatusList = [];
    selectedAronStatus.map((i) => {
      aronStatusList.push(i.Id);
    });

    var refundMoneyList = [];
    selectedRefundMoney.map((i) => {
      refundMoneyList.push(i.Id);
    });

    var subStatusList = [];
    selectedSubStatus.map((i) => {
      subStatusList.push(i.Id);
    });

    var customerTypesList = [];
    selectedCustomerType.map((i) => {
      customerTypesList.push(i.Id);
    });

    var productTypesList = [];
    selectedProduct.map((i) => {
      productTypesList.push(i.Id);
    });

    var confirmTypesList = [];
    selectedConfirmType.map((i) => {
      confirmTypesList.push(i.Id);
    });

    const data = {
      BIRIM_KODU: cityList.toString() || '0',
      BOLGE_BIRIM_KODU: regionsList.toString() || '0',
      ONAY_TIPI: confirmTypesList.toString() || '0',
      DURUM: statusList.toString() || '0',
      ARON_DURUM: aronStatusList.toString() || '0',
      PARA_IADE: refundMoneyList.toString() || '0',
      BASLANGIC_TARIHI: moment(startDate).format('DD-MM-YYYY'),
      BITIS_TARIHI: moment(endDate).format('DD-MM-YYYY'),
      URUN_GRUP: productTypesList.toString() || '0',
      MUSTERI_TIP: customerTypesList.toString() || '0',
      DEGISIM_NEDENI: changeReasonList.toString() || '0',
      ALT_DURUM: subStatusList.toString() || '0',
      SESSION_USER_ID: SessionUser?.UserId?.toString() || 0
    };
    setLoading(true);
    dispatch(getCMSummary(data))
      .then((response) => {
        if (response.payload?.length > 0) {
          setProductInformation(
            response.payload?.map((W) => ({
              SERVIS: W.KUL_BIRIM_KODU + '-' + W.SERVIS,
              FIS_NO: W.FIS_NO,
              ADSOYAD: W.ADSOYAD,
              STOK_NO: W.STOK_NO,
              CAGRI_TARIHI: W.CAGRI_TARIHI
                ? moment(W.CAGRI_TARIHI).format('DD.MM.YYYY')
                : '',
              ISLEM_TARIHI: W.ISLEM_TARIHI
                ? moment(W.ISLEM_TARIHI).format('DD.MM.YYYY')
                : '',
              URUN_ACIKLAMA: W.URUN_ACIKLAMA,
              DURUM: W.DURUM,
              ARON_DURUM_ACIKLAMA: W.ARON_DURUM,
              PARAIADESI: W.PARAIADESI,
              POOL_DESC: W.POOL_DESC,
              SICILNO: W.SICILNO,
              MUSTERI_TIP_ACIKLAMA: W.MUSTERI_TIP_ACIKLAMA,
              TEL1: W.TEL1,
              BIRIM_KODU: W.BIRIM_KODU,
              UST_BIRIM_ACIKLAMA: W.UST_BIRIM_KODU + '-' + W.UST_BIRIM_ACIKLAMA,
              SERVIS_TESLIM_TARIHI: W.SERVIS_TESLIM_TARIHI,
              SERINO: W.UYIL + '/' + W.UDONEM + '/' + W.SERINO,
              BASVURU_NO: W.BASVURU_NO,
              DEGISIM_FATURA_KALEM_ADET: W.DEGISIM_FATURA_KALEM_ADET,
              NITELIK_KODU: W.NITELIK_KODU,
              DEGISIM_FATURA_NO: W.DEGISIM_FATURA_NO
                ? W.DEGISIM_FATURA_NO
                : '-',
              SIRA: W.SIRA,
              BEKLEMEDE: W.BEKLEMEDE
            }))
          );
          dispatch(
            generalFollowActions.updateRowCount(response.payload.length)
          );
          dispatch(
            generalFollowActions.updateProductInformation(response.payload)
          );
          setLoading(false);
        } else {
          setLoading(false);
          setProductInformation([]);
          dispatch(generalFollowActions.updateProductInformation([]));
          dispatch(generalFollowActions.updateRowCount(0));
          return;
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return;
      });
  }

  function CMDetail(rowData) {
    dispatch(generalFollowActions.resetDetails());
    dispatch(informationChatActions.resetChat()); //Mesaj alanına gelen dataları resetler

    dispatch(getReceiptInfoChat([rowData.BASVURU_NO, rowData.BIRIM_KODU]));

    const data = {
      SessionUser: SessionUser?.UserId || 0,
      UnitCode: rowData.BIRIM_KODU,
      ApplicationNumber: rowData.BASVURU_NO
    };
    setLoading(true);
    dispatch(getDetail(data))
      .then((response) => {
        if (response?.payload?.length > 0) {
          setLoading(false);
          dispatch(generalFollowActions.updateActiveTab('2')); //Tab panel 2'yi açar.
          dispatch(
            generalFollowActions.updateOfferPrice(
              response?.payload[0]?.Exchange?.Price
            )
          );
          dispatch(
            generalFollowActions.updateRecomendedPrice(
              response?.payload[0]?.Exchange?.RecomendedPrice
            )
          );
          dispatch(
            generalFollowActions.updateTopModelDifferentPrice(
              response?.payload[0]?.Exchange?.ProductRemainPrice
            )
          );
          dispatch(generalFollowActions.exData(response?.payload));
        } else {
          setLoading(false);
          dispatch(generalFollowActions.updateActiveTab('2'));
          dispatch(generalFollowActions.resetDetails());
          dispatch(informationChatActions.resetChat());
          return <></>;
        }
      })
      .catch((error) => {
        setLoading(false);
        return;
      });
  }

  const handleChangeTabs = (event, newValue) => {
    dispatch(generalFollowActions.updateActiveTab(newValue));
  };

  function searchSummary() {
    setColumnHide(true);
    setProductInformation([]);
    dispatch(generalFollowActions.updateProductInformation([]));

    const data = {
      CustomerName: customerName?.trim().toString() || null,
      CustomerSurname: customerSurname?.trim().toString() || null,
      ReceiptNumber: receiptNumber?.trim().toString() || null,
      CustomerPhone: phoneNumber?.trim().toString() || null,
      Retailer: retailer?.trim().toString() || null,
      InvoiceNumber: invoiceNumber?.trim().toString() || null,
      ModelCode: modelCode?.trim().toString() || null,
      SessionUser: SessionUser?.UserId?.toString() || null
    };
    setLoading(true);
    dispatch(getCMSearch(data))
      .then((response) => {
        if (response.payload?.length > 0) {
          setProductInformation(
            response.payload?.map((W) => ({
              ADSOYAD: W.ADSOYAD,
              BASVURU_NO: W.BASVURU_NO,
              BIRIM_KODU: W.BIRIM_KODU,
              CAGRI_TARIHI: W.CAGRI_TARIHI
                ? moment(W.CAGRI_TARIHI).format('DD.MM.YYYY')
                : '',
              FIS_NO: W.FIS_NO,
              ISLEM_TARIHI: W.ISLEM_TARIHI
                ? moment(W.ISLEM_TARIHI).format('DD.MM.YYYY')
                : '',
              SERINO: W.UYIL + '/' + W.UDONEM + '/' + W.SERINO,
              SERVIS: W.KUL_BIRIM_KODU + '-' + W.SERVIS,
              URUN_ACIKLAMA: W.URUN_ACIKLAMA,
              DURUM: W.DURUM,
              TEL1: W.TEL1,
              UST_BIRIM_ACIKLAMA: W.UST_BIRIM_KODU + '-' + W.UST_BIRIM_ACIKLAMA,
              BASVURU_NO: W.BASVURU_NO,
              NITELIK_KODU: W.NITELIK_KODU,
              SIRA: W.SIRA,
              BEKLEMEDE: 0
            }))
          );
          dispatch(
            generalFollowActions.updateRowCount(response.payload.length)
          );
          dispatch(
            generalFollowActions.updateProductInformation(response.payload)
          );
          setLoading(false);
          setCustomerName('');
          setCustomerSurname('');
          setReceiptNumber('');
          setPhoneNumber('');
          setRetailer('');
          setModelCode('');
          setInvoiceNumber('');
        } else {
          setLoading(false);
          dispatch(generalFollowActions.updateRowCount(0));
          setProductInformation([]);
          dispatch(generalFollowActions.updateProductInformation([]));
          InfoAlert('Aradığınız kriterde sonuç bulunamadı.', 'error', 'Hata');
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        return;
      });
  }

  function GetCity(value) {
    setSelectedRegion(value);
    var RegionList = [];
    value.map((i) => {
      RegionList.push(i.Id);
    });
    setLoading(true);
    dispatch(getCityItems({ ItemId: '2', ItemValue: RegionList.toString() }))
      .then((response) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  function DataGridSorting(model) {
    if (model.length == 0) {
      dispatch(generalFollowActions.updateDataGridSort(dataSort));
      return;
    }
    dispatch(generalFollowActions.updateDataGridSort(model[0]));
  }
  function TakeBackPage() {
    dispatch(generalFollowActions.updateActiveTab('1'));
  }
  function waitingPool() {
    if (!waitingDsc) {
      InfoAlert(
        'Lütfen beklemeye alma nedeninizi yazınız!...',
        'error',
        'Uyarı'
      );
    }
    Log();

    setLoading(true);
    const data = {
      Dsc: waitingDsc.toString(),
      ServiceNumber: waitingServiceNumber, //Servis Numarası
      ApplicationNumber: waitingApplicationNumber?.toString() //Başvuru No
      //SessionUser: SessionUser?.UserId?.toString()
    };
    dispatch(waitingPoolReceipt(data))
      .then((response) => {
        setWaitingDsc('');
        setWaitingReasonsPopUp(false);
        setLoading(false);
        if (response.payload.ErrorCode == '0') {
          InfoAlert(response.payload.ErrorDescription, 'success', 'Başarılı');
          Pools();
        } else {
          InfoAlert(response.payload.ErrorDescription, 'error', 'Hata');
        }
      })
      .catch((err) => {
        setWaitingDsc('');
        setWaitingReasonsPopUp(false);
        setLoading(false);
        return;
      });
  }

  function updateWaitingPoolReceipt() {
    if (!waitingDsc) {
      InfoAlert(
        'Lütfen beklemeye alma nedeninizi yazınız!...',
        'error',
        'Uyarı'
      );
      return;
    }
    Log();
    setLoading(true);
    const data = {
      Dsc: waitingDsc.toString(),
      ServiceNumber: waitingServiceNumber, //Servis Numarası
      ApplicationNumber: waitingApplicationNumber?.toString() //Başvuru No
      //SessionUser: SessionUser?.UserId?.toString()
    };
    dispatch(waitingPoolReceiptUpdate(data))
      .then((response) => {
        setWaitingDsc('');
        setWaitingReasonUpdatePopUp(false);
        setLoading(false);
        if (response.payload.ErrorCode == '0') {
          InfoAlert(response.payload.ErrorDescription, 'success', 'Başarılı');
          Pools();
        } else {
          InfoAlert(response.payload.ErrorDescription, 'error', 'Hata');
        }
      })
      .catch((err) => {
        setWaitingDsc('');
        setWaitingReasonUpdatePopUp(false);
        setLoading(false);
        return;
      });
  }

  function InsertFisListControl(rowData) {
    const data = {
      SicilNo: SessionUser?.UserId?.toString(),
      FisNo: rowData.FIS_NO
    };

    dispatch(insertFisListControl(data));
  }

  function GetFisListControl() {
    dispatch(getFisListControl(SessionUser?.UserId?.toString())).then(
      (response) => {
        if (response.payload?.length > 0) {
          setFisListControl(response.payload);
        }
      }
    );
  }

  function Log() {
    var Message = '';
    const LogData = {
      ServiceNumber: waitingServiceNumber.toString(), //Servis Numarası
      ApplicationNumber: waitingApplicationNumber.toString(), //Başvuru No
      UserID: invoiceData[0]?.UserID,
      Status: waitingStatus,
      Note: waitingDsc?.toString()
    };

    if (waitingDsc != '') {
      Message = 'Bekleme Nedeni:' + ' ' + waitingDsc?.toString();
    }

    LogData.Note = Message;

    axios
      .post('WaitingReasonLog', LogData, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setLoading(false);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  return (
    <>
      <AlertAndLoading
        Message={responseMessage}
        open={uyari}
        AlertType={alertType}
        onClose={uyariKapat}
        loading={loading}
      />
      {activeTab == 2 ? (
        <Grid container spacing={2}>
          <Grid item xs={11}></Grid>
          <Grid item xs={1}>
            <Tooltip title="Geri Dön">
              <IconButton
                onClick={() => {
                  TakeBackPage();
                }}
              >
                <ArrowBackIcon style={{ color: 'orange' }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      ) : null}
      <Card>
        <TabContext value={activeTab}>
          <AppBar
            position="static"
            sx={{ backgroundColor: '#FF9800', textColor: '#424242' }}
          >
            <Tabs
              onChange={handleChangeTabs}
              sx={{
                textColor: '#424242',
                '& .css-1ujykiq-MuiButtonBase-root-MuiTab-root.Mui-selected': {
                  backgroundColor: '#424242',
                  color: '#FF9800'
                },
                '.css-251er.Mui-selected ': {
                  backgroundColor: '#424242',
                  color: '#FF9800'
                }
              }}
              TabIndicatorProps={{
                style: {
                  backgroundColor: '#FF9800',
                  color: '#FF9800'
                }
              }}
              value={activeTab}
              variant="fullWidth"
            >
              <Tab label="Açık İşler" value="1" />
              <Tab label="Detay" value="2" disabled />
            </Tabs>
          </AppBar>
          <TabPanel value="1" dir={theme.direction}>
            <Grid>
              {userRole?.filter((i) => i.COMPONENT_ID == 1).length > 0 ? (
                <Card>
                  <>
                    <Accordion
                      expanded={expanded === 'panel1'}
                      onChange={(event, isExpanded) =>
                        setExpanded(isExpanded ? 'panel1' : false)
                      }
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <Typography
                          sx={{ width: '33%', flexShrink: 0 }}
                          variant="inherit"
                        >
                          Detay Ara
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid item xs={3}>
                            <Autocomplete
                              multiple
                              limitTags={1}
                              id="multiple-limit-tags"
                              disableCloseOnSelect
                              options={regionData}
                              getOptionLabel={(option) => option.Dsc}
                              value={selectedRegion || 0}
                              size="small"
                              fullWidth
                              onChange={(event, val) => {
                                GetCity(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Bölge"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              multiple
                              limitTags={1}
                              id="multiple-limit-tags"
                              disableCloseOnSelect
                              options={cityData}
                              getOptionLabel={(option) => option.Dsc}
                              value={selectedCity || 0}
                              size="small"
                              fullWidth
                              onChange={(event, val) => {
                                setSelectedCity(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Servis"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3} size="small">
                            <Autocomplete
                              multiple
                              id="checkboxes-tags-demo"
                              size="small"
                              options={customerTypes}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.Dsc}
                              onChange={(event, val) =>
                                setSelectedCustomerType(val)
                              }
                              value={selectedCustomerType}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              style={{ width: '100%' }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Müşteri Tipi"
                                  placeholder="Müşteri Tipi"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3} size="small">
                            <Autocomplete
                              multiple
                              limitTags={1}
                              id="multiple-limit-tags"
                              disableCloseOnSelect
                              options={changeReasonData}
                              getOptionLabel={(option) => option.Dsc}
                              size="small"
                              fullWidth
                              value={selectedChangeReason || 0}
                              onChange={(event, val) => {
                                setChangeReason(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Değişim nedeni"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              limitTags={1}
                              multiple
                              disableCloseOnSelect
                              id="multiple-limit-tags"
                              options={aronStatusData}
                              getOptionLabel={(option) => option.Dsc}
                              value={selectedAronStatus || 0}
                              size="small"
                              fullWidth
                              onChange={(event, val) => {
                                setAronStatus(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Fiş Durum"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Autocomplete
                              limitTags={1}
                              multiple
                              disableCloseOnSelect
                              id="multiple-limit-tags"
                              options={refundMoney}
                              getOptionLabel={(option) => option.Dsc}
                              value={selectedRefundMoney || 0}
                              size="small"
                              fullWidth
                              onChange={(event, val) => {
                                setRefundMoney(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Para İadesi / Değişim Çeki Durumu"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={3}>
                            <Autocomplete
                              multiple
                              limitTags={1}
                              id="multiple-limit-tags"
                              disableCloseOnSelect
                              options={statusData}
                              getOptionLabel={(option) => option.Dsc}
                              value={selectedStatus || 0}
                              size="small"
                              fullWidth
                              onChange={(event, val) => {
                                setStatus(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Durum"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              multiple
                              limitTags={1}
                              id="multiple-limit-tags"
                              disableCloseOnSelect
                              options={subStatusData}
                              getOptionLabel={(option) => option.Dsc}
                              value={selectedSubStatus || 0}
                              size="small"
                              fullWidth
                              onChange={(event, val) => {
                                setSubStatus(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Alt Durum"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              multiple
                              limitTags={1}
                              id="multiple-limit-tags"
                              disableCloseOnSelect
                              options={productsData}
                              getOptionLabel={(option) => option.Dsc}
                              value={selectedProduct || 0}
                              size="small"
                              fullWidth
                              onChange={(event, val) => {
                                setProduct(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Ürün Grubu"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <Autocomplete
                              multiple
                              limitTags={1}
                              id="multiple-limit-tags"
                              disableCloseOnSelect
                              options={confirmTypeData}
                              getOptionLabel={(option) => option.Dsc}
                              value={selectedConfirmType || 0}
                              size="small"
                              fullWidth
                              onChange={(event, val) => {
                                setConfirmType(val);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.Dsc}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Onay Tipi"
                                  variant="outlined"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={trLocale}
                            >
                              <DatePicker
                                label="Başlangıç Tarihi"
                                value={startDate}
                                format={'dd.MM.yyyy'}
                                onChange={(e) => handleChangeStartDate(e)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={3}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              locale={trLocale}
                            >
                              <DatePicker
                                label="Bitiş Tarihi"
                                value={endDate}
                                format={'dd.MM.yyyy'}
                                onChange={(e) => handleChangeEndDate(e)}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    fullWidth
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={10}></Grid>
                          <Grid item xs={2}>
                            <Stack spacing={4} direction="row">
                              <Button
                                fullWidth
                                size="medium"
                                onClick={() => searchProductSummary()}
                                sx={{
                                  color: '#FF9800',
                                  backgroundColor: '#424242'
                                }}
                              >
                                Ara
                              </Button>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </>
                </Card>
              ) : null}
            </Grid>
            <Grid style={{ paddingTop: 5 }}>
              <Card>
                <>
                  <Accordion
                    expanded={expandedSearch === 'panel2'}
                    onChange={(event, isExpanded) =>
                      setExpandedSearch(isExpanded ? 'panel2' : false)
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        sx={{ width: '33%', flexShrink: 0 }}
                        variant="inherit"
                      >
                        Özet Ara
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={3}>
                          <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <TextField
                              id="input-with-icon-textfield"
                              label="Müşteri Ad "
                              size="small"
                              type="text"
                              autoComplete="off"
                              fullWidth
                              value={customerName}
                              onChange={(event) => {
                                setCustomerName(event.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ManageSearchIcon
                                      sx={{ color: '#424242' }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <TextField
                              id="input-with-icon-textfield"
                              label="Müşteri Soyad"
                              size="small"
                              type="text"
                              autoComplete="off"
                              fullWidth
                              value={customerSurname}
                              onChange={(event) => {
                                setCustomerSurname(event.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ManageSearchIcon
                                      sx={{ color: '#424242' }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <TextField
                              id="input-with-icon-textfield"
                              label="Müşteri Telefon"
                              size="small"
                              type="number"
                              fullWidth
                              autoComplete="off"
                              inputProps={{
                                maxLength: 11
                              }}
                              value={phoneNumber}
                              onChange={(event) =>
                                setPhoneNumber(event.target.value)
                              }
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ManageSearchIcon
                                      sx={{ color: '#424242' }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={3}>
                          <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <TextField
                              id="input-with-icon-textfield"
                              autoComplete="off"
                              label="Fiş Numarası"
                              size="small"
                              InputLabelProps={{ className: classes.FisNoBox }}
                              fullWidth
                              inputProps={{
                                maxLength: 9
                              }}
                              value={receiptNumber}
                              onChange={(event) => {
                                setReceiptNumber(event.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ManageSearchIcon
                                      sx={{ color: '#424242' }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <TextField
                              id="input-with-icon-textfield"
                              label="Fatura Numarası"
                              size="small"
                              type="text"
                              fullWidth
                              autoComplete="off"
                              value={invoiceNumber}
                              onChange={(event) => {
                                setInvoiceNumber(event.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ManageSearchIcon
                                      sx={{ color: '#424242' }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <TextField
                              id="input-with-icon-textfield"
                              label="Bayi"
                              size="small"
                              type="text"
                              fullWidth
                              autoComplete="off"
                              value={retailer}
                              onChange={(event) => {
                                setRetailer(event.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ManageSearchIcon
                                      sx={{ color: '#424242' }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box sx={{ '& > :not(style)': { m: 1 } }}>
                            <TextField
                              id="input-with-icon-textfield"
                              label="Model Kodu"
                              size="small"
                              type="number"
                              fullWidth
                              value={modelCode}
                              autoComplete="off"
                              onChange={(event) => {
                                setModelCode(event.target.value);
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <ManageSearchIcon
                                      sx={{ color: '#424242' }}
                                    />
                                  </InputAdornment>
                                )
                              }}
                              variant="outlined"
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={10}></Grid>
                        <Grid item xs={2}>
                          <Stack spacing={4} direction="row">
                            <Button
                              fullWidth
                              size="medium"
                              onClick={() => searchSummary()}
                              sx={{
                                color: '#FF9800',
                                backgroundColor: '#424242'
                              }}
                            >
                              Ara
                            </Button>
                          </Stack>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </>
              </Card>
            </Grid>
            <Grid style={{ paddingTop: 5 }}>
              <Box
                sx={{
                  height: 1000,
                  width: '100%',
                  '& .cold': {
                    backgroundColor: '#FFFFFF',
                    color: 'black'
                  },
                  '& .hot': {
                    backgroundColor: '#FF9800',
                    color: '#424242',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#FF9800!important',
                      color: '#424242!important',
                      fontWeight: 'bold!important'
                    }
                  }
                }}
              >
                <DataGrid
                  sx={{ paddingBottom: '3rem' }}
                  columns={columns}
                  rows={UpdatedProductInformation}
                  rowsPerPageOptions={[25, 50, 100, 250, 500, 1000]}
                  getRowId={(row) => row.SIRA}
                  onRowClick={(row) => {
                    CMDetail(row.row);
                    InsertFisListControl(row.row);
                    setFisExpanded(false);
                  }}
                  getRowClassName={(params) =>
                    params.row.NITELIK_KODU == 1 ? 'hot' : 'cold'
                  }
                  components={{
                    Toolbar: QuickSearchToolbar,
                    NoRowsOverlay: CustomNoRowsOverlay
                  }}
                  localeText={
                    trTR.components.MuiDataGrid.defaultProps.localeText
                  }
                  onSortModelChange={(model) => {
                    DataGridSorting(model);
                  }}
                  initialState={{
                    sorting: {
                      sortModel: dataSort
                        ? [{ field: dataSort?.field, sort: dataSort?.sort }]
                        : [{ field: 'SIRA', sort: 'asc' }]
                    }
                  }}
                />
              </Box>
            </Grid>
          </TabPanel>
          <TabPanel value="2" dir={theme.direction}>
            <InvoiceDetail />
          </TabPanel>
        </TabContext>
        <Accordion
          expanded={fisExpanded}
          onChange={() => {
            setFisExpanded(!fisExpanded);
          }}
          className={classes.AccordionStyle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
            sx={{ color: 'black', backgroundColor: 'orange' }}
            onClick={
              !fisExpanded
                ? () => {
                    GetFisListControl();
                  }
                : () => {}
            }
          >
            <HistorySharpIcon sx={{ color: 'white', marginTop: '2px' }} />
            <Typography
              className={classes.div}
              variant="subtitle1"
              sx={{ ml: 1 }}
            >
              Fiş Listem
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ overflowY: 'auto', maxHeight: 350 }}>
            <Divider />
            {fisListControl?.map((row, index) => (
              <>
                <List
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                    maxWidth: 360
                  }}
                >
                  <ListItemIcon key={index.ID}>
                    <ListItemButton>
                      <ReceiptLongSharpIcon sx={{ color: 'green' }} />
                    </ListItemButton>
                  </ListItemIcon>

                  <ListItem key={index.ID}>
                    <ListItemButton
                      onClick={() => {
                        CMDetail(row);
                        InsertFisListControl(row);
                        setFisExpanded(false);
                      }}
                    >
                      <SelectableListItemText primary={row.FIS_NO} />
                      {/* <ListItemText primary={row.FISNO} /> */}
                    </ListItemButton>
                  </ListItem>
                </List>
                <Divider />
              </>
            ))}
          </AccordionDetails>
        </Accordion>
      </Card>
      <>
        <Print open={printOpen} setOpen={setPrintOpen} data={printData} />
      </>

      {/* Bekleme nedeni için popup */}

      <Dialog
        open={waitingReasonPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogContent>
          <Typography
            sx={{ width: '100%', color: 'text.secondary', fontWeight: 'Bold' }}
            variant="subtitle1"
          >
            {waitingReceiptNo +
              ' ' +
              'fişi beklemeye alıyorsunuz. Beklemeye almak istediğinizden emin misiniz ? Lütfen bekleme sebebini yazınız.'}
          </Typography>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                id="Dsc"
                label="Bekleme Sebebi Giriniz..."
                size="small"
                value={waitingDsc}
                fullWidth
                autoFocus
                autoComplete="off"
                variant="outlined"
                onChange={(event) => {
                  setWaitingDsc(event.target.value);
                }}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Beklemeye Al">
            <IconButton
              color="primary"
              onClick={() => {
                waitingPool();
              }}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <PhonePausedIcon sx={{ color: 'green' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Kapat">
            <IconButton
              color="primary"
              onClick={() => {
                setWaitingReasonsPopUp(false);
                setWaitingDsc('');
              }}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
      {/* Bekleme nedeni Güncelleme İçin PopUp */}
      <Dialog
        open={waitingReasonUpdatePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogContent>
          <Typography
            sx={{ width: '100%', color: 'text.secondary', fontWeight: 'Bold' }}
            variant="subtitle1"
          >
            {'Lütfen Yeni Bekleme Sebebini Giriniz...'}
          </Typography>
          <br></br>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                id="Dsc"
                label="Yeni Bekleme Sebebi Giriniz..."
                size="small"
                value={waitingDsc}
                fullWidth
                autoFocus
                autoComplete="off"
                variant="outlined"
                onChange={(event) => {
                  setWaitingDsc(event.target.value);
                }}
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Tooltip title="Güncelle">
            <IconButton
              color="primary"
              onClick={() => {
                updateWaitingPoolReceipt();
              }}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloudSyncIcon sx={{ color: 'green' }} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Kapat">
            <IconButton
              color="primary"
              onClick={() => {
                setWaitingReasonUpdatePopUp(false);
                setWaitingDsc('');
              }}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default GeneralFollow;
