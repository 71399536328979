import ApartmentIcon from '@mui/icons-material/Apartment';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import SaveIcon from '@mui/icons-material/Save';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Snackbar,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Divider
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useRef } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import {
  generalFollowActions,
  getDetail
} from 'src/store/slices/generalFollowSlice';
import { getReceiptInfoChat } from 'src/store/slices/informationChatSlice';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import AddTaskIcon from '@mui/icons-material/AddTask';
import axios from 'src/utils/axios';
import { getUser } from 'src/utils/Common';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  ButonName: {
    color: 'black',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif'
  }
}));

function ExchangeOfficeProcess() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();

  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );
  const receiptStatus = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  )[0]?.Exchange?.Status;
  const updatedInfoChat = useSelector(
    (state) => state.generalFollowSlice.updatedInfoChat
  );
  const UpdatedInformation = useSelector(
    (state) => state.generalFollowSlice.updatedProductInformation
  );
  const exData = useSelector((state) => state.generalFollowSlice.exDatas);
  const subModelDifferenceAmount = useSelector(
    (state) => state.generalFollowSlice.updatedSubModelDifferenceAmount
  );
  const offerProductPrice = useSelector(
    (state) => state.generalFollowSlice.updatedOfferPrice
  );
  const topModelDifferentPrice = useSelector(
    (state) => state.generalFollowSlice.updatedTopModelDifferentPrice
  );
  const totalProductPrice =
    parseFloat(topModelDifferentPrice) + parseFloat(offerProductPrice);

  const [smsSendModelSuggestPopUp, setSmsSendModelSuggestPopUp] =
    React.useState(false);
  const [smsModelSuggest, setSmsModelSuggest] = React.useState(false);
  const [sapResponsePopUp, setSapResponsePopUp] = React.useState(false);
  const [cancelSapPopUp, setCancelSapPopUp] = React.useState(false);
  const [warningMessagePopUp, setWarningMessagePopUp] = React.useState(false);
  const [sapResponse, setSapResponse] = React.useState('');
  const [cancelSapNote, setCancelSapNote] = React.useState('');
  const [warningMessage, setWarningMessage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  // const [TaniOpenResponsePopUp, setTaniOpenResponsePopUp] = React.useState(false);
  // const [taniResponse, setTaniOpenResponse] = React.useState(null);
  // const [webPointCancelType, setWebPointCancelType] = React.useState(invoiceData[0].Exchange.WebPointCancelType);
  // const [iptalTanıUyarıPopUp, setIptalTanıUyarıPopUp] = React.useState(false);
  const [differentBrandSendManagerPopUp, setDifferentBrandSendManagerPopUp] =
    React.useState(false);
  const [differentBrandApplyMessage, setDifferentBrandApplyMessage] =
    React.useState('');
  const [invoiceCountAndTotalPriceByVkn, setInvoiceCountAndTotalPriceByVkn] =
    React.useState([]);
  const [sendToSapPopUp, setSendToSapPopUp] = React.useState(false);
  const [receiptStatusForSapWarningPopUp, setReceiptStatusForSapWarningPopUp] =
    React.useState([]);

  // Sap Gönder methodunun aynı anda 2 kere tetiklenmesini engellemek amaçlı eklenmiştir. UseState asenkron olduğundan düzgün sonuç vermediği için useRef kullanılmıştır.
  const isRunningRef = useRef(false);

  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: 'success_message'
  });
  const [uyari, setUyari] = React.useState(false);
  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  const brands = [3, 4, 18, 28, 43];

  useEffect(() => {
    getInvoiceCountByVKNAndStatusSAP(
      invoiceData[0]?.Exchange?.Service,
      invoiceData[0]?.Exchange?.ApplyNo
    );
  }, []);

  const data = {
    ServiceNumber: invoiceData[0]?.Exchange?.Service.toString(), //Servis Numarası
    ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(), //Başvuru No
    OutOfStok: invoiceData[0]?.Exchange?.NoneProduct?.toString(), //Teminsiz Stok
    Status: invoiceData[0]?.Exchange?.Status,
    BankCode: invoiceData[0]?.Exchange?.BankCode?.toString(), //Banka Kodu
    BankName: invoiceData[0]?.Exchange?.BankName?.toString(), //Banka Adı
    IBANNO: invoiceData[0]?.Exchange?.IBANNO?.toString(), //Iban No
    WebSalesInvoiceNumber: invoiceData[0]?.Exchange?.WebSalesInvoiceNumber, //Web Satış Fatura No
    PaymentOwner: invoiceData[0]?.Exchange?.ABSPayAccount?.toString(), //Ödeme Sahibi
    InvoiceNumber:
      invoiceData[0]?.Consumer?.ChangeInvoiceNo?.toString() || null, //Fatura No
    InvoiceDate:
      invoiceData[0]?.Consumer?.ChangeInvoiceDate?.toString() || null, //Fiş Tarihi
    InvoiceItemQuantity: invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity, //DEGISIM_FATURA_KALEM_ADET
    InvoicePrice: invoiceData[0]?.Consumer?.ChangeInvoiceAmount, // Fatura tutarı
    ChangeInvoiceItemOrder:
      invoiceData[0]?.Consumer?.ChangeInvoiceItemOrder || null, // dEGISIM_FATURA_KALEM_SIRA
    ExchangeReason: invoiceData[0]?.Exchange?.ExchangeResonMain, //altDegisimNedeni
    ExchangeReasonDetail: invoiceData[0]?.Exchange?.ExchangeReson, //degisimNedeniDetay
    IVRGetPrice: invoiceData[0]?.Exchange?.IVRGetPrice?.toString(),
    UpdateUser: SessionUser?.UserId?.toString(),
    Note: updatedInfoChat?.toString(), //Mesaj alanı
    GiveProductNumber: invoiceData[0]?.Model?.UserModelCode?.toString() || '', //kul model kodu !! tekrar sor
    ServiceRefundReason:
      invoiceData[0]?.Exchange?.ServiceRefund?.toString() || '',
    ExchangeType: invoiceData[0]?.Consumer?.LowTypeId, //alt onay türü
    CurrentStatus: invoiceData[0]?.Exchange?.Status, //mevcut durum
    CustomerType: invoiceData[0]?.Consumer?.ConsumerType, //Müşteri tipi
    TopConfirmType: invoiceData[0]?.Consumer?.UpTypeId, //Üst onay türü
    UpConfirmType: invoiceData[0]?.Consumer?.LowTypeId, //alt onay türü
    ProductGetStockNumber:
      invoiceData[0]?.Model?.RevisionStokNo?.toString() || null, // revizyonlu stok numarası
    UYear: invoiceData[0]?.Apply?.UYear,
    UMonth: invoiceData[0]?.Apply?.UMonth,
    City:
      invoiceData[0]?.Consumer?.ConsumerType == 3
        ? invoiceData[0]?.Retailer?.City
        : invoiceData[0]?.Consumer?.City, //sehir
    UserModelCode: invoiceData[0]?.Model?.UserModelCode?.toString() || null,
    SubStatus: invoiceData[0]?.Exchange?.SubStatus,
    GiveProductStokNumber:
      invoiceData[0]?.Exchange?.GiveProductStokNumber?.toString() || '', //verilenUrunStokNo
    GiveProductDesc:
      invoiceData[0]?.Exchange?.GiveProductDesc?.toString() || null, //verilen ürün açıklama
    GiveProductPrice: invoiceData[0]?.Exchange?.GiveProductPrice || null, //VERİLEN ÜRÜN Ücret
    stokTotal: invoiceData[0]?.Exchange?.stokTotal?.toString() || null,
    ProductTotalPrice:
      invoiceData[0]?.Exchange?.ProductTotalPrice?.toString() || null, //toplam tutar
    RecomendedPrice:
      invoiceData[0]?.Exchange?.RecomendedPrice?.toString() || null, //Tavsiye edilen ürün değişim ücreti
    Price: invoiceData[0]?.Exchange?.Price?.toString() || null, //ucretliDegisimTutari ,
    TopModelDifPrice:
      invoiceData[0]?.Exchange?.ProductRemainPrice?.toString() || null, //üst model fark tutar
    PaymentOwner: invoiceData[0]?.Exchange?.ABSPayAccount?.toString() || null, //dekont sahibi
    RefundConvince: invoiceData[0]?.Exchange?.RefundConvince?.toString() || '', //ikna nedenleri
    UpdateStatus: -1,
    UserID: invoiceData[0]?.UserID,
    ProductBuyStockNumber: invoiceData[0]?.Model.RevisionStokNo, //alınan ürün stok number
    ProductBuyStockPrice: invoiceData[0]?.Exchange?.ReceivedProductPrice, //alınan ürün fiyat
    PaymentCustomerTC:
      invoiceData[0]?.Exchange?.RefundCustomerTC?.toString() || null, //Tc müşteri
    PaymentCustomerName:
      invoiceData[0]?.Exchange?.RefundCustomerName?.toString() || null, //müşteri ad
    isServiceGetProduct: invoiceData[0]?.Exchange?.ServiceTakeCustomerProduct,
    ChangeSasNo: invoiceData[0]?.Consumer.ChangeSasNo,
    ChangeSdNo: invoiceData[0]?.Consumer.ChangeSdNo,
    WebPointInvoicePrice: invoiceData[0]?.Exchange?.WebPointInvoicePrice,
    WebPointCustomerPhoneNumber:
      invoiceData[0]?.Exchange.WebPointCustomerPhoneNumber,
    WebPointProductStokNumber: invoiceData[0]?.Exchange?.WebPointStockNumber
      ? invoiceData[0]?.Exchange?.WebPointStockNumber
      : invoiceData[0]?.Exchange?.EqualProductStokNo
        ? invoiceData[0]?.Exchange?.EqualProductStokNo
        : invoiceData[0]?.Model?.UserModelCode,
    WebPointAvaiblePrice: invoiceData[0]?.Exchange?.WebPointAvaiblePrice,
    WebPointCancelType: invoiceData[0]?.Exchange.WebPointCancelType,
    DifferentBrandManagerControl:
      invoiceData[0]?.Exchange?.DifferentBrandManagerControl,
    SubModelDifferenceAmount: invoiceData[0]?.Exchange?.SubModelDifferenceAmount
      ? invoiceData[0]?.Exchange?.SubModelDifferenceAmount
      : 0
  };

  function getInvoiceCountByVKNAndStatusSAP(ServiceNumber, ApplicationNumber) {
    axios
      .get(
        `/GetInvoiceCountByVKNAndStatusSAP/${ServiceNumber}/${ApplicationNumber}`,
        {}
      )
      .then((response) => {
        if (response?.data?.length > 0) {
          const data = response.data[0];
          setInvoiceCountAndTotalPriceByVkn(data);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  function removeRowInTable() {
    const orjinalData = [...UpdatedInformation];
    const removeRow = orjinalData?.findIndex(
      (x) => x.FIS_NO == invoiceData[0]?.Apply?.ReceiptNumber
    );
    orjinalData.splice(removeRow, 1);
    const xd = orjinalData.length;
    dispatch(generalFollowActions.updateProductInformation(orjinalData));
    dispatch(generalFollowActions.updateRowCount(xd));
  }

  function Log() {
    var SubStatusLog = '';
    var Msj = '';
    var TotalPrice = '';
    var GiveProductLog = '';
    var IbanNoLog = '';
    if (exData[0]?.Exchange?.SubStatus != invoiceData[0]?.Exchange?.SubStatus) {
      var SubStatusLog =
        'Ara Durum:' + ' ' + invoiceData[0]?.Exchange?.IndermediateState;
    }
    if (
      exData[0]?.Exchange?.ProductTotalPrice !=
      invoiceData[0]?.Exchange?.ProductTotalPrice
    ) {
      var TotalPrice =
        'Tutar:' + ' ' + invoiceData[0]?.Exchange?.ProductTotalPrice.toString();
    }
    if (
      exData[0]?.Exchange?.GiveProductStokNumber !=
      invoiceData[0]?.Exchange?.GiveProductStokNumber
    ) {
      GiveProductLog =
        'Verilen Ürün:' +
        ' ' +
        invoiceData[0]?.Exchange?.GiveProductStokNumber +
        '-' +
        invoiceData[0]?.Exchange?.GiveProductDesc;
    }
    if (updatedInfoChat != '') {
      var Msj = 'Mesaj:' + ' ' + updatedInfoChat?.toString();
    }
    var IbanNoControl = !exData[0].Exchange.IBANNO
      ? 0
      : exData[0].Exchange.IBANNO;
    var IbanNoChanged = !invoiceData[0].Exchange.IBANNO
      ? 0
      : invoiceData[0].Exchange.IBANNO;
    if (IbanNoChanged != 0 && IbanNoControl != IbanNoChanged) {
      IbanNoLog = 'Iban No:' + IbanNoChanged + ' olarak değiştirildi.';
    }
    data.Note =
      SubStatusLog +
      ' ' +
      ' ' +
      TotalPrice +
      ' ' +
      GiveProductLog +
      ' ' +
      IbanNoLog +
      ' ' +
      Msj;
  }

  function InfoAlert(ErrorDescription) {
    setresponseMessage({
      ErrorCode: 0,
      ErrorDescription: ErrorDescription
    });
    setUyari(true);
    return;
  }

  function AllSave() {
    //Herşeyi kayıt eder
    if (
      invoiceData[0]?.Exchange?.GiveProductPrice != null &&
      invoiceData[0]?.Exchange?.GiveProductPrice < 1
    ) {
      InfoAlert('Mal bedeli 1 den küçük olamaz');
      return;
    }
    if (
      invoiceData[0]?.Consumer.UpTypeId === 2 &&
      invoiceData[0]?.Exchange?.ReceivedProductPrice != null &&
      invoiceData[0]?.Exchange?.ReceivedProductPrice < 1
    ) {
      InfoAlert('Alınan Parakende Fiyat 1 TL den Küçük Olamaz');
      return;
    }
    //MHTU-1539
    //fatura numarası 16 haneli olmalı ve 1111111111111111 olamaz checkleri
    if (invoiceData[0]?.Exchange?.WebSalesInvoiceNumber) {
      if (invoiceData[0]?.Exchange?.WebSalesInvoiceNumber.length != 16) {
        InfoAlert('Lütfen 16 haneli web satış fatura numarasını giriniz.');
        return;
      }
      if (
        invoiceData[0]?.Exchange?.WebSalesInvoiceNumber === '1111111111111111'
      ) {
        InfoAlert('Lütfen geçerli bir web satış fatura numarası giriniz.');
        return;
      }
    }
    confirmAlert({
      message: 'Kaydetmek İstediğinizden Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            Log();
            data.Status = invoiceData[0]?.Exchange?.Status;
            axios
              .post('SetExchangeOfficeSave', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setresponseMessage({
                  ErrorCode: 1000,
                  ErrorDescription: 'İşleminiz başarı ile gerçekleşmiştir.'
                });
                setUyari(true);
                dispatch(generalFollowActions.updateInfoChat(''));
                const data = {
                  SessionUser: SessionUser?.UserId || 0,
                  UnitCode: invoiceData[0]?.Exchange?.Service,
                  ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo
                };
                dispatch(getDetail(data))
                  .then((response) => { })
                  .catch((err) => {
                    setLoading(false);
                    return;
                  });
                dispatch(
                  getReceiptInfoChat([
                    invoiceData[0]?.Exchange?.ApplyNo,
                    invoiceData[0]?.Exchange?.Service
                  ])
                )
                  .then((response) => { })
                  .catch((err) => {
                    setLoading(false);
                    return;
                  });
                setLoading(false);
                return;
              })
              .catch((err) => {
                setresponseMessage({
                  ErrorCode: 0,
                  ErrorDescription: err.response.data
                });
                setUyari(true);
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }
  function rejectToService() {
    //fiş servise atama
    confirmAlert({
      message: 'Servise Geri Göndermek İstediğinizden Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            if (!updatedInfoChat && updatedInfoChat == '') {
              setresponseMessage({
                ErrorCode: 0,
                ErrorDescription: 'Lütfen Mesaj Giriniz!'
              });
              setUyari(true);
              return;
            }
            setLoading(true);
            Log();
            data.UpdateStatus = -1;
            axios
              .post('ExchangeOfficeUpdateRejectServicePaper', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                if (response.data == true) {
                  setresponseMessage({
                    ErrorCode: 1000,
                    ErrorDescription: 'İşleminiz Başarılı ile gerçekleşti...'
                  });
                  setUyari(true);
                  removeRowInTable();
                  setTimeout(pageUpdate, 500);
                  setLoading(false);
                }
                return;
              })
              .catch((err) => {
                setresponseMessage({
                  ErrorCode: 0,
                  ErrorDescription: err.response.data
                });
                setUyari(true);
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function BackToExOfficeForWaitingServiceOldProduct() {
    //fişi servis ürünü almaya bekleniyor konumundan değişim ofisine alma
    confirmAlert({
      message:
        'Servisin eski ürünü bekleniyor konumundan değişim ofisine geri alınıyor.Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            const data = {
              ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo.toString(),
              ServiceNumber: invoiceData[0]?.Exchange?.Service.toString(),
              UserID: invoiceData[0]?.UserID || 0
            };
            setLoading(true);
            axios
              .post('BackToExchangeOfficeForWaitingServiceOldProduct', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                if (response.data.ErrorCode == 0) {
                  setresponseMessage({
                    ErrorCode: 1000,
                    ErrorDescription: response.data.ErrorDescription
                  });
                  setUyari(true);
                  removeRowInTable();
                  setTimeout(pageUpdate, 500);
                  setLoading(false);
                  return;
                } else {
                  setresponseMessage({
                    ErrorCode: 0,
                    ErrorDescription: response.data.ErrorDescription
                  });
                  setUyari(true);
                  setLoading(false);
                  return;
                }
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function CancelSap() {
    //Sap iptal
    setLoading(true);
    setCancelSapPopUp(false);
    data.SAPCancelNote = cancelSapNote;
    axios
      .post('SetExchangeSAPCancel', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data == true) {
          setresponseMessage({
            ErrorCode: 1000,
            ErrorDescription: 'İşleminiz Başarılı ile gerçekleşti...'
          });
          setUyari(true);
          removeRowInTable();
          setTimeout(pageUpdate, 500);
          setTimeout(pageUpdate, 500);
          setLoading(false);
        }
        return;
      })
      .catch((err) => {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: err.response.data
        });
        setUyari(true);
        setLoading(false);
        return;
      });
  }

  function pageUpdate() {
    dispatch(generalFollowActions.updateActiveTab('1'));
  }

  function ExOffWaitingCustomerAccept() {
    //Onay Bekleniyor
    if (!invoiceData[0]?.Consumer?.UpTypeId) {
      InfoAlert('Lütfen onay tipi seçiniz.');
      return;
    }
    if (
      invoiceData[0]?.Exchange?.GiveProductStokNumber == '' ||
      invoiceData[0]?.Exchange?.GiveProductStokNumber == null ||
      invoiceData[0]?.Exchange?.GiveProductPrice < 0
    ) {
      InfoAlert(
        'Verilen stok alanı ile prekande fiyat boş.Lütfen kaydedip tekrar deneyiniz!!'
      );
      return;
    }
    if (
      invoiceData[0]?.Consumer.UpTypeId === 2 &&
      invoiceData[0]?.Exchange?.ReceivedProductPrice != null &&
      invoiceData[0]?.Exchange?.ReceivedProductPrice < 1
    ) {
      InfoAlert('Alınan Parakende Fiyat 1 TL den Küçük Olamaz');
      return;
    }

    confirmAlert({
      message: 'Müşteriden Onay Bekleniyora almak istediğinize emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('SMSSend_Message_Model_Suggess', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setSmsModelSuggest(response.data);
                setSmsSendModelSuggestPopUp(true);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function SendSmsModelSuggest() {
    setLoading(true);
    Log();
    data.Status = 27;
    axios
      .post('ExchangeOfficeWaitingCustomerAccept', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data == true) {
          axios
            .post('SMSModelSuggest', data, {
              headers: { 'Content-Type': 'application/json' }
            })
            .then((response) => {
              setresponseMessage({
                ErrorCode: 1000,
                ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
              });
              removeRowInTable();
              setTimeout(pageUpdate, 500);
              setUyari(true);
              setLoading(false);
              return;
            })
            .catch((err) => {
              setresponseMessage({
                ErrorCode: 0,
                ErrorDescription: err.response.data
              });
              setUyari(false);
              setLoading(false);
              return;
            });
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  function SapSend(ReceiptStatus) {
    var FaturaTutar = invoiceData[0]?.Consumer?.ChangeInvoiceAmount;
    var AlınanUrunTutar = invoiceData[0]?.Exchange?.ReceivedProductPrice;
    var ToplamTutar = invoiceData[0]?.Exchange?.ProductTotalPrice;
    var VerilenUrun = invoiceData[0]?.Exchange.GiveProductPrice;
    var Toplam =
      parseFloat(AlınanUrunTutar) +
      parseFloat(ToplamTutar) -
      parseFloat(subModelDifferenceAmount ? subModelDifferenceAmount : 0);

    var GiveProductStokNumber =
      invoiceData[0]?.Exchange?.GiveProductStokNumber?.toString() || '';

    if (invoiceData[0]?.Exchange?.SubStatus == 18) {
      InfoAlert(
        'Değişim Grubu - Bilgi Talebi Ara Durumunda SAP"ye Gönderemezsiniz.'
      );
      return; //MHTU-2113
    }
    if (
      invoiceData[0]?.Consumer.UpTypeId === 2 &&
      invoiceData[0]?.Exchange?.ReceivedProductPrice != null &&
      invoiceData[0]?.Exchange?.ReceivedProductPrice < 1
    ) {
      InfoAlert('Alınan Parakende Fiyat 1 TL den Küçük Olamaz');
      return;
    }
    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice != 0 &&
      invoiceData[0]?.Consumer?.UpTypeId == 2
    ) {
      InfoAlert(
        'Değişim Onay Türü = Para İadesi seçildiğinde Tutar ve Üst Model Fark Tutar alanlarına ücret bilgisi girilmemelidir!'
      );
      return;
    }
    //MHTU-4263
    if (
      invoiceData[0]?.Consumer.UpTypeId == 3 &&
      (invoiceData[0]?.Consumer?.ConsumerType === 2 ||
        invoiceData[0]?.Consumer?.ConsumerType == 3) &&
      invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity > 1 &&
      invoiceData[0]?.Exchange?.ReceivedProductPrice >=
      invoiceData[0]?.Consumer?.ChangeInvoiceAmount
    ) {
      InfoAlert(
        'Faturada birden fazla ürün var. Alınan Parakende Fiyat Toplam Tutar ile aynı olamaz.'
      );
      return;
    }
    if (
      Toplam !== parseFloat(VerilenUrun) &&
      invoiceData[0]?.Consumer?.UpTypeId !== 2
    ) {
      confirmAlert({
        message:
          'Uyarı! : Alınan ürün fiyat ve ücretli toplam tutarın toplamı verilen ürüne eşit değil !! Bu işlemi gerçekleştirmek istediğinizden emin misiniz?',
        buttons: [
          {
            label: 'Evet',
            onClick: () => {
              if (
                invoiceData[0]?.Consumer.UpTypeId === 3 &&
                (invoiceData[0]?.Consumer?.ConsumerType === 2 ||
                  invoiceData[0]?.Consumer?.ConsumerType === 3) &&
                invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity > 1 &&
                invoiceData[0]?.Consumer?.ChangeInvoiceNo?.length > 0 &&
                invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity - 1 ===
                invoiceCountAndTotalPriceByVkn?.TOPLAM_FIS_ADET &&
                parseFloat(invoiceData[0]?.Exchange?.ReceivedProductPrice) !==
                invoiceData[0]?.Consumer?.ChangeInvoiceAmount -
                parseFloat(
                  invoiceCountAndTotalPriceByVkn?.TOPLAM_ALINAN_URUN_FIYAT
                )
              ) {
                setReceiptStatusForSapWarningPopUp(ReceiptStatus);
                setSendToSapPopUp(true);
              } else {
                SendToSAP(ReceiptStatus);
              }
            }
          },
          {
            label: 'Hayır',
            onClick: () => {
              return;
            }
          }
        ]
      });
    } else {
      if (
        invoiceData[0]?.Consumer.UpTypeId === 3 &&
        (invoiceData[0]?.Consumer?.ConsumerType === 2 ||
          invoiceData[0]?.Consumer?.ConsumerType === 3) &&
        invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity > 1 &&
        invoiceData[0]?.Consumer?.ChangeInvoiceNo?.length > 0 &&
        invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity - 1 ===
        invoiceCountAndTotalPriceByVkn?.TOPLAM_FIS_ADET &&
        parseFloat(invoiceData[0]?.Exchange?.ReceivedProductPrice) !==
        invoiceData[0]?.Consumer?.ChangeInvoiceAmount -
        parseFloat(invoiceCountAndTotalPriceByVkn?.TOPLAM_ALINAN_URUN_FIYAT)
      ) {
        setReceiptStatusForSapWarningPopUp(ReceiptStatus);
        setSendToSapPopUp(true);
      } else {
        SendToSAP(ReceiptStatus);
      }
    }
  }

  function SendToSAP(ReceiptStatus) {
    // Sap gönder methodunu aynı anda 2 kere tetiklenmesini önlemek amacıyla eklenmiştir.
    if (isRunningRef.current) {
      return;
    }
    isRunningRef.current = true;

    setLoading(true);
    Log();
    data.Status = ReceiptStatus;
    axios
      .post('ExchangeOfficeSendToSap', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data.ErrorCode == 100) {
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: response.data.ErrorDescription
          });
          setLoading(false);
          isRunningRef.current = false;
          setUyari(true);
          removeRowInTable();
          setTimeout(pageUpdate, 500);
          return;
        }
        if (response.data.ErrorCode == 1000) {
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: response.data.ErrorDescription
          });
          setUyari(true);
          dispatch(generalFollowActions.updateInfoChat(''));
          const data = {
            SessionUser: SessionUser?.UserId || 0,
            UnitCode: invoiceData[0]?.Exchange?.Service,
            ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo
          };
          dispatch(getDetail(data))
            .then((response) => { })
            .catch((err) => {
              setLoading(false);
              isRunningRef.current = false;
              return;
            });
          dispatch(
            getReceiptInfoChat([
              invoiceData[0]?.Exchange?.ApplyNo,
              invoiceData[0]?.Exchange?.Service
            ])
          )
            .then((response) => { })
            .catch((err) => {
              setLoading(false);
              isRunningRef.current = false;
              return;
            });
          setLoading(false);
          isRunningRef.current = false;
          return;
        }
        setSapResponse(response.data);
        setLoading(false);
        isRunningRef.current = false;
        setSapResponsePopUp(true);
        return;
      })
      .catch((err) => {
        setLoading(false);
        isRunningRef.current = false;
        return;
      })
      .finally((err) => {
        setLoading(false);
        isRunningRef.current = false; // Sap gönder methodunu aynı anda 2 kere tetiklenmesini önlemek amacıyla eklenmiştir.
      });
  }

  function BackToSolutionCenter() {
    //MHTU-3836
    if (
      invoiceData[0]?.Exchange?.Status === 14 &&
      !invoiceData[0]?.Exchange?.GiveProductStokNumber &&
      invoiceData[0]?.Consumer?.UpTypeId !== 2 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 7
    ) {
      InfoAlert('Verilecek Ürün Alanı boş olamaz.');
      return;
    }

    confirmAlert({
      message: 'Çözüm merkezine yönlendiriyorsunuz.Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            if (!invoiceData[0]?.Exchange?.SubStatus) {
              InfoAlert(
                'Lütfen Ara Durum boş iken çözüm merkezine geri göndermeyiniz!!'
              );
              return;
            }
            setLoading(true);
            Log();
            axios
              .post('BackToSolutionCenter', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setLoading(false);
                setresponseMessage({
                  ErrorCode: 1000,
                  ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
                });
                removeRowInTable();
                setTimeout(pageUpdate, 500);
                setUyari(true);
                return;
              })
              .catch((err) => {
                setresponseMessage({
                  ErrorCode: 0,
                  ErrorDescription: err.response.data
                });
                setUyari(true);
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function sendDifferentBrandAdminControl() {
    if (differentBrandApplyMessage.length < 10) {
      InfoAlert(
        'Lütfen mesaj alanına minimum 10 karakter uzunluğunda açıklama giriniz!!'
      );
      setUyari(true);
      return;
    }

    var differentBrandMsg = 'Farklı Marka İçin Yönetici Onayı İsteniyor';
    data.Note = differentBrandMsg + ' Mesaj: ' + differentBrandApplyMessage;
    data.SubStatus = 21;
    setDifferentBrandSendManagerPopUp(false);
    setLoading(true);
    axios
      .post('DiscountAdminApproval', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data.ErrorCode == 1000) {
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: response.data.ErrorDescription
          });
        } else {
          setresponseMessage({
            ErrorCode: 1000,
            ErrorDescription: 'İşleminiz başarı ile gerçekleşmiştir.'
          });
        }

        setUyari(true);
        setLoading(false);
        removeRowInTable();
        setTimeout(pageUpdate, 500);
      })
      .catch((err) => {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: 'Bir sorun oluştu!!!'
        });
        setUyari(true);
        setLoading(false);
        return;
      });
  }

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar
          sx={{ width: '100%' }}
          open={uyari}
          autoHideDuration={6000}
          onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          {responseMessage.ErrorCode === 0 ? (
            <Alert
              icon={<MoodBadIcon />}
              style={{ minWidth: 500 }}
              sx={{
                '& .MuiAlert-icon': {
                  color: 'white'
                },
                height: 75,
                alignItems: 'center',
                verticalAlign: 'center'
              }}
              onClose={uyariKapat}
              variant="filled"
              severity="error"
            >
              {responseMessage.ErrorDescription}
            </Alert>
          ) : (
            <Alert
              icon={<TagFacesIcon />}
              style={{ minWidth: 500 }}
              sx={{
                '& .MuiAlert-icon': {
                  color: 'white'
                },
                height: 75,
                alignItems: 'center',
                verticalAlign: 'center'
              }}
              onClose={uyariKapat}
              variant="filled"
              severity="success"
            >
              {responseMessage.ErrorDescription}
            </Alert>
          )}
        </Snackbar>
      </Stack>
      {receiptStatus == 14 ? (
        <Grid container spacing={2} className={classes.ButonName}>
          <Grid item xs={3} md={3}>
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              <Tooltip title="Sap Fatura Onay">
                <IconButton
                  disabled={
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1
                      ? true
                      : false
                  }
                  onClick={() => SapSend(invoiceData[0]?.Exchange?.Status)}
                >
                  {invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                    1 ? (
                    <CheckIcon color="disabled" />
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={2} md={2} className={classes.ButonName}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Kaydet">
                <IconButton
                  style={{ color: 'blue' }}
                  onClick={() => {
                    AllSave();
                  }}
                  disabled={
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1
                      ? true
                      : false
                  }
                >
                  {invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                    1 ? (
                    <SaveIcon color="disabled" />
                  ) : (
                    <SaveIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={2} md={2} className={classes.ButonName}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Çözüm Merkezine Gönder">
                <IconButton
                  color="primary"
                  onClick={() => {
                    BackToSolutionCenter();
                  }}
                  disabled={
                    invoiceData[0]?.Exchange?.SubStatus == 4 ||
                      invoiceData[0]?.Exchange?.SubStatus == 7 ||
                      invoiceData[0]?.Exchange?.DifferentBrandManagerControl !== 1
                      ? false
                      : true
                  }
                >
                  {invoiceData[0]?.Exchange?.SubStatus == 4 ||
                    invoiceData[0]?.Exchange?.SubStatus == 7 ||
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl !==
                    1 ? (
                    <SwapHorizIcon style={{ color: 'purple' }} />
                  ) : (
                    <SwapHorizIcon color="disabled" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={2} md={2} className={classes.ButonName}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Servise Geri Gönder">
                <IconButton
                  color="primary"
                  onClick={() => {
                    rejectToService();
                  }}
                >
                  <ApartmentIcon style={{ color: 'orange' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={3} md={3} className={classes.ButonName}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Müşteriden Onay Bekleniyor">
                <IconButton
                  onClick={() => ExOffWaitingCustomerAccept()}
                  disabled={
                    invoiceData[0]?.Exchange?.Status == 27 ||
                      invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1
                      ? true
                      : false
                  }
                >
                  {invoiceData[0]?.Exchange?.Status == 27 ||
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                    1 ? (
                    <SupervisedUserCircleIcon color="disabled" />
                  ) : (
                    <SupervisedUserCircleIcon style={{ color: 'green' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          {invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1 &&
            invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 ? (
            <>
              <Grid item xs={4} md={5.5}></Grid>
              <Grid item xs={4} md={1}>
                <Box sx={{ m: 1 }}>
                  <Tooltip title="Farklı Marka İçin Yönetici Onayına Gönder">
                    <IconButton
                      onClick={() => {
                        setDifferentBrandSendManagerPopUp(true);
                      }}
                    >
                      <AddTaskIcon style={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={4} md={5.5}></Grid>
            </>
          ) : null}
          <Grid item xs={3} />
          <Grid item xs={6}>
            <Typography
              sx={{
                width: '100%',
                color: 'text.secondary',
                textAlign: 'center'
              }}
              variant="subtitle1"
            >
              {'Değişim Ofisi'}
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      ) : receiptStatus == 15 && invoiceData[0]?.Consumer.ChangeSasNo > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={5} md={5}></Grid>
          <Grid item xs={2} md={2}>
            <Box sx={{ m: 1 }} className={classes.ButonName}>
              <Tooltip title="Sap İptal">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setCancelSapPopUp(true);
                  }}
                >
                  <CancelIcon style={{ color: 'red' }} fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={5} md={5}>
            {' '}
          </Grid>
        </Grid>
      ) : receiptStatus == 29 ? (
        <Grid container spacing={2}>
          <Grid item xs={5} md={5}>
            {' '}
          </Grid>
          <Grid item xs={2} md={2} className={classes.ButonName}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Değişim Grubuna Geri Çek">
                <IconButton
                  color="primary"
                  onClick={() => {
                    BackToExOfficeForWaitingServiceOldProduct();
                  }}
                >
                  <SwapHorizontalCircleIcon
                    style={{ color: 'blue' }}
                    fontSize="large"
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={5} md={5}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                width: '100%',
                color: 'text.secondary',
                textAlign: 'center'
              }}
              variant="subtitle1"
            >
              {'Değişim Ofisi'}
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      ) : null}

      {/* Sms gönderme model önerme için popup */}
      <Dialog
        open={smsSendModelSuggestPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {invoiceData[0]?.Model?.UserModelCode1 ===
            invoiceData[0]?.Exchange?.GiveProductStokNumber ? (
            <>
              <Typography
                sx={{ width: '100%', color: 'red' }}
                variant="subtitle1"
              >
                {
                  'Dikkat! Müşteriye aynı model ürün için sms atıyorsunuz, emin misiniz?'
                }
              </Typography>
              <Divider style={{ marginBottom: '1rem' }} />
            </>
          ) : null}
          <Typography
            sx={{ width: '100%', color: 'text.secondary' }}
            variant="subtitle1"
          >
            {smsModelSuggest}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              SendSmsModelSuggest();
            }}
          >
            SMS Gönder
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setSmsSendModelSuggestPopUp(false);
            }}
          >
            SMS Gönderme
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={sapResponsePopUp}
        // onClose={() => setSapResponsePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {invoiceData[0]?.Consumer?.ConsumerType == 1 ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  {' Fiş Numarası: ' +
                    invoiceData[0]?.Apply?.ReceiptNumber +
                    sapResponse.GpsNr
                    ? ' GpsNr: ' + sapResponse.GpsNr
                    : ''}
                </Typography>
              ) : (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  sx={{
                    fontWeight: 'bold',
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  {' Fiş Numarası: ' +
                    invoiceData[0]?.Apply?.ReceiptNumber +
                    sapResponse.ReturnValue
                    ? '  Değer: ' + sapResponse.ReturnValue
                    : ''}
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              color="primary"
              onClick={() => {
                setSapResponsePopUp(false);
                removeRowInTable();
                setTimeout(pageUpdate, 500);
              }}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>

      {/* Sap İptal */}
      <Dialog
        open={cancelSapPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogContent>
          <Grid item xs={12} md={12}>
            <TextField
              id="input-with-icon-textfield"
              label="Mesaj"
              size="small"
              value={cancelSapNote}
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              onChange={(event) => {
                setCancelSapNote(event.target.value);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => {
              CancelSap();
            }}
          >
            Gönder
          </Button>
          <IconButton
            sx={{ color: '#FF9800' }}
            onClick={() => {
              setCancelSapPopUp(false);
              setCancelSapNote('');
            }}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>

      {/* Uyarı Taskları */}
      <Dialog
        open={warningMessagePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
      >
        <DialogContent>
          <Typography
            sx={{ width: '100%', color: 'text.secondary' }}
            variant="subtitle1"
          >
            {warningMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => {
              SapSend(invoiceData[0]?.Exchange?.Status);
            }}
          >
            Tamam
          </Button>
          <Button
            size="small"
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => {
              setWarningMessagePopUp(false);
            }}
          >
            İptal
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={differentBrandSendManagerPopUp}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                id="outlined-basic"
                size="medium"
                multiline
                maxRows={5}
                fullWidth
                variant="outlined"
                label="Lütfen Bir Açıklama Giriniz."
                value={differentBrandApplyMessage}
                onChange={(event) => {
                  setDifferentBrandApplyMessage(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                sendDifferentBrandAdminControl();
              }}
            >
              Yönetici Onayına Gönder
            </Button>
            <Button
              variant="contained"
              size="medium"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                setDifferentBrandSendManagerPopUp(false);
              }}
            >
              Vazgeç
            </Button>
          </DialogActions>
        </DialogActions>
      </Dialog>

      {/* MHTU-4263 Kapsamında oluşturulmuştur. */}
      <Dialog
        open={sendToSapPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Typography
            sx={{ width: '100%', color: 'text.secondary' }}
            variant="body1"
          >
            Dikkat!! Alınan Parakende Fiyatı için girebileceğiniz{' '}
            <b>
              Tutar ={' '}
              {invoiceData[0]?.Consumer?.ChangeInvoiceAmount -
                parseFloat(
                  invoiceCountAndTotalPriceByVkn?.TOPLAM_ALINAN_URUN_FIYAT || 0
                )}{' '}
              TL
            </b>
            'dir. Lütfen Alınan Ürün Parakende Fiyatını kontrol ediniz. SAP'ye
            göndermek istiyor musunuz?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              SendToSAP(receiptStatusForSapWarningPopUp);
              setSendToSapPopUp(false);
            }}
          >
            SAP Gönder
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setSendToSapPopUp(false);
            }}
          >
            Kapat
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ExchangeOfficeProcess;
