import CloseIcon from '@mui/icons-material/Close';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import GroupIcon from '@mui/icons-material/Group';
import {
  Backdrop,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  IconButton,
  Link,
  Typography,
  TextField,
  Stack,
  Alert,
  Snackbar,
  Button,
  Autocomplete
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import PaidChange from 'src/components/PaidChange';
import {
  getCustomerHistory,
  getCustomersFromPhoneNumber,
  getRetailer,
  getMatchingConsumerInformation,
  getWebPointInvoiceDetail
} from 'src/store/slices/consumerInformationSlice';
import ChangeProductInfo from './ChangeProductInfo';
import FileInfo from './FileInfo';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import DescriptionIcon from '@mui/icons-material/Description';
import Refund from 'src/components/Refund';
import AlertAndLoading from 'src/components/AlertAndLoading';
import { getUserRole } from 'src/utils/Common';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'src/utils/axios';
import { confirmAlert } from 'react-confirm-alert';
import { getReceiptInfoChat } from 'src/store/slices/informationChatSlice';
import { getDetail } from 'src/store/slices/generalFollowSlice';
import { getUser } from 'src/utils/Common';
import React, { useEffect } from 'react';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  },
  div: {
    color: 'red',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%'
  },
  div2: {
    fontSize: '12px',
    width: '100%',
    color: 'black'
  },
  icerikBilgi: {
    margin: '0px',
    fontWeight: '600',
    fontSize: '12px',
    lineheight: '1.43',
    letterspacing: '0.01071em',
    color: 'black',
    marginBottom: '7.5px'
  },
  tableCell: {
    fontWeight: '600',
    fontSize: '14px',
    color: 'black',
    whiteSpace: 'nowrap'
  },
  tableCellBilgi: {
    fontSize: '14px',
    color: 'black',
    whiteSpace: 'nowrap'
  }
}));

function ConsumerInformation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const Role = getUserRole().RoleId;
  const SessionUser = getUser();

  const [customerInfoPopUp, setCustomerInfoPopUp] = React.useState(false);
  const [webPointInvoicePopUp, setWebPointInvoicePopUp] = React.useState(false);
  const [retailerInfoPopUp, setRetailerInfoPopUp] = React.useState(false);
  const [consumerInfoPopUp, setConsumerInfoPopUp] = React.useState(false);
  const [currentConsumerInfoPopUp, setCurrentConsumerInfoPopUp] =
    React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [retailerData, setRetailerData] = React.useState([]);
  const [consumerPhoneData, setConsumerPhoneData] = React.useState([]);
  const [currentConsumerData, setCurrentConsumerData] = React.useState([]);
  const [webPointInvoiceData, SetWebPointInvoiceData] = React.useState([]);

  const [
    matchingConsumerInformationPopUp,
    setMatchingConsumerInformationPopUp
  ] = React.useState(false);
  const [matchingConsumerInformation, setMatchingConsumerInformation] =
    React.useState([]);
  const [responseMessage, setresponseMessage] = React.useState('');
  const [uyari, setUyari] = React.useState(false);
  const [alertType, setAlertType] = React.useState({
    ErrorType: 'error',
    ErrorTitle: 'Uyarı'
  });
  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );
  const [text, setText] = React.useState(null);
  const [Info, setInfo] = React.useState('');
  const [TaxAdministrationData, setTaxAdministrationData] = React.useState('');
  const [IdentifyNumber, setIdentifyNumber] = React.useState(null);
  const [taxNumberUpdatePopUp, setTaxNumberUpdatePopUp] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const TaxNumOrIdentityNum = `${invoiceData[0]?.Consumer?.SSNGov}/${
    invoiceData[0]?.Consumer?.TaxNumber ||
    invoiceData[0]?.Consumer?.IdentityNumber
  }`;
  const handleEditClick = () => {
    setTaxNumberUpdatePopUp(true);
    setIdentifyNumber(invoiceData[0]?.Consumer?.IdentityNumber);
    setText(invoiceData[0]?.Consumer?.TaxNumber);
    setInfo('');
    axios.get('/GetTaxAdministration', {}).then((response) => {
      setTaxAdministrationData(response.data);
    });
  };
  const handleSaveClick = () => {
    if (Info.length < 15) {
      InfoAlert('Açıklamanız 15 karakterden uzun olmalı ...', 'error', 'Uyarı');
      return;
    }
    if (IdentifyNumber.length != 0) {
      if (IdentifyNumber.length != 11) {
        InfoAlert(
          'TC kimlik numarası 11 karakter olmalı ...',
          'error',
          'Uyarı'
        );
        return;
      }
    }
    if (text.length != 0) {
      if (text.length != 10) {
        InfoAlert(
          'Vergi dairesi numarası 10 karakter olmalı ...',
          'error',
          'Uyarı'
        );
        return;
      }
    }
    if (text.length == 10 && selectedOption?.ID == 374) {
      InfoAlert(
        'Vergi dairesi numarası girdiğiniz için vergi dairesi TCKIMLIKNO olamaz.Lütfen Vergi dairesini değiştiriniz...',
        'error',
        'Uyarı'
      );
      return;
    }
    if (
      IdentifyNumber.length == 11 &&
      text.length == 0 &&
      selectedOption?.ID != 374
    ) {
      InfoAlert(
        ' TC kimlik numarası girdiğiniz için vergi dairesi TCKIMLIKNO olmalı.Lütfen Vergi dairesini değiştiriniz...',
        'error',
        'Uyarı'
      );
      return;
    }
    if (
      selectedOption?.ID == null ||
      selectedOption?.ID == '' ||
      selectedOption?.ID == undefined
    ) {
      InfoAlert('Lütfen Vergi Dairesi Seçiniz ...', 'error', 'Uyarı');
      return;
    }
    setTaxNumberUpdatePopUp(false);
    const data = {
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo,
      ServiceNumber: invoiceData[0]?.Exchange?.Service,
      CustomerNumber: invoiceData[0]?.Consumer?.Id,
      UserId: invoiceData[0]?.UserID,
      NewTaxNumber: text,
      Type: 1,
      NewCustomerType: 0,
      Desc: Info,
      TaxAdministrationId: selectedOption?.ID,
      NewIdentityNumber: IdentifyNumber
    };
    const data2 = {
      SessionUser: SessionUser?.UserId || 0,
      UnitCode: invoiceData[0]?.Exchange?.Service,
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo
    };
    confirmAlert({
      message: 'Bu işlemi gerçekleştirmek istediğinizden emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('DiaUpdateCustomerTypeAndTaxNumber', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                if (response.data.ErrorCode === '0') {
                  InfoAlert(
                    'İşleminiz başarılıyla gerçekleşmiştir...',
                    'success',
                    'Başarılı'
                  );
                  setLoading(false);
                  dispatch(getDetail(data2))
                    .then((response) => {})
                    .catch((err) => {
                      setLoading(false);
                      return;
                    });
                  dispatch(
                    getReceiptInfoChat([
                      invoiceData[0]?.Exchange?.ApplyNo,
                      invoiceData[0]?.Exchange?.Service
                    ])
                  )
                    .then((response) => {})
                    .catch((err) => {
                      setLoading(false);
                      return;
                    });
                  setInfo('');
                  setIdentifyNumber(null);
                  setText(null);
                  setSelectedOption(null);
                  return;
                } else {
                  InfoAlert(response.data.ErrorDescription, 'error', 'Hata');
                  setLoading(false);
                  return;
                }
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => {}
        }
      ]
    });
  };

  const handleInfoChange = (event) => {
    setInfo(event.target.value);
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const handleIdentifyNumberChange = (event) => {
    setIdentifyNumber(event.target.value);
  };

  function InfoAlert(ErrorDescription, ErrorType, ErrorTitle) {
    setresponseMessage(ErrorDescription);
    setAlertType({
      ErrorType: ErrorType,
      ErrorTitle: ErrorTitle
    });
    setUyari(true);
    return;
  }

  function getRetailerList(RetailerCode) {
    setRetailerInfoPopUp(true);
    setLoading(true);
    dispatch(getRetailer(RetailerCode)).then((response) => {
      if (response.payload?.length > 0) {
        setRetailerData(response.payload);
        setLoading(false);
      }
    });
  }

  function getConsumerPhoneList(Phone1) {
    setConsumerInfoPopUp(true);
    setLoading(true);
    dispatch(getCustomersFromPhoneNumber(Phone1)).then((response) => {
      if (response.payload?.length > 0) {
        setConsumerPhoneData(response.payload);
        setLoading(false);
      }
    });
  }

  function getCustomerHistoryList(CustomerNumber) {
    setCurrentConsumerInfoPopUp(true);
    setLoading(true);
    dispatch(getCustomerHistory(CustomerNumber)).then((response) => {
      setCurrentConsumerData([]);
      setLoading(false);
      if (response.payload.length > 0) {
        setCurrentConsumerData(response?.payload);
      }
    });
  }

  function getMatchingConsumerInformationClick(FisNo) {
    setLoading(true);
    dispatch(getMatchingConsumerInformation(FisNo)).then((response) => {
      if (response.payload?.length > 0) {
        setMatchingConsumerInformationPopUp(true);
        setMatchingConsumerInformation(response.payload[0]);
        setLoading(false);
      } else {
        setMatchingConsumerInformationPopUp(false);
        setLoading(false);
        InfoAlert('Fiş Bilgileri Bulunamadı!...', 'error', 'Hata');
        return;
      }
    });
  }

  function getWebPointInvoiceDetailClick(FisNo) {
    setLoading(true);
    dispatch(getWebPointInvoiceDetail(FisNo))
      .then((response) => {
        SetWebPointInvoiceData([]);
        if (response.payload.length > 0) {
          SetWebPointInvoiceData(response?.payload);
        }
        setWebPointInvoicePopUp(true);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  }

  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  return (
    <>
      <AlertAndLoading
        Message={responseMessage}
        open={uyari}
        AlertType={alertType}
        onClose={uyariKapat}
        loading={loading}
      />
      <Card sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <Typography className={classes.div} variant="subtitle1">
                Tüketici Bilgileri
              </Typography>
            </Grid>
            <Grid item xs={3} md={3}></Grid>
            <Grid item xs={1} md={1}>
              <Tooltip title="Fatura Bilgileri">
                <IconButton
                  className={classes.action}
                  key="close"
                  onClick={() => {
                    getWebPointInvoiceDetailClick(
                      invoiceData[0]?.Apply?.ReceiptNumber
                    );
                  }}
                >
                  <DescriptionIcon sx={{ color: '#424242' }} />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item xs={2} md={2}>
              {Role?.filter((i) => i.ROLE_ID !== 11).length > 0 ? (
                <Tooltip title="Güncel Müşteri Bilgileri">
                  <IconButton
                    className={classes.action}
                    key="close"
                    onClick={() => {
                      setCustomerInfoPopUp(true);
                    }}
                  >
                    <GroupIcon sx={{ color: '#424242' }} />
                  </IconButton>
                </Tooltip>
              ) : null}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {invoiceData[0]?.Consumer?.ConsumerType == 1 ||
            invoiceData[0]?.Consumer?.ConsumerType == 2 ? (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Ad Soyad:&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Consumer?.Name}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Ad Soyad:&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Retailer?.Name}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            {invoiceData[0]?.Consumer?.ConsumerType == 1 ||
            invoiceData[0]?.Consumer?.ConsumerType == 2 ? (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Telefon :&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Consumer?.Phone1}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Telefon :&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Retailer?.Phone1}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>

          {invoiceData[0]?.Consumer?.ConsumerType == 1 ||
          invoiceData[0]?.Consumer?.ConsumerType == 2 ? (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={10}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Vergi D./No: &nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {TaxNumOrIdentityNum}
                  </Typography>
                </Typography>
              </Grid>
              <Grid item xs={12} md={2} style={{ textAlign: 'right' }}>
                <Tooltip title="Vergi Numarası Düzenleme">
                  <IconButton onClick={handleEditClick}>
                    <EditIcon sx={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ) : (
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                className={classes.icerikBilgi}
              >
                Vergi D./No :&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  className={classes.div2}
                >
                  {invoiceData[0]?.Retailer?.TaxAdministration +
                    '/' +
                    invoiceData[0]?.Retailer?.TaxNumber}
                </Typography>
              </Typography>
            </Grid>
          )}
          {invoiceData[0]?.Exchange?.ReasonForChangeConsumerInformation ? (
            <Grid container spacing={2} style={{ paddingTop: 10 }}>
              <Grid item xs={12} md={12}>
                <TextField
                  className={classes.div2}
                  id="input-with-icon-textfield"
                  label="Tüketici Bilgileri Değişiklik Sebebi"
                  size="small"
                  value={
                    invoiceData[0]?.Exchange?.ReasonForChangeConsumerInformation
                      ? invoiceData[0]?.Exchange
                          ?.ReasonForChangeConsumerInformation
                      : '-'
                  }
                  fullWidth
                  variant="outlined"
                  multiline
                  rows={2}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        position="end"
                        onClick={() =>
                          getMatchingConsumerInformationClick(
                            invoiceData[0]?.Apply?.ReceiptNumber
                          )
                        }
                      >
                        <PriorityHighIcon sx={{ color: 'red' }} />
                      </IconButton>
                    )
                  }}
                />
              </Grid>
            </Grid>
          ) : null}
        </CardContent>
      </Card>
      <Grid item xs={4} md={4} style={{ paddingTop: 10 }}></Grid>
      <ChangeProductInfo />
      <Grid item xs={4} md={4} style={{ paddingTop: 10 }}></Grid>
      <FileInfo />
      {Role?.filter((i) => i.ROLE_ID !== 11).length > 0 ? (
        <>
          <Grid item xs={12} style={{ paddingTop: 5 }}>
            <Refund />
          </Grid>
          <Grid item xs={12} style={{ paddingTop: 10 }}>
            <PaidChange />
          </Grid>
        </>
      ) : null}

      {/* Garanti Başlatan Hizmet Fişi Müşteri Bilgileri PopUp*/}
      <Dialog
        open={matchingConsumerInformationPopUp}
        onClose={() => setMatchingConsumerInformationPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="subtitle1"
                color="textSecondary"
                component="div"
                sx={{
                  width: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                  m: 1,
                  textAlign: 'center',
                  color: 'red'
                }}
              >
                Garanti Başlatan Hizmet Fişi Müşteri Bilgileri
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                sx={{
                  width: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {'Garanti başlatan Hizmet Fiş no:'}{' '}
                {matchingConsumerInformation?.FIS_NO}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                sx={{
                  width: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {'Müşteri İsim Soyisim:'} {matchingConsumerInformation?.ADI}{' '}
                {matchingConsumerInformation?.SOYADI}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                sx={{
                  width: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {'Müşteri Telefonu: '}
                {matchingConsumerInformation?.TEL1
                  ? matchingConsumerInformation?.TEL1
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                sx={{
                  width: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {'Müşteri Telefonu 2: '}
                {matchingConsumerInformation?.TEL2
                  ? matchingConsumerInformation?.TEL2
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                sx={{
                  width: '100%',
                  width: '100%',
                  fontWeight: 'bold',
                  textAlign: 'center'
                }}
              >
                {'Faks Numarası: '}
                {matchingConsumerInformation?.FAKS
                  ? matchingConsumerInformation?.FAKS
                  : '-'}
              </Typography>
            </Grid>
            <Grid item xs={1} md={1}></Grid>
          </Grid>
          <DialogActions>
            <IconButton
              sx={{ color: '#424242' }}
              onClick={() => setMatchingConsumerInformationPopUp(false)}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        open={customerInfoPopUp}
        onClose={() => setCustomerInfoPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <Grid container spacing={2}>
          <Grid item xs={10} md={10}>
            <Typography
              variant="subtitle1"
              color="textSecondary"
              component="div"
            >
              <Box
                sx={{ width: '100%', fontWeight: 'bold', m: 2, color: 'red' }}
              >
                {' '}
                Tüketici Bilgileri Detay
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={10} md={2}>
            <Tooltip title="Güncel Müşteri Kartı">
              <IconButton
                className={classes.action}
                color="inherit"
                key="close"
                sx={{ color: '#FF9800' }}
                onClick={() =>
                  getCustomerHistoryList(invoiceData[0]?.Consumer?.Id)
                }
              >
                <ContactMailIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <DialogContent>
          <Grid container spacing={2}>
            {invoiceData[0]?.Consumer?.ConsumerType == 1 ||
            invoiceData[0]?.Consumer?.ConsumerType == 2 ? (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Ad Soyad :&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Consumer?.Name}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Ad Soyad :&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Retailer?.Name}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            {invoiceData[0]?.Consumer?.ConsumerType == 1 ||
            invoiceData[0]?.Consumer?.ConsumerType == 2 ? (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Telefon:&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    <Link
                      sx={{ color: '#424242' }}
                      onClick={() =>
                        getConsumerPhoneList(invoiceData[0]?.Consumer?.Phone1)
                      }
                    >
                      {invoiceData[0]?.Consumer?.Phone2
                        ? invoiceData[0]?.Consumer?.Phone1 +
                          '/' +
                          invoiceData[0]?.Consumer?.Phone2
                        : invoiceData[0]?.Consumer?.Phone1}
                    </Link>
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Telefon:&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    <Link
                      sx={{ color: '#424242' }}
                      onClick={() =>
                        getConsumerPhoneList(invoiceData[0]?.Retailer?.Phone1)
                      }
                    >
                      {invoiceData[0]?.Retailer?.Phone2
                        ? invoiceData[0]?.Retailer?.Phone1 +
                          '/' +
                          invoiceData[0]?.Retailer?.Phone2
                        : invoiceData[0]?.Retailer?.Phone1}
                    </Link>
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            {invoiceData[0]?.Consumer?.ConsumerType == 1 ||
            invoiceData[0]?.Consumer?.ConsumerType == 2 ? (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Vergi D./No:&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Consumer?.SSNGov +
                      '/' +
                      invoiceData[0]?.Consumer?.SSN}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Vergi D./No:&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Retailer?.TaxAdministration +
                      '/' +
                      invoiceData[0]?.Retailer?.TaxNumber}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                className={classes.icerikBilgi}
              >
                Bayi Adı/Kodu:&nbsp;&nbsp;&nbsp;&nbsp;
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="span"
                  className={classes.div2}
                >
                  <Link
                    sx={{ color: '#424242' }}
                    onClick={() => {
                      getRetailerList(invoiceData[0]?.Retailer?.Code);
                    }}
                  >
                    {invoiceData[0]?.Retailer?.Code +
                      '/' +
                      invoiceData[0]?.Retailer?.Dsc}
                  </Link>
                </Typography>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {invoiceData[0]?.Consumer?.ConsumerType == 1 ||
            invoiceData[0]?.Consumer?.ConsumerType == 2 ? (
              <Grid item xs={12} md={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Adres:&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Consumer?.Address}
                  </Typography>
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={9} md={9}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  className={classes.icerikBilgi}
                >
                  Adres:&nbsp;&nbsp;&nbsp;&nbsp;
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                    className={classes.div2}
                  >
                    {invoiceData[0]?.Retailer?.Addres}
                  </Typography>
                </Typography>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              sx={{ color: '#424242' }}
              onClick={() => setCustomerInfoPopUp(false)}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>
      {/*Bayi Bilgileri Detay popup başlangıcı */}

      <Dialog
        open={retailerInfoPopUp}
        onClose={() => setRetailerInfoPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontWeight: 'bold',
            fontSize: '14px'
          }}
        >
          {'Bayi Bilgileri Detay'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left">
                        Bayi Kodu
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Bayi Adı
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Marka
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        İlgili Kişi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Şehir
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Telefon1
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Telefon2
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {retailerData.map((row) => (
                      <TableRow
                        key={row.KUL_BAYI_KODU}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.KUL_BAYI_KODU}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.ACIKLAMA}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.MARKA ? row.MARKA : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.SATISILGILIKISI ? row.SATISILGILIKISI : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.SEHIR ? row.SEHIR : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.TEL1 ? row.TEL1 : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.TEL2 ? row.TEL2 : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            sx={{ color: '#424242' }}
            onClick={() => setRetailerInfoPopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      {/*Telefon Bilgileri Detay popup başlangıcı */}
      <Dialog
        open={consumerInfoPopUp}
        onClose={() => setConsumerInfoPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          {'Telefon Bilgileri Detay'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left">
                        Müşteri No
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Adı
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Soyadı
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Birim Kodu
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Telefon
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Semt
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Şehir
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Adres
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Kayıt Tarihi
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {consumerPhoneData.map((row) => (
                      <TableRow
                        key={row.MUSTERI_NO}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.MUSTERI_NO ? row.MUSTERI_NO : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.ADI ? row.ADI : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.SOYADI ? row.SOYADI : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.BIRIM_KODU ? row.BIRIM_KODU : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.TEL1 ? row.TEL1 : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.SEMT ? row.SEMT : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.SEHIR ? row.SEHIR : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.ADRES ? row.ADRES : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.KAYIT_TARIHI ? row.KAYIT_TARIHI : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              sx={{ color: '#424242' }}
              onClick={() => setConsumerInfoPopUp(false)}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>
      {/*Güncel Müşteri Kartı  */}
      <Dialog
        open={currentConsumerInfoPopUp}
        onClose={() => setCurrentConsumerInfoPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          {'Güncel Müşteri Bilgileri'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="left">
                        Müşteri Durum
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Adı
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Soyadı
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        Adres
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Tel 1
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Tel 2
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        İl
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        İlçe
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Mahalle
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Vergi Dairesi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Vergi Numarası
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Tarih
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentConsumerData?.map((row) => (
                      <TableRow
                        key={row.CustomerId}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.CustomerType ? row.CustomerType : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.Name ? row.Name : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.Surname ? row.Surname : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="left"
                        >
                          {row.Adress ? row.Adress : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.Tel1 ? row.Tel1 : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.Tel2 ? row.Tel2 : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.City ? row.City : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.Town ? row.Town : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.District ? row.District : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.TaxOffice ? row.TaxOffice : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.Tckn ? row.Tckn : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.Date ? row.Date : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              sx={{ color: '#424242' }}
              onClick={() => setCurrentConsumerInfoPopUp(false)}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>
      <Dialog
        open={taxNumberUpdatePopUp}
        onClose={() => {
          setTaxNumberUpdatePopUp(false);
          setInfo('');
          setIdentifyNumber(null);
          setText(null);
          setSelectedOption(null);
        }}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontSize: '16px',
            fontWeight: 'bold',
            textAlign: 'center'
          }}
        >
          Müşteri Tipi Değiştirme
        </DialogTitle>
        <DialogContent>
          <br></br>
          <Grid item xs={12} md={12}>
            <TextField
              label="Tc Kimlik No"
              fullWidth
              size="small"
              value={IdentifyNumber}
              onChange={handleIdentifyNumberChange}
            />
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <TextField
              label="Vergi Numarası"
              fullWidth
              size="small"
              value={text}
              onChange={handleTextChange}
            />
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <Autocomplete
              options={TaxAdministrationData}
              getOptionLabel={(option) => option.ACIKLAMA}
              value={selectedOption}
              onChange={(event, newValue) => setSelectedOption(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  size="small"
                  placeholder="Vergi Dairesi"
                />
              )}
            />
          </Grid>

          <br></br>
          <Grid item xs={12} md={12}>
            <TextField
              label="Açıklama"
              fullWidth
              maxRows={5}
              size="medium"
              multiline
              value={Info}
              onChange={handleInfoChange}
            />
          </Grid>
          <br></br>
          <Grid item xs={12} md={12}>
            <Button
              size="small"
              fullWidth
              onClick={() => {
                handleSaveClick();
              }}
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            >
              {'Kaydet'}
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <IconButton
            color="primary"
            onClick={() => {
              setTaxNumberUpdatePopUp(false);
            }}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={webPointInvoicePopUp}
        onClose={() => setWebPointInvoicePopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle
          sx={{
            width: '100%',
            color: 'red',
            fontSize: '14px',
            fontWeight: 'bold'
          }}
        >
          {'Fatura Bilgileri'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TableContainer sx={{ maxHeight: 440 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.tableCell} align="center">
                        Müşteri Adı Soyadı
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        SAP Ürün Kodu
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Ürün Tanım
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Fatura Tarihi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Fatura Numarası
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Satış Adeti
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Satış Tutarı
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Bayi Kodu
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Bayi Ünvanı
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Vergi Dairesi
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        TC Kimlik Numarası
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        E Fatura Flag
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        Vergi Numarası
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {webPointInvoiceData?.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 }
                        }}
                      >
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.ADSOYAD ? row.ADSOYAD : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.SAP_URUN_KODU ? row.SAP_URUN_KODU : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.URUN_KISA_TANIM ? row.URUN_KISA_TANIM : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.FATURA_TARIHI
                            ? moment(row.FATURA_TARIHI).format('DD/MM/YYYY')
                            : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.FATURA_NO ? row.FATURA_NO : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.SATIS_ADET ? row.SATIS_ADET : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.SATIR_TUTARI ? row.SATIR_TUTARI : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.BAYI_KODU ? row.BAYI_KODU : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.BAYI_UNVAN ? row.BAYI_UNVAN : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.VERGI_DAIRESI ? row.VERGI_DAIRESI : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.TC_KIMLIK_NO ? row.TC_KIMLIK_NO : '-'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.E_FATURA_FLAG === true ? '1' : '0'}
                        </TableCell>
                        <TableCell
                          className={classes.tableCellBilgi}
                          align="center"
                        >
                          {row.VERGI_NO ? row.VERGI_NO : '-'}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              sx={{ color: '#424242' }}
              onClick={() => setWebPointInvoicePopUp(false)}
              aria-label="close"
              component="span"
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConsumerInformation;
