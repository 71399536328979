import ApartmentIcon from '@mui/icons-material/Apartment';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import PaymentsIcon from '@mui/icons-material/Payments';
import SaveIcon from '@mui/icons-material/Save';
import TagFacesIcon from '@mui/icons-material/TagFaces';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import TimerIcon from '@mui/icons-material/Timer';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  InputAdornment,
  Divider
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRefundConvince,
  getRefundConvinceType,
  getServiceRefund
} from 'src/store/slices/commonSlice';
import {
  generalFollowActions,
  getDetail,
  getWarranty
} from 'src/store/slices/generalFollowSlice';
import axios from 'src/utils/axios';
import { getUser } from 'src/utils/Common';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { getReceiptInfoChat } from 'src/store/slices/informationChatSlice';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { getUserRole } from 'src/utils/Common';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import {
  getConfirmType,
  getConfirmTypeWithUp
} from 'src/store/slices/commonSlice';
import Autocomplete from '@mui/material/Autocomplete';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import PeopleIcon from '@mui/icons-material/People';
import AddTaskIcon from '@mui/icons-material/AddTask';
import { ApprovalRounded, Stream } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: 1500,
    color: '#fff'
  }
}));

function SolutionCenterProcess() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const SessionUser = getUser();
  const userRole = getUserRole().Component;
  const Role = getUserRole().RoleId;

  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );

  const updatedInfoChat = useSelector(
    (state) => state.generalFollowSlice.updatedInfoChat
  );
  const receiptStatus = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  )[0]?.Exchange?.Status;
  const UpdatedInformation = useSelector(
    (state) => state.generalFollowSlice.updatedProductInformation
  );
  const PaidChangeDiscountRate = useSelector(
    (state) => state.generalFollowSlice.paidChangeDiscountRate
  );
  const TopModelPriceDiscountRate = useSelector(
    (state) => state.generalFollowSlice.topModelPriceDiscountRate
  );
  const DiscountButtonMessage = useSelector(
    (state) => state.generalFollowSlice.discountButtonMessage
  );
  const exData = useSelector((state) => state.generalFollowSlice.exDatas);
  const confirmTypeData = useSelector(
    (state) => state.commonSlice.confirmTypes
  );
  const upConfirmTypeData = useSelector(
    (state) => state.commonSlice.upConfirmTypes
  );
  const offerProductPrice = useSelector(
    (state) => state.generalFollowSlice.updatedOfferPrice
  );
  const topModelDifferentPrice = useSelector(
    (state) => state.generalFollowSlice.updatedTopModelDifferentPrice
  );

  const isIbanNoValid = useSelector(
    (state) => state.generalFollowSlice.updatedIsIbanNoValid
  );

  const [confirmTypes, setConfirmTypes] = React.useState(
    confirmTypeData.filter((i) => i.Id == invoiceData[0]?.Consumer?.UpTypeId)[0]
  );
  const [upConfirmTypes, setUpConfirmTypes] = React.useState(null);
  const [uyari, setUyari] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [serviceRefundPopUp, setServiceRefundPopUp] = React.useState(false);
  const [convinceTypePopUp, setConvinceTypePopUp] = React.useState(false);
  const [serviceRefundList, setServiceRefundList] = React.useState([]);
  const [convinceRefundList, setConvinceRefundList] = React.useState([]);
  const [convinceRefundTypeList, setConvinceRefundTypeList] = React.useState(
    []
  );
  const [selectedServiceRefund, setSelectedServiceRefund] = React.useState([]);
  const [selectedConvinceRefund, setSelectedConvinceRefund] = React.useState(
    []
  );
  const [convinceChat, setConvinceChat] = React.useState('');
  const [selectedConvinceSurvey, setSelectedConvinceSurvey] = React.useState(
    []
  );
  const [isUpdate, setIsUpdate] = React.useState(false);
  const [responseMessage, setresponseMessage] = React.useState({
    ErrorCode: '0',
    ErrorDescription: 'success_message'
  });
  const [convinceProcessPopUp, setConvinceProcessPopUp] = React.useState(false);
  const [smsContent, setSmsContent] = React.useState('');
  const [smsModelSuggest, setSmsModelSuggest] = React.useState(false);
  const [smsSendPopUp, setSmsSendPopUp] = React.useState(false);
  const [smsSendModelSuggestPopUp, setSmsSendModelSuggestPopUp] =
    React.useState(false);
  const [rejectMessageDsc, setRejectMessageDsc] = React.useState('');
  const [discountMessageDsc, setDiscountMessageDsc] = React.useState('');
  const [discountMessagePopUp, setDiscountMessagePopUp] = React.useState(false);
  const [rejectMessagePopUp, setRejectMessagePopUp] = React.useState(false);
  const [webPointCancelType, setWebPointCancelType] = React.useState(
    invoiceData[0].Exchange.WebPointCancelType
  );
  const [iptalTanıUyarıPopUp, setIptalTanıUyarıPopUp] = React.useState(false);
  const [differentBrandSendManagerPopUp, setDifferentBrandSendManagerPopUp] =
    React.useState(false);
  const [TaniOpenResponsePopUp, setTaniOpenResponsePopUp] =
    React.useState(false);
  const [taniResponse, setTaniOpenResponse] = React.useState(null);
  const [differentBrandApplyMessage, setDifferentBrandApplyMessage] =
    React.useState('');

  const [isIbanValidatorActivated, setIsIbanValidatorActivated] =
    React.useState(false);

  // const [giveStokNumber, setGiveStokNumber] = React.useState(invoiceData[0]?.Exchange?.GiveProductStokNumber ? invoiceData[0]?.Exchange?.GiveProductStokNumber : invoiceData[0]?.Model?.UserModelCode);

  const brands = [3, 4, 18, 28, 43];
  const isValidStatus = [23, 24, 25, 27, 29, 30].includes(receiptStatus);
  const isValidWebPuanStatus = [23, 24, 25, 27, 30].includes(receiptStatus);
  const topProductIds = [18924, 27048, 28063];
  const airConditionerMainGroups = [30092, 24370, 18597];
  var lastControlMessage = 'Onaylamak istediğinize emin misiniz?';
  const [oldSubStatus, setOldSubStatus] = React.useState([]);

  const [filterServiceRefundText, setFilterServiceRefundText] =
    React.useState('');
  const [filteredserviceRefundList, setFilteredserviceRefundList] =
    React.useState(serviceRefundList);

  useEffect(() => {
    if (!invoiceData[0]?.Consumer?.UpTypeId) {
      dispatch(getConfirmType()).then((a) => {
        setConfirmTypes(a.payload[0]);
        dispatch(getConfirmTypeWithUp(1)).then((b) => {
          var LowConfirmType = b.payload.filter((i) => i.Id == 11)[0];
          setUpConfirmTypes(LowConfirmType);
          var tempData = JSON.parse(JSON.stringify(invoiceData));
          tempData[0].Consumer.UpTypeId = 1;
          tempData[0].Consumer.LowTypeId = 11;
          dispatch(generalFollowActions.updateNewData(tempData));
        });
      });
    } else {
      dispatch(getConfirmType()).then((a) => {
        dispatch(getConfirmTypeWithUp(invoiceData[0]?.Consumer?.UpTypeId)).then(
          (b) => {
            var LowConfirmType = b.payload.filter(
              (i) => i.Id == invoiceData[0]?.Consumer?.LowTypeId
            )[0];
            var UpConfirmType = a.payload.filter(
              (i) => i.Id == invoiceData[0]?.Consumer?.UpTypeId
            )[0];
            setConfirmTypes(UpConfirmType);
            setUpConfirmTypes(LowConfirmType);
          }
        );
      });
    }
  }, [dispatch]);

  useEffect(() => {
    getOldSubStatusOfReceipt();
  }, []);

  useEffect(() => {
    if (invoiceData[0]?.Exchange?.IBANNO.length > 2) {
      setIsIbanValidatorActivated(true);
    } else {
      setIsIbanValidatorActivated(false);
    }
  }, [invoiceData[0]?.Exchange?.IBANNO]);

  const data = {
    ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(), //Servis Numarası
    ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(), //Başvuru No
    OutOfStok: invoiceData[0]?.Exchange?.NoneProduct?.toString(), //Teminsiz Stok
    Status: invoiceData[0]?.Exchange?.Status,
    BankCode: invoiceData[0]?.Exchange?.BankCode?.toString(), //Banka Kodu
    BankName: invoiceData[0]?.Exchange?.BankName?.toString(), //Banka Adı
    IBANNO: invoiceData[0]?.Exchange?.IBANNO?.toString(), //Iban No
    WebSalesInvoiceNumber: invoiceData[0]?.Exchange?.WebSalesInvoiceNumber, //Web Satış Fatura No
    PaymentOwner: invoiceData[0]?.Exchange?.ABSPayAccount?.toString(), //Ödeme Sahibi
    InvoiceNumber:
      invoiceData[0]?.Consumer?.ChangeInvoiceNo?.toString() || null, //Fatura No
    InvoiceDate:
      invoiceData[0]?.Consumer?.ChangeInvoiceDate?.toString() || null, //Fiş Tarihi
    InvoiceItemQuantity: invoiceData[0]?.Consumer?.ChangeInvoiceItemQuantity, //DEGISIM_FATURA_KALEM_ADET
    InvoicePrice: invoiceData[0]?.Consumer?.ChangeInvoiceAmount, // Fatura tutarı
    ExchangeReason: invoiceData[0]?.Exchange?.ExchangeResonMain, //altDegisimNedeni
    ExchangeReasonDetail: invoiceData[0]?.Exchange?.ExchangeReson, //degisimNedeniDetay
    IVRGetPrice: invoiceData[0]?.Exchange?.IVRGetPrice?.toString(),
    UpdateUser: SessionUser?.UserId?.toString(),
    Note: updatedInfoChat?.toString(), //Mesaj alanı
    GiveProductNumber: invoiceData[0]?.Model?.UserModelCode?.toString() || '', //kul model kodu !! tekrar sor
    ServiceRefundReason:
      invoiceData[0]?.Exchange?.ServiceRefund?.toString() || '',
    ExchangeType: invoiceData[0]?.Consumer?.LowTypeId, //alt onay türü
    CurrentStatus: invoiceData[0]?.Exchange?.Status, //mevcut durum
    CustomerType: invoiceData[0]?.Consumer?.ConsumerType, //Müşteri tipi
    TopConfirmType: invoiceData[0]?.Consumer?.UpTypeId, //Üst onay türü
    UpConfirmType: invoiceData[0]?.Consumer?.LowTypeId, //Alt onay türü
    ProductGetStockPrice: invoiceData[0]?.Exchange?.ReceivedProductPrice,
    ProductGetStockNumber:
      invoiceData[0]?.Model?.RevisionStokNo?.toString() || null, // revizyonlu stok numarası
    UYear: invoiceData[0]?.Apply?.UYear,
    UMonth: invoiceData[0]?.Apply?.UMonth,
    City:
      invoiceData[0]?.Consumer?.ConsumerType == 3
        ? invoiceData[0]?.Retailer?.City
        : invoiceData[0]?.Consumer?.City, //sehir
    UserModelCode: invoiceData[0]?.Model?.UserModelCode?.toString() || null,
    SubStatus: invoiceData[0]?.Exchange?.SubStatus,
    GiveProductStokNumber:
      invoiceData[0]?.Exchange?.GiveProductStokNumber?.toString() || '', //verilenUrunStokNo
    GiveProductDesc:
      invoiceData[0]?.Exchange?.GiveProductDesc?.toString() || null, //verilen ürün açıklama
    GiveProductPrice: invoiceData[0]?.Exchange?.GiveProductPrice || null, //VERİLEN ÜRÜN Fıyat
    stokTotal: invoiceData[0]?.Exchange?.stokTotal?.toString() || null, //VERILEN URUN stok
    ProductTotalPrice: invoiceData[0].Exchange.ProductTotalPrice || null, //toplam tutar
    RecomendedPrice:
      invoiceData[0]?.Exchange?.RecomendedPrice?.toString() || null, //Tavsiye edilen ürün değişim ücreti
    Price: offerProductPrice?.toString() || null, //ucretliDegisimTutari ,
    TopModelDifPrice: topModelDifferentPrice?.toString() || null,
    PaymentOwner: invoiceData[0]?.Exchange?.ABSPayAccount?.toString() || null, //dekont sahibi
    RefundConvince: invoiceData[0]?.Exchange?.RefundConvince?.toString() || '', //ikna nedenleri
    UpdateStatus: -1,
    UserID: invoiceData[0]?.UserID,
    ReceiptNumber: invoiceData[0]?.Apply?.ReceiptNumber,
    RequestedModelStokNumber: invoiceData[0]?.RequestedModelCode,
    RequestedModelPrice:
      invoiceData[0]?.Exchange?.RequestedModelPrice?.toString() || null,
    EqualProductStokNumber:
      invoiceData[0]?.Exchange?.EqualProductStokNo?.toString() || '',
    EqualProductPrice: invoiceData[0]?.Exchange?.EqualProductPrice || null,
    AirConditionerReceiptNo:
      invoiceData[0]?.Exchange.AirconditionerReceiptNo || 0,
    TopModelDiscount: TopModelPriceDiscountRate || 0,
    PaidChangeDiscount: PaidChangeDiscountRate || 0,
    DiscountControl: invoiceData[0]?.Exchange.DiscountControl || 0,
    WebPointInvoicePrice: invoiceData[0]?.Exchange?.WebPointInvoicePrice,
    WebPointCustomerPhoneNumber:
      invoiceData[0]?.Exchange.WebPointCustomerPhoneNumber,
    WebPointProductStokNumber: invoiceData[0]?.Exchange?.WebPointStockNumber
      ? invoiceData[0]?.Exchange?.WebPointStockNumber
      : invoiceData[0]?.Exchange?.EqualProductStokNo
        ? invoiceData[0]?.Exchange?.EqualProductStokNo
        : invoiceData[0]?.Model?.UserModelCode,
    WebPointAvaiblePrice: invoiceData[0]?.Exchange?.WebPointAvaiblePrice,
    WebPointCancelType: invoiceData[0]?.Exchange.WebPointCancelType,
    DifferentBrandManagerControl:
      invoiceData[0]?.Exchange?.DifferentBrandManagerControl,
    AirConditionerComplementaryReceiptNumber:
      invoiceData[0]?.Exchange?.AirConditionerComplementaryReceiptNumber || 0
  };
  const uyariKapat = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setUyari(false);
  };

  const columns = [
    { field: 'ACIKLAMA', headerName: 'Servis Red Nedeni', flex: 1 }
  ];

  const convinceColumns = [
    { field: 'ACIKLAMA', headerName: 'İkna Türü', flex: 1 }
  ];

  const convinceTypeColumns = [
    { field: 'IKNA_TURU', headerName: 'İkna Türü', flex: 1 }
  ];

  function InfoAlert(ErrorDescription) {
    setresponseMessage({
      ErrorCode: 0,
      ErrorDescription: ErrorDescription
    });
    setUyari(true);
    return;
  }

  function getOldSubStatusOfReceipt() {
    const data = {
      SessionUser: SessionUser?.UserId || 0,
      UnitCode: invoiceData[0]?.Exchange?.Service,
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo
    };
    dispatch(getDetail(data))
      .then((response) => {
        if (response.payload?.length > 0) {
          const data = response.payload[0];
          setOldSubStatus(
            data.Exchange.SubStatus ? data.Exchange.SubStatus : 0
          );
        }
      })
      .catch((err) => {
        return;
      });
  }

  function updateLowConfirmTypeValue(val) {
    //Alt Onay Tipi Yeni Değeri
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Consumer.LowTypeId = val?.Id;
    dispatch(generalFollowActions.updateNewData(tempData));
    setUpConfirmTypes(val);
  }

  function updateUpConfirmTypeValue(val) {
    //Onay Tipi Yeni Değeri
    setLoading(true);
    if (val?.Id) {
      dispatch(getConfirmTypeWithUp(val?.Id)).then((b) => {
        var tempData = JSON.parse(JSON.stringify(invoiceData));
        tempData[0].Consumer.UpTypeId = val?.Id;
        tempData[0].Consumer.LowTypeId = b?.payload[0].Id;
        dispatch(generalFollowActions.updateNewData(tempData));
        setUpConfirmTypes(b?.payload[0]);
        setConfirmTypes(val);
        setLoading(false);
      });
    }
    setLoading(false);
  }

  function ucretliDegisimIndirimOranıKontrol() {
    if (
      invoiceData[0]?.Consumer?.UpTypeId == 2 ||
      invoiceData[0]?.Consumer?.UpTypeId == 6
    ) {
      return false;
    }
    //MHTU-2940 kapsamında yönetici onayına aynı fiyatla tekrar tekrar gitmemesi için yapıldı
    if (
      invoiceData[0]?.Exchange?.IsManagerFirstControl == 1 &&
      exData[0].Exchange.Price === +offerProductPrice
    ) {
      return false;
    }
    var kisiBazlıUcretliDegisimOranı =
      +invoiceData[0]?.Exchange?.PaidChangeDiscountUserRate;
    if (
      PaidChangeDiscountRate >
      parseFloat(kisiBazlıUcretliDegisimOranı.toFixed(2))
    ) {
      return true;
    }
    return false;
  }
  function ustModelIndırımOranıKontrol() {
    if (
      invoiceData[0]?.Consumer?.UpTypeId == 2 ||
      invoiceData[0]?.Consumer?.UpTypeId == 6
    ) {
      return false;
    }
    //MHTU-2940 kapsamında yönetici onayına aynı fiyatla tekrar tekrar gitmemesi için yapıldı
    if (
      invoiceData[0]?.Exchange?.IsManagerFirstControl == 1 &&
      exData[0]?.Exchange?.ProductRemainPrice === +topModelDifferentPrice
    ) {
      return false;
    }
    var kisiBazlıUstModelDegisimOranı =
      +invoiceData[0]?.Exchange?.TopModelPriceDiscountUserRate;
    if (
      TopModelPriceDiscountRate >
      parseFloat(kisiBazlıUstModelDegisimOranı.toFixed(2))
    ) {
      return true;
    }
    return false;
  }
  function Log() {
    var SubStatusLog = '';
    var Msj = '';
    var ExchangeReasonLog = '';
    var TotalPrice = '';
    var GiveProductLog = '';
    var IbanNoLog = '';
    var DiscountButtonDsc = '';
    var WebPointCancelTypeDsc = '';
    var SubStatusNoChanged = !invoiceData[0]?.Exchange?.SubStatus
      ? 0
      : invoiceData[0]?.Exchange?.SubStatus;
    if (
      exData[0]?.Exchange?.SubStatus != invoiceData[0]?.Exchange?.SubStatus &&
      SubStatusNoChanged != 0
    ) {
      var SubStatusLog =
        'Ara Durum:' + ' ' + invoiceData[0]?.Exchange?.IndermediateState;
    }
    if (
      exData[0]?.Exchange?.ExchangeResonMain !=
      invoiceData[0]?.Exchange?.ExchangeResonMain
    ) {
      var ExchangeReasonLog =
        'Değişim Nedeni:' +
        ' ' +
        invoiceData[0]?.Exchange?.ExchangeReasonMainDsc;
    }
    if (
      exData[0]?.Exchange?.ProductTotalPrice !=
      invoiceData[0]?.Exchange?.ProductTotalPrice
    ) {
      var TotalPrice =
        'Tutar:' +
        ' ' +
        (parseFloat(topModelDifferentPrice) + parseFloat(offerProductPrice));
    }
    if (
      exData[0]?.Exchange?.GiveProductStokNumber !=
      invoiceData[0]?.Exchange?.GiveProductStokNumber
    ) {
      GiveProductLog =
        'Verilen Ürün:' +
        ' ' +
        invoiceData[0]?.Exchange?.GiveProductStokNumber +
        '-' +
        invoiceData[0]?.Exchange?.GiveProductDesc;
    }
    if (updatedInfoChat != '') {
      var Msj = 'Mesaj:' + ' ' + updatedInfoChat?.toString();
    }
    if (DiscountButtonMessage != '') {
      var DiscountButtonDsc =
        'İndirim Buton Tutarı:' + ' ' + DiscountButtonMessage?.toString();
    }
    var IbanNoControl = !exData[0].Exchange.IBANNO
      ? 0
      : exData[0].Exchange.IBANNO;
    var IbanNoChanged = !invoiceData[0].Exchange.IBANNO
      ? 0
      : invoiceData[0].Exchange.IBANNO;
    if (IbanNoChanged != 0 && IbanNoControl != IbanNoChanged) {
      IbanNoLog = 'Iban No:' + IbanNoChanged + ' olarak değiştirildi.';
    }

    if (
      invoiceData[0]?.Consumer?.UpTypeId === 7 &&
      invoiceData[0]?.Exchange?.Status === 31 &&
      webPointCancelType !== ''
    ) {
      var CancelTypeDetail = '';
      switch (webPointCancelType) {
        case 'ZFG':
          CancelTypeDetail = 'Fiyat Güncelleme';
          break;
        case 'ZUI':
          CancelTypeDetail = 'Değişim çekinden ücret iadesine dönüş';
          break;
        case 'ZUD':
          CancelTypeDetail = 'Değişim çekinden ürün değişimine dönüş';
          break;
        default:
          CancelTypeDetail = '';
          break;
      }
      if (CancelTypeDetail !== '') {
        WebPointCancelTypeDsc =
          'Değişim Çeki Red Nedeni:' + ' ' + CancelTypeDetail;
      } else {
        WebPointCancelTypeDsc = '';
      }
    }

    data.Note =
      SubStatusLog +
      ' ' +
      ExchangeReasonLog +
      ' ' +
      TotalPrice +
      ' ' +
      GiveProductLog +
      ' ' +
      IbanNoLog +
      ' ' +
      DiscountButtonDsc +
      ' ' +
      Msj +
      ' ' +
      WebPointCancelTypeDsc;
  }

  function discountAdminApproval(smsSablon) {
    var ToplamTutar =
      parseFloat(+topModelDifferentPrice) + parseFloat(+offerProductPrice);
    if (!invoiceData[0].Exchange.ProductTotalPrice) {
      var ToplamTutar = 0;
    }
    if (smsSablon == 1) {
      var sablon =
        'Merhaba ' +
        invoiceData[0]?.Consumer?.Name +
        ',' +
        invoiceData[0]?.Model?.ProductDesc +
        ' model ürününüzün ' +
        invoiceData[0]?.Exchange?.GiveProductDesc +
        ' ürün ile ' +
        ToplamTutar +
        ' TL karsiliginda degisimine karar verilmistir. İnceleme ve onayınız sonrası ödemenizi @BANKAADRES linkini kullanarak banka kartı (atm, bankamatik, maaş kartı, vb.) veya anlaşmalı bankaların kredi kartları ile taksitli olarak yapabilir veya 0850 210 0 888 i arayabilirsiniz (Belirtilen fiyat ve model 30 gün süresince geçerlidir.)';
    }
    if (smsSablon == 2) {
      var sablon =
        'Sayın ' +
        invoiceData[0]?.Consumer?.Name +
        ', ' +
        invoiceData[0]?.Apply?.ReceiptNumber +
        ' Hizmet No’ lu Ürün Değişim talebiniz onaylanmış olup teyidiniz sonrasında tarafınıza verilen ' +
        invoiceData[0]?.Exchange?.GiveProductDesc +
        ' ürün sevk edilecektir. Onay vermek için @BANKAADRES linkte yer alan onay butonuna tıklayabilirsiniz.';
    }
    var tmp = false;

    var verilecekUrünFiyat = +exData[0]?.Exchange?.GiveProductPrice;
    var muadilUrunFiyat = +exData[0]?.Exchange?.EqualProductPrice;
    var yuzdelikUstModelTutarOran =
      +invoiceData[0]?.Exchange?.TopModelPriceDiscountUserRate / 100;
    var ustModelIndırımTutar =
      (verilecekUrünFiyat - muadilUrunFiyat) * yuzdelikUstModelTutarOran;
    confirmAlert({
      message: (
        <div>
          Yapabileceğiniz son Ücretli Değişim Tutarı{' '}
          {parseFloat(
            +invoiceData[0]?.Exchange?.RecomendedPrice *
            (+invoiceData[0]?.Exchange?.PaidChangeDiscountUserRate / 100)
          ).toFixed(2)}{' '}
          TL ,Üst Model Fark Tutar{' '}
          {parseFloat(+ustModelIndırımTutar).toFixed(2)} TL. İndirim hakkınızı
          aştığınız için değişim fişi Ürün Değişim Yönetici onayına gidecektir.
          Ürün değişim yönetici onayına göndermek istiyor musunuz?
          <br />
          <br />
          {sablon}
        </div>
      ),
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setDiscountMessagePopUp(true);
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function SendAdminControl() {
    Log();
    var yuzdelikUcretliTutarOran =
      +invoiceData[0]?.Exchange?.PaidChangeDiscountUserRate / 100;
    var tavsiyeEdilenDegisimTutarı = +invoiceData[0]?.Exchange?.RecomendedPrice;
    var yuzdelikUcretliTutar =
      tavsiyeEdilenDegisimTutarı * [1 - yuzdelikUcretliTutarOran];
    if (yuzdelikUcretliTutar < 0 || !yuzdelikUcretliTutar) {
      yuzdelikUcretliTutar = 0;
    }

    var verilecekUrünFiyat = +exData[0]?.Exchange?.GiveProductPrice;
    var muadilUrunFiyat = +exData[0]?.Exchange?.EqualProductPrice;
    var yuzdelikUstModelTutarOran =
      +invoiceData[0]?.Exchange?.TopModelPriceDiscountUserRate / 100;
    var ustModelIndırımTutar =
      (verilecekUrünFiyat - muadilUrunFiyat) * (1 - yuzdelikUstModelTutarOran);

    var yeniVerilecekUrünFiyat = +invoiceData[0]?.Exchange?.GiveProductPrice;
    var yeniMuadilUrunFiyat = +invoiceData[0]?.Exchange?.EqualProductPrice;
    var yeniUstDegısımTutarı =
      (yeniVerilecekUrünFiyat - yeniMuadilUrunFiyat) *
      (TopModelPriceDiscountRate / 100);
    if (yeniUstDegısımTutarı < 0 || !yeniUstDegısımTutarı) {
      yeniUstDegısımTutarı = 0;
    }
    var ucretliDegisimIndirimFiyatı =
      tavsiyeEdilenDegisimTutarı * (PaidChangeDiscountRate / 100);
    if (!ucretliDegisimIndirimFiyatı || ucretliDegisimIndirimFiyatı < 0) {
      ucretliDegisimIndirimFiyatı = 0;
    }
    var indirimOran = +invoiceData[0]?.Exchange?.PaidChangeDiscountUserRate;
    if (!indirimOran) {
      indirimOran = 0;
    }
    var ustModelKullanıcıOran = invoiceData[0]?.Exchange
      ?.TopModelPriceDiscountUserRate
      ? invoiceData[0]?.Exchange?.TopModelPriceDiscountUserRate
      : 0;

    var resultForDsc =
      'Ücretli Değişim Tutarı indirim hakkı %' +
      indirimOran +
      '  ' +
      parseFloat(yuzdelikUcretliTutar).toFixed(0) +
      ' TL olmasına rağmen %' +
      parseFloat(PaidChangeDiscountRate).toFixed(0) +
      '  ' +
      parseFloat(+ucretliDegisimIndirimFiyatı).toFixed(0) +
      ' TL, Üst model tutar indirim hakkı %' +
      ustModelKullanıcıOran +
      '  ' +
      parseFloat(ustModelIndırımTutar).toFixed(0) +
      ' TL olmasına rağmen %' +
      parseFloat(TopModelPriceDiscountRate).toFixed(0) +
      '  ' +
      parseFloat(+yeniUstDegısımTutarı).toFixed(0) +
      ' TL indirim yaparak yönetici onayına göndermiştir.';
    if (!discountMessageDsc && discountMessageDsc == '') {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Lütfen Mesaj Giriniz!'
      });
      setUyari(true);
      return;
    }
    setDiscountMessagePopUp(false);
    setLoading(true);
    data.Note =
      data.Note + ' ' + resultForDsc + ' Mesaj: ' + discountMessageDsc;
    axios
      .post('DiscountAdminApproval', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data.ErrorCode == 1000) {
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: response.data.ErrorDescription
          });
        } else {
          setresponseMessage({
            ErrorCode: 1000,
            ErrorDescription: 'İşleminiz başarı ile gerçekleşmiştir.'
          });
        }

        setUyari(true);
        setLoading(false);
        removeRowInTable();
        setTimeout(pageUpdate, 500);
      })
      .catch((err) => {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: 'Bir sorun oluştu!!!'
        });
        setUyari(true);
        setLoading(false);
        return;
      });
    setDiscountMessageDsc([]);
  }

  function sendDifferentBrandAdminControl() {
    if (differentBrandApplyMessage.length < 10) {
      InfoAlert(
        'Lütfen mesaj alanına minimum 10 karakter uzunluğunda açıklama giriniz!!'
      );
      setUyari(true);
      return;
    }

    var differentBrandMsg = 'Farklı Marka İçin Yönetici Onayı İsteniyor';
    data.Note = differentBrandMsg + ' Mesaj: ' + differentBrandApplyMessage;
    data.SubStatus = 21;
    setDifferentBrandSendManagerPopUp(false);
    setLoading(true);
    axios
      .post('DiscountAdminApproval', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data.ErrorCode == 1000) {
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: response.data.ErrorDescription
          });
        } else {
          setresponseMessage({
            ErrorCode: 1000,
            ErrorDescription: 'İşleminiz başarı ile gerçekleşmiştir.'
          });
        }

        setUyari(true);
        setLoading(false);
        removeRowInTable();
        setTimeout(pageUpdate, 500);
      })
      .catch((err) => {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: 'Bir sorun oluştu!!!'
        });
        setUyari(true);
        setLoading(false);
        return;
      });
  }

  function BackToSolutionCenter() {
    if (!updatedInfoChat) {
      InfoAlert('Lütfen mesaj alanına açıklama giriniz!!!');
      setUyari(true);
      return;
    }
    confirmAlert({
      message: 'Çözüm merkezine yönlendiriyorsunuz.Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            var Dsc = 'Ara Durum:Değerlendirme ' + ' Mesaj: ' + updatedInfoChat;
            data.Note = Dsc;
            data.SubStatus = 7;
            data.Status = 23;
            axios
              .post('BackToSolutionCenterForCM', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setLoading(false);
                setresponseMessage({
                  ErrorCode: 1000,
                  ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
                });
                removeRowInTable();
                setTimeout(pageUpdate, 500);
                setUyari(true);
                return;
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function ExchangeManagerReject() {
    if (!rejectMessageDsc && rejectMessageDsc == '') {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Lütfen Mesaj Giriniz!'
      });
      setUyari(true);
      return;
    }
    setRejectMessagePopUp(false);
    data.Note = rejectMessageDsc;
    data.SubStatus = 7;
    data.Status = 23;
    axios
      .post('BackToSolutionCenter', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setLoading(false);
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
        });
        removeRowInTable();
        setTimeout(pageUpdate, 500);
        setUyari(true);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  function removeRowInTable() {
    const orjinalData = [...UpdatedInformation];
    const removeRow = orjinalData?.findIndex(
      (x) => x.FIS_NO == invoiceData[0]?.Apply?.ReceiptNumber
    );
    orjinalData.splice(removeRow, 1);
    const xd = orjinalData.length;
    dispatch(generalFollowActions.updateProductInformation(orjinalData));
    dispatch(generalFollowActions.updateRowCount(xd));
  }

  function TakeBack() {
    //Sap iptal
    confirmAlert({
      message: 'Geri Almak İstediğinizden Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            data.Status = invoiceData[0]?.Exchange?.Status;
            data.Note =
              'Müşteri Ücreti Ödemedi durumundan Ücret Ödemesi Bekleniyor Konumuna alınmıştır.';
            axios
              .post('TakeItBack', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setresponseMessage({
                  ErrorCode: 1000,
                  ErrorDescription: 'İşleminiz Başarılı ile gerçekleşti...'
                });
                setUyari(true);
                setLoading(false);
                removeRowInTable();
                setTimeout(pageUpdate, 500);
                return;
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function GetReceiptDetail() {
    dispatch(generalFollowActions.updateInfoChat(''));
    const data = {
      SessionUser: SessionUser?.UserId || 0,
      UnitCode: invoiceData[0]?.Exchange?.Service,
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo
    };
    dispatch(getDetail(data))
      .then((response) => { })
      .catch((err) => {
        return;
      });
    dispatch(
      getReceiptInfoChat([
        invoiceData[0]?.Exchange?.ApplyNo,
        invoiceData[0]?.Exchange?.Service
      ])
    )
      .then((response) => { })
      .catch((err) => {
        return;
      });
  }
  function AllSave() {
    if (invoiceData[0]?.Exchange?.SubStatus == 18 && !updatedInfoChat) {
      InfoAlert('Lütfen Değişim Grubu için Detaylı Açıklama Yazınız!!!');
      setUyari(true);
      return;
    }
    if (!invoiceData[0]?.Consumer?.UpTypeId) {
      InfoAlert('Lütfen onay tipi seçiniz.');
      return;
    }
    if (!invoiceData[0]?.Exchange?.ExchangeReson) {
      InfoAlert('Lütfen değişim nedeni seçiniz.');
      return;
    }
    if (isIbanValidatorActivated && isIbanNoValid === 0) {
      InfoAlert('Lütfen geçerli bir IBAN numarası giriniz!...');
      return;
    }

    //MHTU-3836
    if (
      brands.includes(invoiceData[0]?.Apply.BrandCode) &&
      invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 &&
      !invoiceData[0]?.Exchange?.GiveProductStokNumber &&
      invoiceData[0]?.Exchange?.Status === 23 &&
      oldSubStatus === 3 &&
      invoiceData[0]?.Exchange?.SubStatus !== 3 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 2 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 7
    ) {
      InfoAlert('Verilecek Ürün Alanı boş olamaz.');
      return;
    }
    //MHTU-3594 Başlangıç
    if (
      invoiceData[0]?.Apply.BrandCode !== 3 &&
      invoiceData[0]?.Apply.BrandCode !== 4 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Arçelik ve Beko marka ürünler dışında Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      invoiceData[0]?.Consumer?.ConsumerType !== 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Müşteri Tipi: Müşteri haricinde Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      topProductIds.includes(invoiceData[0]?.Model?.TopProductId) &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Ürün Grubu Yazarkasa olan ürünler için Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-3594 Son

    //MHTU-4127 Başlangıç
    if (
      invoiceData[0]?.Exchange.IsAirConditionerProduct === 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Portatif Klima Grubundaki Ürünler Haricindeki Klima Grubu Ürünler Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-4127 Bitiş

    if (
      invoiceData[0]?.Exchange?.SubStatus === 22 &&
      (!airConditionerMainGroups.includes(invoiceData[0]?.Model?.MainGroupId) ||
        invoiceData[0]?.Consumer?.ConsumerType != 1)
    ) {
      InfoAlert(
        "'Klima Tamamlayıcı Ünite Ödeme/Onay Bekleniyor' ara durumu sadece Klima Ürün Grubu ve Müşteri Tipi: Müşteri olan fişler için seçilebilmektedir."
      );
      return;
    }

    //MHTU-1539
    //fatura numarası 16 haneli olmalı ve 1111111111111111 olamaz checkleri
    if (invoiceData[0]?.Exchange?.WebSalesInvoiceNumber) {
      if (invoiceData[0]?.Exchange?.WebSalesInvoiceNumber.length != 16) {
        InfoAlert('Lütfen 16 haneli web satış fatura numarasını giriniz.');
        return;
      }
      if (
        invoiceData[0]?.Exchange?.WebSalesInvoiceNumber === '1111111111111111'
      ) {
        InfoAlert('Lütfen geçerli bir web satış fatura numarası giriniz.');
        return;
      }
    }
    if (
      invoiceData[0]?.Apply.AttributeCode == 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 1 &&
      invoiceData[0]?.Exchange?.ProductTotalPrice == 0
    ) {
      RevisionControlPopUp(
        'Revizyonlu ürünü standart olarak onaylıyorsunuz. Emin misiniz?',
        'Kaydet'
      );
      return;
    }
    if (
      invoiceData[0]?.Apply.AttributeCode != 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 4 &&
      invoiceData[0]?.Exchange?.ProductTotalPrice == 0
    ) {
      RevisionControlPopUp(
        'Standart ürünü revizyonlu olarak onaylıyorsunuz. Emin misiniz?',
        'Kaydet'
      );
      return;
    }

    SaveConfirmAlert();
  }

  function SaveConfirmAlert() {
    confirmAlert({
      message: 'Kaydetmek İstediğinizden Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            if (!invoiceData[0]?.Consumer?.UpTypeId) {
              InfoAlert('Lütfen onay tipi seçiniz.');
              return;
            }
            setLoading(true);
            Log();
            dispatch(generalFollowActions.updateDiscountButtonMessage(''));
            data.Status = invoiceData[0]?.Exchange?.Status;
            axios
              .post('SolutionCenterSave', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                if (response.data.ErrorCode == 1000) {
                  setLoading(false);
                  setresponseMessage({
                    ErrorCode: 0,
                    ErrorDescription: response.data.ErrorDescription
                  });
                  setUyari(true);
                } else {
                  setLoading(false);
                  setresponseMessage({
                    ErrorCode: 1000,
                    ErrorDescription: response.data.ErrorDescription
                  });
                  setUyari(true);
                  GetReceiptDetail();
                }
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function RevisionControlPopUp(DescriptionInfo, type) {
    var tmp = false;
    confirmAlert({
      message: DescriptionInfo,
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            tmp = true;
            PopUpTypes(type);
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ],
      afterClose: () => (tmp ? PopUpTypes(type) : null)
    });
  }

  function PopUpTypes(popUpTypes) {
    if (popUpTypes == 'Kaydet') {
      SaveConfirmAlert();
    } else if (popUpTypes == 'ücret ödemesi bekleniyor') {
      WaitingStatusPaymentPopUp();
    } else if (popUpTypes == 'Müşteriden Onay Bekleniyor') {
      WaitingCustomerAcceptPopUp();
    } else if (popUpTypes == 'Onayla') {
      lastControl();
    }
  }

  function StatusCancel() {
    if (!invoiceData[0]?.Consumer?.UpTypeId) {
      InfoAlert('Lütfen onay tipi seçiniz.');
      return;
    }
    //MHTU-3836
    if (
      brands.includes(invoiceData[0]?.Apply.BrandCode) &&
      invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 &&
      !invoiceData[0]?.Exchange?.GiveProductStokNumber &&
      invoiceData[0]?.Exchange?.Status === 23 &&
      oldSubStatus === 3 &&
      invoiceData[0]?.Exchange?.SubStatus !== 3 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 2 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 7
    ) {
      InfoAlert('Verilecek Ürün Alanı boş olamaz.');
      return;
    }
    //MHTU-3594 Başlangıç
    if (
      invoiceData[0]?.Apply.BrandCode !== 3 &&
      invoiceData[0]?.Apply.BrandCode !== 4 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Arçelik ve Beko marka ürünler dışında Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      invoiceData[0]?.Consumer?.ConsumerType !== 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Müşteri Tipi: Müşteri haricinde Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      topProductIds.includes(invoiceData[0]?.Model?.TopProductId) &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Ürün Grubu Yazarkasa olan ürünler için Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-3594 Son

    //MHTU-4127 Başlangıç
    if (
      invoiceData[0]?.Exchange.IsAirConditionerProduct === 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Portatif Klima Grubundaki Ürünler Haricindeki Klima Grubu Ürünler Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-4127 Bitiş

    if (
      invoiceData[0]?.Exchange?.SubStatus === 22 &&
      (!airConditionerMainGroups.includes(invoiceData[0]?.Model?.MainGroupId) ||
        invoiceData[0]?.Consumer?.ConsumerType != 1)
    ) {
      InfoAlert(
        "'Klima Tamamlayıcı Ünite Ödeme/Onay Bekleniyor' ara durumu sadece Klima Ürün Grubu ve Müşteri Tipi: Müşteri olan fişler için seçilebilmektedir."
      );
      return;
    }

    if (!invoiceData[0]?.Exchange?.ExchangeReson) {
      InfoAlert('Lütfen değişim nedeni seçiniz.');
      return;
    }
    confirmAlert({
      message: 'İptal Etmek İstediğinizden Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            if (!updatedInfoChat && updatedInfoChat == '') {
              setresponseMessage({
                ErrorCode: 0,
                ErrorDescription: 'Lütfen Mesaj Giriniz!'
              });
              setUyari(true);
              return;
            }
            dispatch(getServiceRefund())
              .then((response) => {
                setServiceRefundList(response.payload);
                setFilteredserviceRefundList(response.payload);
                setFilterServiceRefundText('');
                setServiceRefundPopUp(true);
                setIsUpdate(true);
              })
              .catch((err) => {
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function SendToService() {
    //Servise Ata
    if (!invoiceData[0]?.Consumer?.UpTypeId) {
      InfoAlert('Lütfen onay tipi seçiniz.');
      return;
    }
    //MHTU-3594 Başlangıç
    if (
      invoiceData[0]?.Apply.BrandCode !== 3 &&
      invoiceData[0]?.Apply.BrandCode !== 4 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Arçelik ve Beko marka ürünler dışında Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      invoiceData[0]?.Consumer?.ConsumerType !== 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Müşteri Tipi: Müşteri haricinde Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      topProductIds.includes(invoiceData[0]?.Model?.TopProductId) &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Ürün Grubu Yazarkasa olan ürünler için Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-3594 Son

    //MHTU-4127 Başlangıç
    if (
      invoiceData[0]?.Exchange.IsAirConditionerProduct === 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Portatif Klima Grubundaki Ürünler Haricindeki Klima Grubu Ürünler Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-4127 Bitiş

    if (
      invoiceData[0]?.Exchange?.SubStatus === 22 &&
      (!airConditionerMainGroups.includes(invoiceData[0]?.Model?.MainGroupId) ||
        invoiceData[0]?.Consumer?.ConsumerType != 1)
    ) {
      InfoAlert(
        "'Klima Tamamlayıcı Ünite Ödeme/Onay Bekleniyor' ara durumu sadece Klima Ürün Grubu ve Müşteri Tipi: Müşteri olan fişler için seçilebilmektedir."
      );
      return;
    }

    if (!invoiceData[0]?.Exchange?.ExchangeReson) {
      InfoAlert('Lütfen değişim nedeni seçiniz.');
      return;
    }
    confirmAlert({
      message: 'Servise Geri Göndermek İstediğinizden Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            dispatch(getServiceRefund())
              .then((response) => {
                setServiceRefundList(response.payload);
                setFilteredserviceRefundList(response.payload);
                setFilterServiceRefundText('');
                setServiceRefundPopUp(true);
              })
              .catch((err) => {
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function reject() {
    //fiş iptal
    if (selectedServiceRefund?.length == 0) {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Lütfen en az 1 tane servis red nedeni seçiniz!'
      });
      setUyari(true);
      return;
    }
    setLoading(true);
    axios
      .post('SolutionCenterReject', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setServiceRefundPopUp(false);
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
        });
        setUyari(true);
        setLoading(false);
        removeRowInTable();
        setTimeout(pageUpdate, 500);
        return;
      })
      .catch((err) => {
        setLoading(false);
        setServiceRefundPopUp(false);
        return;
      });
  }

  function assignedToService() {
    //fiş servise atama
    if (selectedServiceRefund?.length == 0) {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Lütfen en az 1 tane servis red nedeni seçiniz!'
      });
      setUyari(true);
      return;
    }
    setLoading(true);
    Log();
    axios
      .post('SolutionCenterServiceReject', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setServiceRefundPopUp(false);
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
        });
        setUyari(true);
        setLoading(false);
        removeRowInTable();
        setTimeout(pageUpdate, 500);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  function ServiveRejectLog() {
    if (selectedServiceRefund?.length == 0) {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Lütfen en az 1 tane servis red nedeni seçiniz!'
      });
      setUyari(true);
      return;
    }

    var RejectReasonsList = [];
    selectedServiceRefund.map((i) => {
      RejectReasonsList.push(i);
    });

    const ServiceRejectData = {
      ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(), //Servis Numarası
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(), //Başvuru No
      RejectReasons: RejectReasonsList.toString(),
      SessionUser: SessionUser?.UserId
    };

    axios
      .post('InsertServiceRejectReasonsLog', ServiceRejectData, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz başarılıyla gerçekleşmiştir...'
        });
        setUyari(true);
        setLoading(false);
        return;
      })
      .catch((err) => {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription:
            'Kaydedilemedi... Lütfen daha sonra tekrar deneyiniz...'
        });
        setUyari(true);
        setLoading(false);
        return;
      });
  }

  function WaitingStatusPayment() {
    //müşteriden para ödemesi bekleniyor
    const result = ucretliDegisimIndirimOranıKontrol();
    const result1 = ustModelIndırımOranıKontrol();
    if (
      (result == true || result1 == true) &&
      invoiceData[0]?.Exchange.Status != 25
    ) {
      UserButtonLog(24);
      discountAdminApproval(1);
      return;
    }
    if (!invoiceData[0]?.Consumer?.UpTypeId) {
      InfoAlert('Lütfen onay tipi seçiniz.');
      return;
    }
    //MHTU-3836
    if (
      brands.includes(invoiceData[0]?.Apply.BrandCode) &&
      invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 &&
      !invoiceData[0]?.Exchange?.GiveProductStokNumber &&
      invoiceData[0]?.Exchange?.Status === 23 &&
      oldSubStatus === 3 &&
      invoiceData[0]?.Exchange?.SubStatus !== 3 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 2 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 7
    ) {
      InfoAlert('Verilecek Ürün Alanı boş olamaz.');
      return;
    }
    //MHTU-3594 Başlangıç
    if (
      invoiceData[0]?.Apply.BrandCode !== 3 &&
      invoiceData[0]?.Apply.BrandCode !== 4 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Arçelik ve Beko marka ürünler dışında Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      invoiceData[0]?.Consumer?.ConsumerType !== 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Müşteri Tipi: Müşteri haricinde Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      topProductIds.includes(invoiceData[0]?.Model?.TopProductId) &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Ürün Grubu Yazarkasa olan ürünler için Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-3594 Son

    //MHTU-4127 Başlangıç
    if (
      invoiceData[0]?.Exchange.IsAirConditionerProduct === 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Portatif Klima Grubundaki Ürünler Haricindeki Klima Grubu Ürünler Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-4127 Bitiş

    if (
      invoiceData[0]?.Exchange?.SubStatus === 22 &&
      (!airConditionerMainGroups.includes(invoiceData[0]?.Model?.MainGroupId) ||
        invoiceData[0]?.Consumer?.ConsumerType != 1)
    ) {
      InfoAlert(
        "'Klima Tamamlayıcı Ünite Ödeme/Onay Bekleniyor' ara durumu sadece Klima Ürün Grubu ve Müşteri Tipi: Müşteri olan fişler için seçilebilmektedir."
      );
      return;
    }

    if (!invoiceData[0]?.Exchange?.ExchangeReson) {
      InfoAlert('Lütfen değişim nedeni seçiniz.');
      return;
    }
    if (
      invoiceData[0]?.Exchange?.GiveProductStokNumber == '' ||
      invoiceData[0]?.Exchange?.GiveProductStokNumber == null ||
      invoiceData[0]?.Exchange?.GiveProductPrice < 0
    ) {
      InfoAlert(
        'Verilen stok alanı ile prekande fiyat boş.Lütfen kaydedip tekrar deneyiniz!!'
      );
      return;
    }
    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice > 0 &&
      invoiceData[0]?.Consumer?.UpTypeId != 3
    ) {
      InfoAlert('Ücret Girilmiş Değişim Talebi Onaylanamaz.');
      return;
    }
    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice != 0 &&
      invoiceData[0]?.Consumer?.UpTypeId == 2
    ) {
      InfoAlert(
        'Değişim Onay Türü = Para İadesi seçildiğinde Tutar ve Üst Model Fark Tutar alanlarına ücret bilgisi girilmemelidir!'
      );
      return;
    }
    if (
      !invoiceData[0]?.Exchange?.NoneProduct &&
      invoiceData[0]?.Exchange?.ExchangeResonMain == 30
    ) {
      InfoAlert(
        'Teminsiz stok alanı boş.Değişim yedek parça lojistiği nedeniyle onaylanamaz '
      );
      return;
    }
    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice > 0 &&
      (invoiceData[0]?.Consumer?.UpTypeId == 1 ||
        invoiceData[0]?.Consumer?.UpTypeId == 4 ||
        invoiceData[0]?.Consumer?.UpTypeId == 6)
    ) {
      InfoAlert('Ücret Girilmiş Değişim Talebi Onaylanamaz.');
      return;
    }
    if (
      invoiceData[0]?.Exchange?.productTotalPrice < 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 3
    ) {
      InfoAlert(
        'Ücretli değişim seçtiniz.Lütfen ücretli değişim tutarını giriniz.'
      );
      return;
    }
    if (
      invoiceData[0]?.Apply.AttributeCode == 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 1 &&
      invoiceData[0]?.Exchange?.ProductTotalPrice == 0
    ) {
      RevisionControlPopUp(
        'Revizyonlu ürünü standart olarak onaylıyorsunuz. Emin misiniz?',
        'ücret ödemesi bekleniyor'
      );
      return;
    }
    if (
      invoiceData[0]?.Apply.AttributeCode != 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 4 &&
      invoiceData[0]?.Exchange?.ProductTotalPrice == 0
    ) {
      RevisionControlPopUp(
        'Standart ürünü revizyonlu olarak onaylıyorsunuz. Emin misiniz?',
        'ücret ödemesi bekleniyor'
      );
      return;
    }
    WaitingStatusPaymentPopUp();
  }
  function CustomerAcceptChangeStatus() {
    Log();
    dispatch(generalFollowActions.updateDiscountButtonMessage(''));
    data.Status = 27;
    axios
      .post('SolutionCenterWaitingCustomerAccept', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data.ErrorCode == '0') {
          SendSmsModelSuggest();
          removeRowInTable();
          setTimeout(pageUpdate, 500);
        } else {
          setLoading(false);
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: response.data.ErrorDescription
          });
          setUyari(true);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  function PriceAcceptChangeStatus() {
    Log();
    dispatch(generalFollowActions.updateDiscountButtonMessage(''));
    data.Status = 24;
    axios
      .post('SolutionCenterWaitingStatusPayment', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        if (response.data.ErrorCode == '0') {
          SendSms();
          removeRowInTable();
          setTimeout(pageUpdate, 500);
        } else {
          setLoading(false);
          setresponseMessage({
            ErrorCode: 0,
            ErrorDescription: response.data.ErrorDescription
          });
          setUyari(true);
          return;
        }
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }
  function WaitingStatusPaymentPopUp() {
    confirmAlert({
      message: 'Ödeme Bekleniyora almak istediğinize emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('SMSSend_Message', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setLoading(true);
                setSmsContent(response.data);
                setSmsSendPopUp(true);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function WaitingCustomerAccept() {
    //Onay Bekleniyor
    const result = ucretliDegisimIndirimOranıKontrol();
    const result1 = ustModelIndırımOranıKontrol();
    if (
      (result == true || result1 == true) &&
      invoiceData[0]?.Exchange.Status != 25 &&
      invoiceData[0]?.Consumer.UpTypeId != 7
    ) {
      UserButtonLog(27);
      discountAdminApproval(2);
      return;
    }
    if (!invoiceData[0]?.Consumer?.UpTypeId) {
      InfoAlert('Lütfen onay tipi seçiniz.');
      return;
    }
    //MHTU-3836
    if (
      brands.includes(invoiceData[0]?.Apply.BrandCode) &&
      invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 &&
      !invoiceData[0]?.Exchange?.GiveProductStokNumber &&
      invoiceData[0]?.Exchange?.Status === 23 &&
      oldSubStatus === 3 &&
      invoiceData[0]?.Exchange?.SubStatus !== 3 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 2 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 7
    ) {
      InfoAlert('Verilecek Ürün Alanı boş olamaz.');
      return;
    }
    //MHTU-3594 Başlangıç
    if (
      invoiceData[0]?.Apply.BrandCode !== 3 &&
      invoiceData[0]?.Apply.BrandCode !== 4 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Arçelik ve Beko marka ürünler dışında Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      invoiceData[0]?.Consumer?.ConsumerType !== 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Müşteri Tipi: Müşteri haricinde Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      topProductIds.includes(invoiceData[0]?.Model?.TopProductId) &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Ürün Grubu Yazarkasa olan ürünler için Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-3594 Son

    //MHTU-4127 Başlangıç
    if (
      invoiceData[0]?.Exchange.IsAirConditionerProduct === 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Portatif Klima Grubundaki Ürünler Haricindeki Klima Grubu Ürünler Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-4127 Bitiş

    if (
      invoiceData[0]?.Exchange?.SubStatus === 22 &&
      (!airConditionerMainGroups.includes(invoiceData[0]?.Model?.MainGroupId) ||
        invoiceData[0]?.Consumer?.ConsumerType != 1)
    ) {
      InfoAlert(
        "'Klima Tamamlayıcı Ünite Ödeme/Onay Bekleniyor' ara durumu sadece Klima Ürün Grubu ve Müşteri Tipi: Müşteri olan fişler için seçilebilmektedir."
      );
      return;
    }

    if (!invoiceData[0]?.Exchange?.ExchangeReson) {
      InfoAlert('Lütfen değişim nedeni seçiniz.');
      return;
    }
    if (
      invoiceData[0]?.Exchange?.GiveProductStokNumber == '' ||
      invoiceData[0]?.Exchange?.GiveProductStokNumber == null ||
      invoiceData[0]?.Exchange?.GiveProductPrice < 0
    ) {
      InfoAlert(
        'Verilen stok alanı ile prekande fiyat boş.Lütfen kaydedip tekrar deneyiniz!!'
      );
      return;
    }
    if (
      !invoiceData[0]?.Exchange?.NoneProduct &&
      invoiceData[0]?.Exchange?.ExchangeResonMain == 30
    ) {
      InfoAlert(
        'Teminsiz stok alanı boş.Değişim yedek parça lojistiği nedeniyle onaylanamaz '
      );
      return;
    }
    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice > 0 &&
      invoiceData[0]?.Consumer?.UpTypeId != 3
    ) {
      InfoAlert('Ücret Girilmiş Değişim Talebi Onaylanamaz.');
      return;
    }
    if (invoiceData[0]?.Consumer?.UpTypeId == 2) {
      InfoAlert(
        'Onay Türü Para İadesi olduğundan Müşteriden Onay Bekleniyor konumuna alınamaz!'
      );
      return;
    }
    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice != 0 &&
      invoiceData[0]?.Consumer?.UpTypeId == 2
    ) {
      InfoAlert(
        'Değişim Onay Türü = Para İadesi seçildiğinde Tutar ve Üst Model Fark Tutar alanlarına ücret bilgisi girilmemelidir!'
      );
      return;
    }
    if (
      invoiceData[0]?.Exchange?.productTotalPrice < 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 3
    ) {
      InfoAlert(
        'Ücretli değişim seçtiniz.Lütfen ücretli değişim tutarını giriniz.'
      );
      return;
    }
    if (
      invoiceData[0]?.Apply.AttributeCode == 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 1 &&
      invoiceData[0]?.Exchange?.ProductTotalPrice == 0
    ) {
      RevisionControlPopUp(
        'Revizyonlu ürünü standart olarak onaylıyorsunuz. Emin misiniz?',
        'Müşteriden Onay Bekleniyor'
      );
      return;
    }
    if (
      invoiceData[0]?.Apply.AttributeCode != 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 4 &&
      invoiceData[0]?.Exchange?.ProductTotalPrice == 0
    ) {
      RevisionControlPopUp(
        'Standart ürünü revizyonlu olarak onaylıyorsunuz. Emin misiniz?',
        'Müşteriden Onay Bekleniyor'
      );
      return;
    }
    if (invoiceData[0]?.Consumer.UpTypeId == 7) {
      WebPuanWaitingCustomerAcceptPopUp();
    } else {
      WaitingCustomerAcceptPopUp();
    }
  }
  function WebPuanWaitingCustomerAcceptPopUp() {
    confirmAlert({
      message:
        'Değişim Çeki için Müşteriden Onay Bekleniyor almak istediğinize emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            Log();
            dispatch(generalFollowActions.updateDiscountButtonMessage(''));
            data.Status = 30;
            axios
              .post('SolutionCenterWebPointWaitingCustomerAccept', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setLoading(false);
                setTimeout(pageUpdate, 500);
                removeRowInTable();
                return;
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function WaitingCustomerAcceptPopUp() {
    confirmAlert({
      message: 'Müşteriden Onay Bekleniyora almak istediğinize emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('SMSSend_Message_Model_Suggess', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setSmsModelSuggest(response.data);
                setSmsSendModelSuggestPopUp(true);
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function lastControl() {
    let childrenElementContent = null;
    if (
      invoiceData[0]?.Apply.AttributeCode == 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 1 &&
      invoiceData[0]?.Exchange?.ProductTotalPrice == 0
    ) {
      lastControlMessage =
        'Revizyonlu ürünü standart olarak onaylıyorsunuz.Yine de onaylamak istediğinize emin misiniz?';
    }
    if (
      invoiceData[0]?.Apply.AttributeCode != 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 4 &&
      invoiceData[0]?.Exchange?.ProductTotalPrice == 0
    ) {
      lastControlMessage =
        'Revizyonlu ürünü standart olarak onaylıyorsunuz.Yine de onaylamak istediğinize emin misiniz?';
    }
    if (invoiceData[0]?.Consumer?.UpTypeId === 7) {
      lastControlMessage = '';
      childrenElementContent = (
        <div>
          <p>
            • Değişim çeki tanımlandıktan sonra çeki kullanmadan 14 günlük cayma
            hakkınız bulunmaktadır.
          </p>
          <p>
            • Cayma hakkını kullanmanız durumunda değişim çeki tutarı değil
            ürününüzün ilk alım fatura bedeli iade edilebilir. Cayma hakkını
            kullanabilmeniz için değişim çekini hiç kullanmamış olmanız gerekir.
          </p>
          <p>
            • Değişim Çekinin kısmi kullanılması durumunda iptal/iade talebinde
            bulunulamaz.
          </p>
          <p>
            • Tanımlama sonrası çeki kullanma süreniz 1 yıl olacak. Tavsiyemiz
            enflasyon nedeni ile ürün fiyatları değişeceği için çeki
            ihtiyacınıza göre kısa bir sürede kullanmanız.
          </p>
          <p>
            • Ürün Arçelik ise sadece arcelik.com.tr'de kullanılabilir. Ürün
            Beko ise beko.com.tr'de kullanılabilir olacak. Sadece markamıza ait
            ürünler satın alınabilecek.
          </p>
          <p>
            <strong>
              • Değişim çeki aslında bir ürün olarak size tanımlanacaktır.
              Onaylıyor musunuz?
            </strong>
          </p>
        </div>
      );
    }
    var tmp = false;
    confirmAlert({
      message: lastControlMessage,
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            Log();
            data.Status = 14;
            axios
              .post('SolutionCenterAccept', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                if (
                  response.data.ErrorCode == 1000 ||
                  response.data.ErrorCode == 0
                ) {
                  setresponseMessage({
                    ErrorCode: 1000,
                    ErrorDescription: response.data.ErrorDescription
                  });
                  if (invoiceData[0]?.Consumer?.UpTypeId === 7) {
                    const smsData = {
                      ServiceNumber:
                        invoiceData[0]?.Exchange?.Service?.toString(),
                      ApplicationNumber:
                        invoiceData[0]?.Exchange?.ApplyNo?.toString()
                    };
                    axios.post('WebPointApprovedContractSmsSend', smsData, {
                      headers: { 'Content-Type': 'application/json' }
                    });
                  }
                  setLoading(false);
                  setUyari(true);
                  removeRowInTable();
                  setTimeout(pageUpdate, 500);
                  return;
                } else {
                  setLoading(false);
                  setresponseMessage({
                    ErrorCode: 0,
                    ErrorDescription: response.data.ErrorDescription
                  });
                  setUyari(true);
                  return;
                }
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ],
      childrenElement: () => <div>{childrenElementContent}</div>,
      afterClose: () => (tmp ? lastControl() : null)
    });
  }
  function warrantyControl() {
    var tmp = false;
    confirmAlert({
      message:
        'Ürünün garanti süresi bitmiş.Devam etmek istediğinizden emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            tmp = true;
            lastControl();
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ],
      afterClose: () => (tmp ? warrantyControl() : null)
    });
  }

  function PriceToExchange() {
    var tmp = false;
    confirmAlert({
      message:
        'Ücretli değişim onaylıyorsunuz ödemenin alındığına emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            tmp = true;
            lastControl();
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ],
      afterClose: () => (tmp ? lastControl() : null)
    });
  }

  function PriceToRefund() {
    var tmp = false;
    confirmAlert({
      message: 'İade öncesi ikna alt durumuna alındı mı?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            tmp = true;
            PriceToRefund2();
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ],
      afterClose: () => (tmp ? PriceToRefund2() : null)
    });
  }
  function PriceToRefund2() {
    var tmp = false;
    confirmAlert({
      message:
        'IBAN bilgisi alınmalı, paraiadesi fatura sahibine yapılmalı, farklı bir isme yapılacak ise mutlaka dilekçe alınmalıdır.',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            tmp = true;
            if (
              invoiceData[0]?.Exchange?.SubStatus == 6 &&
              invoiceData[0]?.Consumer?.UpTypeId == 3
            ) {
              setLoading(true);
              dispatch(getRefundConvince())
                .then((response) => {
                  setConvinceRefundList(response.payload);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  return;
                });
              setConvinceProcessPopUp(true);
              return;
            } else if (
              invoiceData[0]?.Exchange?.SubStatus == 6 &&
              invoiceData[0]?.Consumer?.UpTypeId != 3
            ) {
              setLoading(true);
              dispatch(getRefundConvinceType())
                .then((response) => {
                  setConvinceRefundTypeList(response.payload);
                  setLoading(false);
                })
                .catch((err) => {
                  setLoading(false);
                  return;
                });
              setConvinceTypePopUp(true);
              return;
            } else {
              lastControl();
              setLoading(false);
              return;
            }
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ],
      afterClose: () => (tmp ? lastControl() : null)
    });
  }

  function UserButtonLog(durum) {
    const data = [
      invoiceData[0]?.Apply?.ReceiptNumber || 0,
      durum,
      SessionUser?.UserId || 0
    ];
    axios.get(`/DiaSolutionCenterButtonLog/${data[0]}/${data[1]}/${data[2]}`);
  }

  function Accept() {
    //onayla konumu için yazıyorum.
    const result = ucretliDegisimIndirimOranıKontrol();
    const result1 = ustModelIndırımOranıKontrol();
    if (
      (result == true || result1 == true) &&
      invoiceData[0]?.Exchange.Status != 25
    ) {
      UserButtonLog(15);
      discountAdminApproval();
      return;
    }
    if (invoiceData[0]?.Exchange?.SubStatus == 18 && !updatedInfoChat) {
      InfoAlert(
        'Değişim Grubu-Bilgi Talebi ara durumundayken mesaj alanını doldurmadan fişi onaylayamazsınız.Lütfen mesaj alanına açıklama giriniz'
      );
      setUyari(true);
      return;
    }
    if (!invoiceData[0]?.Exchange?.ExchangeReson) {
      InfoAlert('Lütfen değişim nedeni seçiniz.');
      return;
    }
    if (isIbanValidatorActivated && isIbanNoValid === 0) {
      InfoAlert('Lütfen geçerli bir IBAN numarası giriniz!...');
      return;
    }
    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice != 0 &&
      invoiceData[0]?.Consumer?.UpTypeId == 2
    ) {
      InfoAlert(
        'Değişim Onay Türü = Para İadesi seçildiğinde Tutar ve Üst Model Fark Tutar alanlarına ücret bilgisi girilmemelidir!'
      );
      return;
    }
    if (!invoiceData[0]?.Consumer?.UpTypeId) {
      InfoAlert('Lütfen onay tipi seçiniz.');
      return;
    }
    //MHTU-4164

    if (
      (invoiceData[0]?.Exchange?.ABSPayAccount == null ||
        invoiceData[0]?.Exchange?.ABSPayAccount == '') &&
      invoiceData[0]?.Exchange.BankFile == 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 1 &&
      invoiceData[0]?.Exchange.BankFile == 1
    ) {
      InfoAlert(
        'Banka Ödemesi Dekontu tipinde bir dosya yüklü ise Dekont sahibi alanı boş olamaz ayrıca onay türü= Standart değişim olamaz.'
      );
      return;
    }
    if (
      (invoiceData[0]?.Exchange?.ABSPayAccount == null ||
        invoiceData[0]?.Exchange?.ABSPayAccount == '') &&
      invoiceData[0]?.Exchange.BankFile == 1 &&
      invoiceData[0]?.Consumer?.UpTypeId != 1 &&
      invoiceData[0]?.Exchange.BankFile == 1
    ) {
      InfoAlert(
        'Banka Ödemesi Dekontu tipinde bir dosya yüklü ise Dekont sahibi alanı boş olamaz !'
      );
      return;
    }
    //MHTU-3836
    if (
      brands.includes(invoiceData[0]?.Apply.BrandCode) &&
      invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 &&
      !invoiceData[0]?.Exchange?.GiveProductStokNumber &&
      invoiceData[0]?.Exchange?.Status === 23 &&
      oldSubStatus === 3 &&
      invoiceData[0]?.Exchange?.SubStatus !== 3 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 2 &&
      invoiceData[0]?.Consumer?.UpTypeId !== 7
    ) {
      InfoAlert('Verilecek Ürün Alanı boş olamaz.');
      return;
    }
    //MHTU-3594 Başlangıç
    if (
      invoiceData[0]?.Apply.BrandCode !== 3 &&
      invoiceData[0]?.Apply.BrandCode !== 4 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Arçelik ve Beko marka ürünler dışında Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      invoiceData[0]?.Consumer?.ConsumerType !== 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Müşteri Tipi: Müşteri haricinde Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    if (
      topProductIds.includes(invoiceData[0]?.Model?.TopProductId) &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Ürün Grubu Yazarkasa olan ürünler için Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-3594 Son

    //MHTU-4127 Başlangıç
    if (
      invoiceData[0]?.Exchange.IsAirConditionerProduct === 1 &&
      invoiceData[0]?.Consumer?.UpTypeId === 7
    ) {
      InfoAlert(
        'Portatif Klima Grubundaki Ürünler Haricindeki Klima Grubu Ürünler Onay Tipi: Değişim Çeki olarak seçilemez.'
      );
      return;
    }
    //MHTU-4127 Bitiş

    if (
      invoiceData[0]?.Exchange?.SubStatus === 22 &&
      (!airConditionerMainGroups.includes(invoiceData[0]?.Model?.MainGroupId) ||
        invoiceData[0]?.Consumer?.ConsumerType != 1)
    ) {
      InfoAlert(
        "'Klima Tamamlayıcı Ünite Ödeme/Onay Bekleniyor' ara durumu sadece Klima Ürün Grubu ve Müşteri Tipi: Müşteri olan fişler için seçilebilmektedir."
      );
      return;
    }

    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice > 0 &&
      invoiceData[0]?.Consumer?.UpTypeId != 3
    ) {
      InfoAlert('Ücret Girilmiş Değişim Talebi Onaylanamaz.');
      return;
    }
    if (
      invoiceData[0]?.Exchange?.productTotalPrice < 1 &&
      invoiceData[0]?.Consumer?.UpTypeId == 3
    ) {
      InfoAlert(
        'Ücretli değişim seçtiniz.Lütfen ücretli değişim tutarını giriniz.'
      );
      return;
    }
    if (
      !invoiceData[0]?.Exchange?.NoneProduct &&
      invoiceData[0]?.Exchange?.ExchangeResonMain == 30
    ) {
      InfoAlert(
        'Teminsiz stok alanı boş.Değişim yedek parça lojistiği nedeniyle onaylanamaz '
      );
      return;
    }
    if (
      invoiceData[0]?.Consumer?.UpTypeId == 1 ||
      invoiceData[0]?.Consumer?.UpTypeId == 4
    ) {
      const data = [
        invoiceData[0]?.Model?.ProductId,
        invoiceData[0]?.Apply.SerialNumber,
        invoiceData[0]?.Apply.UYear,
        invoiceData[0]?.Apply.UMonth,
        invoiceData[0]?.Apply.AttributeCode
      ];
      setLoading(true);
      if (
        invoiceData[0]?.Model?.ProductId === undefined ||
        invoiceData[0]?.Model?.ProductId === null ||
        invoiceData[0]?.Apply.SerialNumber === undefined ||
        invoiceData[0]?.Apply.SerialNumber === null ||
        invoiceData[0]?.Apply.UYear === null ||
        invoiceData[0]?.Apply.UYear === undefined ||
        invoiceData[0]?.Apply.UMonth === undefined ||
        invoiceData[0]?.Apply.UMonth === null ||
        invoiceData[0]?.Apply.AttributeCode === undefined ||
        invoiceData[0]?.Apply.AttributeCode === null
      ) {
        setLoading(false);
        return false;
      }
      dispatch(getWarranty(data))
        .then((response) => {
          const warrantyControlData = response.payload;
          if (warrantyControlData[0]?.RemainingDay < 0) {
            warrantyControl();
            setLoading(false);
          } else {
            lastControl();
            setLoading(false);
            return;
          }
        })
        .catch((err) => {
          setLoading(false);
          return;
        });
    }
    if (
      invoiceData[0]?.Exchange?.ProductTotalPrice >= 0 &&
      invoiceData[0]?.Consumer?.UpTypeId == 3
    ) {
      setLoading(true);
      PriceToExchange();
      setLoading(false);
      return;
    }
    if (
      invoiceData[0]?.Consumer?.UpTypeId == 2 &&
      invoiceData[0]?.Exchange?.ExchangeReasonSurveyCheck === true
    ) {
      setLoading(true);
      PriceToRefund();
      setLoading(false);
      return;
    }
    setLoading(true);
    lastControl();
    setLoading(false);
  }
  function SendSmsModelSuggest() {
    setLoading(true);
    data.Status = 27;
    axios
      .post('SMSModelSuggest', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
        });
        setTimeout(pageUpdate, 500);
        setUyari(true);
        setLoading(false);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }
  function SendSms() {
    setLoading(true);
    axios
      .post('SMSSend', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
        });
        setTimeout(pageUpdate, 500);
        setUyari(true);
        setLoading(false);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  function pageUpdate() {
    dispatch(generalFollowActions.updateActiveTab('1'));
  }

  function sendConvinceSurvey() {
    if (selectedConvinceSurvey?.length == 0) {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Lütfen en az 1 tane ikna nedeni seçiniz!'
      });
      setUyari(true);
      return;
    }
    if (!convinceChat) {
      setresponseMessage({
        ErrorCode: 0,
        ErrorDescription: 'Lütfen mesaj alanını boş bırakmayınız!'
      });
      setUyari(true);
      return;
    }
    const data = {
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo,
      ServiceNumber: invoiceData[0]?.Exchange?.Service,
      ConvinceTypeList: selectedConvinceSurvey.toString(),
      ConvinceDescription: convinceChat.toString()
    };
    axios
      .post('UpdateRefundConvince', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
        });
        setUyari(true);
        setConvinceTypePopUp(false);
        setLoading(false);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  function SendSmsModelSuggest1() {
    const result = ucretliDegisimIndirimOranıKontrol();
    const result1 = ustModelIndırımOranıKontrol();
    if (
      (result == true || result1 == true) &&
      invoiceData[0]?.Exchange.Status != 25
    ) {
      UserButtonLog(27);
      discountAdminApproval(2);
      return;
    }
    const data = {
      ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(),
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString(),
      ProductBuyStockNumber: invoiceData[0]?.Model.RevisionStokNo, //alınan ürün stok number
      GiveProductStokNumber:
        invoiceData[0]?.Exchange?.GiveProductStokNumber.toString() //verilen ürün stok number
    };
    confirmAlert({
      message: 'Müşteriye aynı model ürün için sms atıyorsunuz, emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('SMSSend_Message_Model_Suggess', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setSmsModelSuggest(response.data);
                setSmsSendModelSuggestPopUp(true);
                setLoading(false);
                return;
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function SendSmsWebPoint() {
    const data = {
      ServiceNumber: invoiceData[0]?.Exchange?.Service?.toString(),
      ApplicationNumber: invoiceData[0]?.Exchange?.ApplyNo?.toString()
    };
    confirmAlert({
      message: 'Müşteriye Sms gönderilecektir. Devam etmek istiyor musunuz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('WebPointContractSmsSend', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setresponseMessage({
                  ErrorCode: 1000,
                  ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
                });
                setTimeout(pageUpdate, 500);
                setUyari(true);
                setLoading(false);
                return;
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function SendSmsMessage() {
    const result = ucretliDegisimIndirimOranıKontrol();
    const result1 = ustModelIndırımOranıKontrol();
    if (
      (result == true || result1 == true) &&
      invoiceData[0]?.Exchange.Status != 25
    ) {
      UserButtonLog(24);
      discountAdminApproval(1);
      return;
    }
    const data = {
      ReceiptNumber: invoiceData[0]?.Apply?.ReceiptNumber
    };
    setLoading(true);
    axios
      .post('SMSSend_Message', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setSmsContent(response.data);
        setSmsSendPopUp(true);
        setLoading(false);
        return;
      })
      .catch((err) => {
        setresponseMessage({
          ErrorCode: 0,
          ErrorDescription: err.response.data
        });
        setUyari(true);
        setLoading(false);
        return;
      });
  }
  //Değişim Çeki Onaylama İşlemi
  function webPointAccept() {
    confirmAlert({
      message: 'Değişim Çeki işlemini onaylıyorsunuz.Emin misiniz ?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('WebPointAccept', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setLoading(false);
                setresponseMessage({
                  ErrorCode: 1000,
                  ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
                });
                removeRowInTable();
                setTimeout(pageUpdate, 500);
                setUyari(true);
                return;
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }
  //Değişim Çeki Reddetme İşlemi

  function webPointReject() {
    confirmAlert({
      message: 'Değişim Çeki işlemini reddediyorsunuz.Emin misiniz ?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            setLoading(true);
            axios
              .post('BackToSolutionCenterForCM', data, {
                headers: { 'Content-Type': 'application/json' }
              })
              .then((response) => {
                setLoading(false);
                setresponseMessage({
                  ErrorCode: 1000,
                  ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
                });
                removeRowInTable();
                setTimeout(pageUpdate, 500);
                setUyari(true);
                return;
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  function changeWebPuanCancelType(val) {
    //verilen ürün stok numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.WebPointCancelType = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setWebPointCancelType(val);
  }

  function CancelToTani() {
    if (invoiceData[0]?.Exchange?.TotalDayOfWebPoint > 90) {
      setIptalTanıUyarıPopUp(true);
      return;
    }

    confirmAlert({
      message: 'TANI tarafında Hediye Çeki İptal Edilecek Emin misiniz?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => {
            var receiptNumber = invoiceData[0]?.Apply?.ReceiptNumber;
            setLoading(true);
            axios
              .get('/CancelFromTanı/' + receiptNumber, {
                responseType: 'application/json'
              })
              .then((response) => {
                if (response.data.Statu === '0') {
                  Log();
                  data.Status = 32;
                  axios
                    .post('SolutionCenterSave', data, {
                      headers: { 'Content-Type': 'application/json' }
                    })
                    .then((response) => {
                      if (response.data.ErrorCode == 1000) {
                        setLoading(false);
                      } else {
                        const LogData = {
                          ReceiptNumber: invoiceData[0]?.Apply?.ReceiptNumber,
                          UserId: invoiceData[0]?.UserID,
                          WebPointCancelType: webPointCancelType.toString()
                        };
                        axios
                          .post('WebPointCancelTypeLog', LogData, {
                            headers: { 'Content-Type': 'application/json' }
                          })
                          .then((response) => {
                            setLoading(false);
                          })
                          .catch((err) => {
                            setLoading(false);
                            return;
                          });
                      }
                    })
                    .catch((err) => {
                      setLoading(false);
                      return;
                    });
                } else {
                  setLoading(false);
                }
                setTaniOpenResponsePopUp(true);
                setTaniOpenResponse(response.data);
              })
              .catch((err) => {
                setLoading(false);
                return;
              });
          }
        },
        {
          label: 'Hayır',
          onClick: () => { }
        }
      ]
    });
  }

  const closeTanıResponsePopUp = (value) => {
    if (value === '0') {
      setTaniOpenResponsePopUp(false);
      removeRowInTable();
      setTimeout(pageUpdate, 500);
    } else {
      setTaniOpenResponsePopUp(false);
    }
  };

  const differentBrandSendManagerClick = () => {
    setDifferentBrandApplyMessage([]);
    setDifferentBrandSendManagerPopUp(true);
  };

  function ExchangeManagerApprovedDifferentBrand() {
    // Log();
    setLoading(true);
    var Dsc =
      ' Farklı Marka: Yönetici Onayı Verildi. Müşteriye Bilgi Verilebilir.';
    data.Note = data.Note + Dsc;
    axios
      .post('ExchangeManagerApprovedDifferentBrand', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setLoading(false);
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
        });
        removeRowInTable();
        setTimeout(pageUpdate, 500);
        setUyari(true);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  function ExchangeManagerDeniedDifferentBrand() {
    Log();
    setLoading(true);
    var Dsc = ' Farklı Marka: Yönetici Reddetti';
    data.Note = data.Note + Dsc;
    axios
      .post('ExchangeManagerDeniedDifferentBrand', data, {
        headers: { 'Content-Type': 'application/json' }
      })
      .then((response) => {
        setLoading(false);
        setresponseMessage({
          ErrorCode: 1000,
          ErrorDescription: 'İşleminiz Başarı ile Gerçekleşmiştir'
        });
        removeRowInTable();
        setTimeout(pageUpdate, 500);
        setUyari(true);
        return;
      })
      .catch((err) => {
        setLoading(false);
        return;
      });
  }

  const handleFilterServiceRefundTextChange = (value) => {
    setFilterServiceRefundText(value);

    if (value === '') {
      setFilteredserviceRefundList(serviceRefundList);
      return;
    }

    const filteredData = serviceRefundList.filter((item) => {
      return item.ACIKLAMA.toString()
        .toLocaleLowerCase('tr-TR')
        .includes(value.toLocaleLowerCase('tr-TR'));
    });

    setFilteredserviceRefundList(filteredData);
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stack spacing={8}>
        <Snackbar
          sx={{ width: '100%' }}
          open={uyari}
          autoHideDuration={6000}
          onClose={uyariKapat}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          {responseMessage.ErrorCode === 0 ? (
            <Alert
              icon={<MoodBadIcon />}
              style={{ minWidth: 500 }}
              sx={{
                '& .MuiAlert-icon': {
                  color: 'white'
                },
                height: 75,
                alignItems: 'center',
                verticalAlign: 'center'
              }}
              onClose={uyariKapat}
              variant="filled"
              severity="error"
            >
              {responseMessage.ErrorDescription}
            </Alert>
          ) : (
            <Alert
              icon={<TagFacesIcon />}
              style={{ minWidth: 500 }}
              sx={{
                '& .MuiAlert-icon': {
                  color: 'white'
                },
                height: 75,
                alignItems: 'center',
                verticalAlign: 'center'
              }}
              onClose={uyariKapat}
              variant="filled"
              severity="success"
            >
              {responseMessage.ErrorDescription}
            </Alert>
          )}
        </Snackbar>
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={9}></Grid>
        {invoiceData[0]?.Exchange?.Status == 27 ||
          invoiceData[0].Exchange.Status == 25 ? (
          <Grid item xs={1}>
            <Tooltip title="Müşteriye sms model öneri">
              <IconButton
                style={{ color: 'orange' }}
                disabled={invoiceData[0]?.Exchange?.Status == 27 ? false : true}
                onClick={() => {
                  SendSmsModelSuggest1();
                }}
              >
                {invoiceData[0]?.Exchange?.Status == 27 ? (
                  <MarkEmailReadIcon style={{ color: 'orange' }} />
                ) : (
                  <MarkEmailReadIcon color="disabled" />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {invoiceData[0]?.Exchange?.Status == 30 ? (
          <Grid item xs={1}>
            <Tooltip title="Müşteriye Değişim Çeki Sms Gönder">
              <IconButton
                style={{ color: 'orange' }}
                disabled={invoiceData[0]?.Exchange?.Status == 30 ? false : true}
                onClick={() => {
                  SendSmsWebPoint();
                }}
              >
                {invoiceData[0]?.Exchange?.Status == 30 ? (
                  <MarkEmailReadIcon style={{ color: 'orange' }} />
                ) : (
                  <MarkEmailReadIcon color="disabled" />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
        {invoiceData[0].Exchange.Status == 23 ||
          invoiceData[0].Exchange.Status == 24 ||
          invoiceData[0].Exchange.Status == 27 ||
          invoiceData[0].Exchange.Status == 25 ? (
          <Grid item xs={2}>
            <Tooltip title="Sms Gönder">
              <IconButton
                style={{ color: 'orange' }}
                disabled={invoiceData[0]?.Exchange?.Status == 24 ? false : true}
                onClick={() => {
                  SendSmsMessage();
                }}
              >
                {invoiceData[0]?.Exchange?.Status == 24 ? (
                  <MarkEmailReadIcon style={{ color: 'orange' }} />
                ) : (
                  <MarkEmailReadIcon color="disabled" />
                )}
              </IconButton>
            </Tooltip>
          </Grid>
        ) : null}
      </Grid>
      {isValidStatus &&
        userRole?.filter((i) => i.COMPONENT_NAME == 'SolutionCenterProcess')
          .length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="combo-box-demo"
              options={confirmTypeData}
              getOptionLabel={(i) => i.Dsc}
              size="small"
              value={confirmTypes || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: '14px' }
                  }}
                  label="Onay Tipi"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant="body2" style={{ fontSize: '14px' }}>
                    {option.Dsc}
                  </Typography>
                </li>
              )}
              onChange={(event, val) => {
                updateUpConfirmTypeValue(val);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              id="combo-box-demo"
              options={upConfirmTypeData}
              size="small"
              getOptionLabel={(i) => i.Dsc}
              value={upConfirmTypes || null}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { fontSize: '14px' }
                  }}
                  label="Detay"
                />
              )}
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography variant="body2" style={{ fontSize: '14px' }}>
                    {option.Dsc}
                  </Typography>
                </li>
              )}
              onChange={(event, val) => {
                updateLowConfirmTypeValue(val);
              }}
            />
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={2} md={2}></Grid>
          <Grid item xs={1} md={1}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Kaydet">
                <IconButton
                  style={{ color: 'blue' }}
                  onClick={() => {
                    AllSave();
                  }}
                  disabled={
                    invoiceData[0]?.Exchange?.Status === 25 ||
                      invoiceData[0]?.Exchange?.Status === 29 ||
                      invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1
                      ? true
                      : false
                  }
                >
                  {invoiceData[0]?.Exchange?.Status === 25 ||
                    invoiceData[0]?.Exchange?.Status === 29 ||
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                    1 ? (
                    <SaveIcon color="disabled" />
                  ) : (
                    <SaveIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={1} md={1}>
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              <Tooltip title="Onayla">
                <IconButton
                  onClick={() => {
                    Accept();
                  }}
                  disabled={
                    invoiceData[0]?.Exchange?.Status === 25 ||
                      invoiceData[0]?.Exchange?.Status === 29 ||
                      invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1
                      ? true
                      : false
                  }
                >
                  {invoiceData[0]?.Exchange?.Status === 25 ||
                    invoiceData[0]?.Exchange?.Status === 29 ||
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                    1 ? (
                    <CheckIcon color="disabled" />
                  ) : (
                    <CheckIcon style={{ color: 'green' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={1} md={1}>
            <Box sx={{ '& > :not(style)': { m: 1 } }}>
              <Tooltip title="İptal">
                <IconButton
                  onClick={() => StatusCancel()}
                  disabled={
                    invoiceData[0]?.Exchange?.Status === 25 ||
                      invoiceData[0]?.Exchange?.Status === 29 ||
                      invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1
                      ? true
                      : false
                  }
                >
                  {invoiceData[0]?.Exchange?.Status === 25 ||
                    invoiceData[0]?.Exchange?.Status === 29 ||
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                    1 ? (
                    <HighlightOffIcon color="disabled" />
                  ) : (
                    <HighlightOffIcon style={{ color: 'red' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={1} md={1}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Servise Ata">
                <IconButton
                  color="primary"
                  onClick={() => SendToService()}
                  disabled={
                    invoiceData[0]?.Exchange?.Status === 25 ||
                      invoiceData[0]?.Exchange?.Status === 29
                      ? true
                      : false
                  }
                >
                  {invoiceData[0]?.Exchange?.Status === 25 ||
                    invoiceData[0]?.Exchange?.Status === 29 ? (
                    <ApartmentIcon color="disabled" />
                  ) : (
                    <ApartmentIcon style={{ color: 'orange' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={1} md={1}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Ücret Ödemesi Bekleniyor">
                <IconButton
                  disabled={
                    invoiceData[0]?.Exchange?.Status == 24 ||
                      invoiceData[0]?.Exchange?.Status === 29 ||
                      invoiceData[0]?.Exchange?.Status === 25 ||
                      invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1
                      ? true
                      : false
                  }
                  onClick={() => WaitingStatusPayment()}
                >
                  {invoiceData[0]?.Exchange?.Status == 24 ||
                    invoiceData[0]?.Exchange?.Status === 29 ||
                    invoiceData[0]?.Exchange?.Status === 25 ||
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                    1 ? (
                    <PaymentsIcon color="disabled" />
                  ) : (
                    <PaymentsIcon style={{ color: 'green' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={1} md={1}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Müşteriden Onay Bekleniyor">
                <IconButton
                  onClick={() => WaitingCustomerAccept()}
                  disabled={
                    invoiceData[0]?.Exchange?.Status == 27 ||
                      invoiceData[0]?.Exchange?.Status === 29 ||
                      invoiceData[0]?.Exchange?.Status === 25 ||
                      invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                      1 ||
                      invoiceData[0]?.Exchange?.Status == 30
                      ? true
                      : false
                  }
                >
                  {invoiceData[0]?.Exchange?.Status == 27 ||
                    invoiceData[0]?.Exchange?.Status === 29 ||
                    invoiceData[0]?.Exchange?.Status === 25 ||
                    invoiceData[0]?.Exchange?.DifferentBrandManagerControl ===
                    1 ||
                    invoiceData[0]?.Exchange?.Status == 30 ? (
                    <TimerIcon color="disabled" />
                  ) : (
                    <TimerIcon style={{ color: 'purple' }} />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={1} md={1}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Çözüm Merkezine Gönder ">
                <IconButton
                  onClick={() => BackToSolutionCenter()}
                  disabled={
                    invoiceData[0]?.Exchange?.Status == 23 ? true : false
                  }
                >
                  {invoiceData[0]?.Exchange?.Status != 23 ? (
                    <SwapHorizIcon style={{ color: 'blue' }} />
                  ) : (
                    <SwapHorizIcon color="disabled" />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={3} md={3}></Grid>
          {invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1 &&
            invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 &&
            invoiceData[0]?.Exchange?.Status !== 25 ? (
            <>
              <Grid item xs={4} md={5.5}></Grid>
              <Grid item xs={4} md={1}>
                <Box sx={{ m: 1 }}>
                  <Tooltip title="Farklı Marka İçin Yönetici Onayına Gönder">
                    <IconButton
                      onClick={() => {
                        differentBrandSendManagerClick();
                      }}
                    >
                      <AddTaskIcon style={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={4} md={5.5}></Grid>
            </>
          ) : null}
          <Grid item xs={3} md={3}></Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                width: '100%',
                color: 'text.secondary',
                textAlign: 'center'
              }}
              variant="subtitle1"
            >
              {'Çözüm merkezi'}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}></Grid>
        </Grid>
      ) : receiptStatus == 28 ? (
        <Grid container spacing={2}>
          <Grid item xs={5}></Grid>
          <Grid item xs={2}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Geri Al">
                <IconButton
                  color="primary"
                  onClick={() => {
                    TakeBack();
                  }}
                >
                  <SettingsBackupRestoreIcon fontSize="large" />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={5}></Grid>
        </Grid>
      ) : receiptStatus == 30 &&
        Role?.filter((i) => i.ROLE_ID == 5).length >= 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}></Grid>
          <Grid item xs={2} md={2}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Değişim Çeki Onay">
                <IconButton onClick={() => webPointAccept()}>
                  <PeopleIcon style={{ color: 'green' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={2} md={2}>
            <Box sx={{ m: 1 }}>
              <Tooltip title="Değişim Çeki Red">
                <IconButton onClick={() => webPointReject()}>
                  <ThumbDownAltIcon style={{ color: 'red' }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={4} md={4}></Grid>
          <Grid item xs={3} md={3}></Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                width: '100%',
                color: 'text.secondary',
                textAlign: 'center'
              }}
              variant="subtitle1"
            >
              {'Değişim Çeki'}
            </Typography>
          </Grid>
        </Grid>
      ) : invoiceData[0]?.Consumer.UpTypeId == 7 &&
        invoiceData[0]?.Exchange.GPSNO != 0 &&
        (receiptStatus === 31 || receiptStatus === 33) ? (
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Button
              fullWidth
              size="medium"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              disabled={
                invoiceData[0]?.Exchange?.WebPointCancelType ? false : true
              }
              onClick={() => {
                CancelToTani();
              }}
            >
              Değişim Çeki İptal
            </Button>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              fullWidth
              select
              label="Red Nedeni"
              variant="outlined"
              value={webPointCancelType}
              onChange={(event) => {
                changeWebPuanCancelType(event.target.value);
              }}
            >
              <MenuItem key={1} value={'ZFG'}>
                {'Fiyat güncelleme'}
              </MenuItem>
              <MenuItem key={2} value={'ZUI'}>
                {'Değişim çekinden ücret iadesine dönüş'}
              </MenuItem>
              <MenuItem key={3} value={'ZUD'}>
                {'Değişim çekinden ürün değişimine dönüş'}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={2}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Typography
              sx={{ width: '100%', color: 'red', textAlign: 'center' }}
              variant="subtitle1"
            >
              {'Değişim  Çeki İptal'}
            </Typography>
          </Grid>
          <Grid item xs={3}></Grid>
        </Grid>
      ) : null}

      {receiptStatus != 30 &&
        userRole?.filter((i) => i.COMPONENT_NAME == 'ExchangeManagerProcess')
          .length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={4} md={4}></Grid>
          {invoiceData[0]?.Exchange?.DiscountStatus == 15 ? (
            <Grid item xs={2} md={2}>
              <Box sx={{ '& > :not(style)': { m: 1 } }}>
                <Tooltip title="Onayla">
                  <IconButton
                    onClick={() => {
                      Accept();
                    }}
                  >
                    <CheckIcon style={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          ) : invoiceData[0]?.Exchange?.DiscountStatus == 24 ? (
            <Grid item xs={2} md={2}>
              <Box sx={{ m: 1 }}>
                <Tooltip title="Ücret Ödemesi Bekleniyor">
                  <IconButton onClick={() => WaitingStatusPayment()}>
                    <PaymentsIcon style={{ color: 'green' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          ) : invoiceData[0]?.Exchange?.DiscountStatus == 27 ? (
            <Grid item xs={2} md={2}>
              <Box sx={{ m: 1 }}>
                <Tooltip title="Müşteriden Onay Bekleniyor">
                  <IconButton onClick={() => WaitingCustomerAccept()}>
                    <TimerIcon style={{ color: 'purple' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          ) : null}
          {/* Farklı Marka Ürün Değişim Yönetici Butonları Başlangıç */}
          {invoiceData[0]?.Exchange?.DifferentBrandManagerControl === 1 &&
            invoiceData[0]?.Exchange?.IsManagerAppliedDifferenBrand === 0 ? (
            <>
              <Grid item xs={1} md={1}>
                <Box sx={{ m: 1 }}>
                  <Tooltip title="Farklı Marka Onayla">
                    <IconButton
                      onClick={() => {
                        ExchangeManagerApprovedDifferentBrand();
                      }}
                    >
                      <CheckIcon style={{ color: 'green' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
              <Grid item xs={1} md={1}>
                <Box sx={{ m: 1 }}>
                  <Tooltip title="Farklı Marka Reddet">
                    <IconButton
                      onClick={() => {
                        ExchangeManagerDeniedDifferentBrand();
                      }}
                    >
                      <ThumbDownIcon style={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>
            </>
          ) : (
            <Grid item xs={2} md={2}>
              <Box sx={{ m: 1 }}>
                <Tooltip title="Reddet">
                  <IconButton
                    onClick={() => {
                      setRejectMessagePopUp(true);
                    }}
                  >
                    <ThumbDownIcon style={{ color: 'red' }} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          )}

          <Grid item xs={4} md={4}></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Typography
              sx={{
                width: '100%',
                color: 'text.secondary',
                textAlign: 'center'
              }}
              variant="subtitle1"
            >
              {'Ürün Değişim Yöneticisi'}
            </Typography>
          </Grid>
          <Grid item xs={3} md={3}></Grid>
        </Grid>
      ) : null}
      {/* Servis red nedenlerini çıkarmak için popup */}
      <Dialog
        open={serviceRefundPopUp}
        onClose={() => setServiceRefundPopUp(false)}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Arama"
                variant="outlined"
                value={filterServiceRefundText}
                onChange={(e) =>
                  handleFilterServiceRefundTextChange(e.target.value)
                }
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  endAdornment: filterServiceRefundText && (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => handleFilterServiceRefundTextChange('')}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <div style={{ height: 600, width: '100%' }}>
                <DataGrid
                  rows={filteredserviceRefundList}
                  columns={columns}
                  getRowId={(row) => row.DEGISIM_RED_NEDENI}
                  checkboxSelection
                  onSelectionModelChange={(selection) => {
                    var tempData = JSON.parse(JSON.stringify(invoiceData));
                    tempData[0].Exchange.ServiceRefund = selection;
                    dispatch(generalFollowActions.updateNewData(tempData));
                    setSelectedServiceRefund(selection);
                  }}
                />
              </div>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              if (!isUpdate) {
                ServiveRejectLog();
                assignedToService();
              } else reject();
            }}
          >
            {isUpdate ? 'Bilgi Düzelt' : 'Servise Ata'}
          </Button>
          <IconButton
            color="primary"
            onClick={() => setServiceRefundPopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      <Dialog
        open={convinceProcessPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid item xs={12} md={12}>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                rows={convinceRefundList}
                columns={convinceColumns}
                getRowId={(row) => row.KATALOG_ID}
                checkboxSelection
                onSelectionModelChange={(selection) => {
                  var tempData = JSON.parse(JSON.stringify(invoiceData));
                  tempData[0].Exchange.RefundConvince = selection;
                  dispatch(generalFollowActions.updateNewData(tempData));
                  setSelectedConvinceRefund(selection);
                }}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              lastControl();
              setConvinceProcessPopUp(false);
            }}
          >
            Para İadesi
          </Button>
          <IconButton
            color="primary"
            onClick={() => setConvinceProcessPopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      {/* Sms gönderme için popup */}
      <Dialog
        open={smsSendPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {invoiceData[0]?.Model?.UserModelCode1 ===
            invoiceData[0]?.Exchange?.GiveProductStokNumber ? (
            <>
              <Typography
                sx={{ width: '100%', color: 'red' }}
                variant="subtitle1"
              >
                {
                  'Dikkat! Müşteriye aynı model ürün için sms atıyorsunuz, emin misiniz?'
                }
              </Typography>
              <Divider style={{ marginBottom: '1rem' }} />
            </>
          ) : null}
          <Typography
            sx={{ width: '100%', color: 'text.secondary' }}
            variant="subtitle1"
          >
            {smsContent}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              PriceAcceptChangeStatus();
            }}
          >
            SMS Gönder
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setSmsSendPopUp(false);
            }}
          >
            SMS Gönderme
          </Button>
        </DialogActions>
      </Dialog>
      {/* Sms gönderme model önerme için popup */}
      <Dialog
        open={smsSendModelSuggestPopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {invoiceData[0]?.Model?.UserModelCode1 ===
            invoiceData[0]?.Exchange?.GiveProductStokNumber ? (
            <>
              <Typography
                sx={{ width: '100%', color: 'red' }}
                variant="subtitle1"
              >
                {
                  'Dikkat! Müşteriye aynı model ürün için sms atıyorsunuz, emin misiniz?'
                }
              </Typography>
              <Divider style={{ marginBottom: '1rem' }} />
            </>
          ) : null}
          <Typography
            sx={{ width: '100%', color: 'text.secondary' }}
            variant="subtitle1"
          >
            {smsModelSuggest}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              CustomerAcceptChangeStatus();
            }}
          >
            SMS Gönder
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              setSmsSendModelSuggestPopUp(false);
            }}
          >
            SMS Gönderme
          </Button>
        </DialogActions>
      </Dialog>
      {/* //teşekkür anketi pop up */}
      <Dialog
        open={convinceTypePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid item xs={12} md={12}>
            <TextField
              id="input-with-icon-textfield"
              label="Mesaj"
              size="small"
              value={convinceChat || ''}
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              onChange={(event) => {
                setConvinceChat(event.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} style={{ paddingTop: 20 }}>
            <div style={{ height: 450, width: '100%' }}>
              <DataGrid
                rows={convinceRefundTypeList}
                columns={convinceTypeColumns}
                getRowId={(row) => row.ID}
                checkboxSelection
                onSelectionModelChange={(selection) => {
                  setSelectedConvinceSurvey(selection);
                }}
              />
            </div>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              sendConvinceSurvey();
            }}
          >
            Para İadesi
          </Button>
          <IconButton
            color="primary"
            onClick={() => setConvinceTypePopUp(false)}
            aria-label="close"
            component="span"
            variant="contained"
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>
      </Dialog>
      {/* İndirim Yapan Kişi Açıklama */}
      <Dialog
        open={discountMessagePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Grid item xs={12} md={12}>
            <TextField
              id="input-with-icon-textfield"
              label="Lütfen Açıklama Giriniz"
              size="small"
              value={discountMessageDsc}
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              onChange={(event) => {
                setDiscountMessageDsc(event.target.value);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => {
              SendAdminControl();
            }}
          >
            Gönder
          </Button>
          <Button
            size="small"
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => {
              setDiscountMessagePopUp(false);
              setDiscountMessageDsc([]);
            }}
          >
            İptal
          </Button>
        </DialogActions>
      </Dialog>
      {/*Yönetici onayı reddetme sebebi*/}
      <Dialog
        open={rejectMessagePopUp}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <DialogContent>
          <Typography
            sx={{ width: '100%', color: 'text.secondary', fontWeight: 'Bold' }}
            variant="subtitle1"
          >
            {
              'Reddetmek istediğinizden emin misiniz ? Lütfen reddetme sebebinizi yazınız.'
            }
          </Typography>
          <br></br>
          <Grid item xs={12} md={12}>
            <TextField
              id="input-with-icon-textfield"
              label="Lütfen Açıklama Giriniz"
              size="small"
              value={rejectMessageDsc}
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              onChange={(event) => {
                setRejectMessageDsc(event.target.value);
              }}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => {
              ExchangeManagerReject();
            }}
          >
            Gönder
          </Button>
          <Button
            size="small"
            sx={{ color: '#FF9800', backgroundColor: '#424242' }}
            onClick={() => {
              setRejectMessagePopUp(false);
              setRejectMessageDsc([]);
            }}
          >
            İptal
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={TaniOpenResponsePopUp}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                sx={{ fontWeight: 'bold', width: '100%', textAlign: 'center' }}
              >
                {taniResponse?.Description ? taniResponse?.Description : null}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <IconButton
              color="primary"
              aria-label="close"
              component="span"
              variant="contained"
              onClick={() => {
                closeTanıResponsePopUp(taniResponse?.Statu);
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogActions>
        </DialogActions>
      </Dialog>

      <Dialog
        open={iptalTanıUyarıPopUp}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                component="div"
                sx={{ fontWeight: 'bold', width: '100%', textAlign: 'center' }}
              >
                {
                  '3 Ayı Geçen Değişim Çeklerini İptal Edemezsiniz. Müşteri Israr Ediyorsa Lütfen Yöneticinize Bildiriniz.'
                }
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                setIptalTanıUyarıPopUp(false);
              }}
            >
              Tamam
            </Button>
          </DialogActions>
        </DialogActions>
      </Dialog>

      <Dialog
        open={differentBrandSendManagerPopUp}
        aria-describedby="alert-dialog-slide-description"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                id="outlined-basic"
                size="medium"
                multiline
                maxRows={5}
                fullWidth
                variant="outlined"
                label="Lütfen Bir Açıklama Giriniz."
                value={differentBrandApplyMessage}
                onChange={(event) => {
                  setDifferentBrandApplyMessage(event.target.value);
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <Button
              variant="contained"
              size="medium"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                sendDifferentBrandAdminControl();
              }}
            >
              Yönetici Onayına Gönder
            </Button>
            <Button
              variant="contained"
              size="medium"
              sx={{ color: '#FF9800', backgroundColor: '#424242' }}
              onClick={() => {
                setDifferentBrandSendManagerPopUp(false);
              }}
            >
              Vazgeç
            </Button>
          </DialogActions>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default SolutionCenterProcess;
