import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Autocomplete,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import React, { useEffect } from 'react';
import InputMask from 'react-input-mask';
import { useDispatch, useSelector } from 'react-redux';
import generalFollowSlice, {
  generalFollowActions
} from 'src/store/slices/generalFollowSlice';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    fontFamily: 'Segoe UI'
  },
  div: {
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
    color: 'red'
  }
}));

function Refund() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const invoiceData = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  );

  const [expanded, setExpanded] = React.useState(true);
  const [bankCode, setBankCode] = React.useState(
    invoiceData[0]?.Exchange?.BankCode
  );
  const [bankName, setBankName] = React.useState(
    invoiceData[0]?.Exchange?.BankName
  );
  const [ibanNo, setIbanNo] = React.useState(invoiceData[0]?.Exchange?.IBANNO);
  const [webSalesInvoiceNumber, setWebSalesInvoiceNumber] = React.useState(
    invoiceData[0]?.Exchange?.WebSalesInvoiceNumber
  );
  const ibanNumber = useSelector(
    (state) => state.generalFollowSlice.invoiceDetail
  )[0]?.Exchange?.IBANNO;

  const [subModelDifferenceAmount, setSubModelDifferenceAmount] =
    React.useState(invoiceData[0]?.Exchange?.SubModelDifferenceAmount);

  const [isIbanValid, setIsIbanValid] = React.useState(null);

  const TRiban = ibanNumber?.substring(2);

  useEffect(() => {
    if (ibanNo?.length > 2) {
      const isValidIban = IbanValidator(invoiceData[0]?.Exchange?.IBANNO);
      setIsIbanValid(isValidIban);

      const result = isValidIban ? 1 : 0;
      dispatch(generalFollowActions.updateIsIbanNoValid(result));
    }
  }, []);

  function changeBankCode(val) {
    //Banka kodu
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.BankCode = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setBankCode(val);
  }

  function changeBankName(val) {
    //Banka Adı
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.BankName = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setBankName(val);
  }

  function IbanValidator(iban) {
    if (iban.length !== 26 || !iban.startsWith('TR')) {
      return false;
    }
    // Mod 97 kontrolü
    const reformattedIBAN = iban.slice(4) + iban.slice(0, 4); // İlk 4 karakteri sona taşı
    // Harfleri sayılara çevir
    const numericIBAN = reformattedIBAN.replace(
      /[A-Z]/g,
      (char) => char.charCodeAt(0) - 55
    );

    let remainder = 0;
    for (let i = 0; i < numericIBAN.length; i++) {
      remainder = (remainder * 10 + parseInt(numericIBAN[i], 10)) % 97; // Mod işlemi
    }

    // Mod 97 sonucu 1 olmalı
    return remainder === 1;
  }

  function handleChangeBankNo(event) {
    const inputValue = event.target.value.toUpperCase();
    const sanitizedValue = inputValue.replace(/TR/g, '');

    const finalIban = sanitizedValue.startsWith('TR')
      ? `TR${sanitizedValue.slice(2)}` // Zaten TR varsa, bir kere al
      : `TR${sanitizedValue}`; // TR yoksa ekle

    const ibanToSet = finalIban.slice(0, 26);

    // IBAN doğrulama işlemi
    const isValid = IbanValidator(ibanToSet);
    setIsIbanValid(isValid);

    const result = isValid ? 1 : 0;
    dispatch(generalFollowActions.updateIsIbanNoValid(result));

    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.IBANNO = ibanToSet;
    dispatch(generalFollowActions.updateNewData(tempData));
    setIbanNo(ibanToSet);
  }

  function changeWebSalesInvoiceNumber(val) {
    //Web Satış Fatura Numarası
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.WebSalesInvoiceNumber = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setWebSalesInvoiceNumber(val);
  }

  function changeSubModelDifferenceAmount(val) {
    dispatch(generalFollowActions.updateSubModelDifferenceAmount(val));
    //Alt Model Fark Tutarı
    var tempData = JSON.parse(JSON.stringify(invoiceData));
    tempData[0].Exchange.SubModelDifferenceAmount = val;
    dispatch(generalFollowActions.updateNewData(tempData));
    setSubModelDifferenceAmount(val);
  }
  return (
    <>
      <Accordion
        expanded={
          invoiceData[0]?.Exchange?.Status === 14 &&
          invoiceData[0]?.Consumer.UpTypeId !== 2 &&
          invoiceData[0]?.Consumer.UpTypeId !== 6
            ? !expanded
            : expanded
        }
        onChange={() => setExpanded(!expanded)}
        sx={{ border: 1, borderRadius: 2, borderColor: 'grey.500' }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography className={classes.div} variant="subtitle1">
            Banka İşlemleri
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Tooltip title="Banka Adı">
                <TextField
                  id="input-with-icon-textfield"
                  label="Banka Adı"
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={bankName}
                  onChange={(event) => {
                    changeBankName(event.target.value);
                  }}
                  autoComplete="off"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <InputMask
                mask="TR**************************"
                value={ibanNo}
                maskChar={null}
                onChange={handleChangeBankNo}
              >
                {() => (
                  <TextField
                    id="input-with-icon-textfield"
                    label="IBAN No"
                    size="small"
                    fullWidth
                    autoComplete="off"
                    variant="outlined"
                    error={!isIbanValid && ibanNo.length > 2} // Geçersizse hata göster
                    helperText={
                      ibanNo.length > 2
                        ? isIbanValid
                          ? 'IBAN numarası geçerlidir.'
                          : 'Geçersiz IBAN numarası. Lütfen kontrol edin.'
                        : ''
                    }
                    InputProps={{
                      endAdornment: isIbanValid ? (
                        <CheckCircleIcon style={{ color: 'green' }} />
                      ) : (
                        ibanNo.length > 2 && (
                          <CancelIcon style={{ color: 'red' }} />
                        )
                      )
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: isIbanValid ? 'green' : 'default'
                        },
                        '&:hover fieldset': {
                          borderColor: isIbanValid ? 'darkgreen' : 'default'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: isIbanValid ? 'green' : 'default'
                        }
                      }
                    }}
                  />
                )}
              </InputMask>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title="Web Satış Fatura Numarası">
                <TextField
                  id="input-with-icon-textfield"
                  label="Web Satış Fatura Numarası"
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={webSalesInvoiceNumber}
                  onChange={(event) => {
                    changeWebSalesInvoiceNumber(event.target.value);
                  }}
                  autoComplete="off"
                />
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip title="Alt Model Fark Tutarı">
                <TextField
                  id="input-with-icon-textfield"
                  label="Alt Model Fark Tutarı"
                  size="small"
                  fullWidth
                  variant="outlined"
                  value={subModelDifferenceAmount}
                  onChange={(event) => {
                    changeSubModelDifferenceAmount(event.target.value);
                  }}
                  autoComplete="off"
                />
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default Refund;
